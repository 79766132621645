/** 4种菜单颜色 */
export const MenuThemeStyle = {
  theme1: {
    colorPrimary: '#1352BE',
    colorText: '#A2CFFC',
    selectedBg: '#FFFFFF',
    selectedText: '#2B6BD8',
  },
  theme2: {
    colorPrimary: '#A78949',
    colorText: '#533700',
    selectedBg: '#FFFFFF',
    selectedText: '#AD8A44',
  },
  theme3: {
    colorPrimary: '#2B3B56',
    colorText: '#A2CFFC',
    selectedBg: '#FFFFFF',
    selectedText: '#4E80D5',
  },
  theme4: {
    colorPrimary: '#282b2d',
    colorText: '#BECFD9',
    selectedBg: '#FFFFFF',
    selectedText: '#627682',
  },
  otherTheme: {
    colorPrimary: '#1352BE',
    colorText: '#A2CFFC',
    selectedBg: '#FFFFFF',
    selectedText: '#2B6BD8',
  },
};

export function getThemeStyles({ token, themeType = 'theme1', sysMode = 'light', menuCollapsed = false }) {
  const menuConfig = MenuThemeStyle[themeType];

  const selfThemeStyle = {
    '--TUI-Menu-Color-Primary': menuConfig.colorPrimary,
    '--TUI-Menu-Color-Text': menuConfig.colorText,
    '--TUI-Menu-SelectedBg': sysMode === 'dark' ? '#fff' : menuConfig.selectedBg,
    '--TUI-Menu-SelectedText': sysMode === 'dark' && !menuCollapsed ? '#fff' : !menuCollapsed ? '#fff' : menuConfig.selectedText,
    '--TUI-Menu-Title-Color': sysMode === 'dark' ? '#fff' : menuConfig.selectedText,
  };

  return {
    ...selfThemeStyle,
    ...menuConfig,
    '--diff-border-radius': `${token.borderRadius - 6}px`,
  };
};

/**
 * 获得菜单的token变量
 * layoutMode: 'shrinkMenu' | 'default' | 'top' | 'ExpandMenu'
 * themeType: 'theme1' | 'theme2' | 'theme3' | 'theme4' | 'otherTheme'
 *
 * 1.底色为白色，菜单随主题
 * layoutMode： default
 *
 * 2.底色为主题色，菜单默认给一套
 * layoutMode: 'shrinkMenu' | 'top' | 'ExpandMenu'
 */
export function getMenuToken({ token, sysMode, layoutMode, themeType = 'theme1', menuCollapsed }) {
  const menuConfig = MenuThemeStyle[themeType];

  const commonToken = {
    iconSize: token.fontSizeLG,
    groupTitleFontSize: token.fontSizeLG,
  };

  /** 展开的深底 */
  const darkMenuExpand = {
    ...commonToken,
    itemBg: 'transparent',
    darkItemBg: 'transparent',
    darkSubMenuItemBg: 'transparent',
    darkItemSelectedBg: menuConfig.selectedBg,
    darkItemSelectedColor: menuConfig.colorPrimary,
  };

  /** 收缩的深底,浅色主题 */
  const darkMenuCollapsed = {
    ...commonToken,
    darkItemBg: 'transparent',
  };
  if (['default'].includes(layoutMode)) {
    return {
      ...commonToken,
      itemSelectedBg: '#fff',
      itemSelectedColor: token.colorPrimary,
      itemBg: 'transparent',
      darkItemBg: 'transparent',
      darkSubMenuItemBg: 'transparent',
      subMenuItemBg: 'transparent',
    };
  }

  if (sysMode === 'dark') {
    if (!menuCollapsed) { // 展开时
      return {
        ...darkMenuExpand,
      };
    }
    else { // 收起
      if (['top'].includes(layoutMode)) {
        return {
          horizontalItemBorderRadius: token.borderRadius,

          subMenuItemBg: token.colorPrimary,
          horizontalItemBg: token.colorPrimary,
          horizontalItemColor: menuConfig.colorText,
          horizontalItemHoverColor: '#fff',
          horizontalItemSelectedBg: '#fff',
          horizontalItemSelectedColor: token.colorPrimary,
        };
      }

      return darkMenuCollapsed;
    }
  }
  else {
    /** 底色为白色菜单随浅色系 */
    if (['default'].includes(layoutMode)) {
      return {
        ...commonToken,
        subMenuItemBg: '#fff',
      };
    }
    else {
      /** 底色为主题色，菜单为深色 */
      if (['ExpandMenu'].includes(layoutMode)) {
        if (!menuCollapsed) { // 展开时
          return darkMenuExpand;
        }
        return darkMenuCollapsed;
      }

      if (['top'].includes(layoutMode)) {
        return {
          ...commonToken,
          horizontalItemBorderRadius: token.borderRadius,

          itemBg: token.colorPrimary,
          // itemColor: menuConfig.colorText,
          itemHoverBg: '#fff',
          itemHoverColor: token.colorPrimary,
          itemSelectedBg: token.colorPrimaryBg,
          itemSelectedColor: token.colorPrimary,

          subMenuItemBg: token.colorPrimary,
          horizontalItemBg: token.colorPrimary,
          horizontalItemColor: menuConfig.colorText,
          horizontalItemHoverColor: '#fff',
          horizontalItemSelectedBg: menuConfig.selectedBg,
          horizontalItemSelectedColor: token.colorPrimary,
        };
      }

      if (['shrinkMenu'].includes(layoutMode)) {
        if (!menuCollapsed) { // 展开时
          return darkMenuExpand;
        }
        return darkMenuCollapsed;
      }

      // 其他深色 通用方案
      if (themeType === 'otherTheme') {
        return {
          ...commonToken,
          itemBg: 'transparent',
          itemBorderRadius: token.borderRadius,
          itemHoverBg: 'rgba(255, 255, 255, 0.2)',
          itemHoverColor: '#fff',
          itemSelectedBg: 'rgba(255, 255, 255, 0.2)',
          itemSelectedColor: '#fff',
        };
      }
    }
  }
  return {};
}

/** 获得四种菜单蒙版 */
export function getbgWider(markMode = 'markMode1') {
  const widerObj = {
    markMode1: 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/bg_Wider1.png',
    markMode2: 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/bg_Wider2.png',
    markMode3: 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/bg_Wider3.png',
    markMode4: 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/bg_Wider4.png',
  };
  return widerObj[markMode];
}

export default {};
