import { Select as AntSelect } from 'antd';
import { filterOptionByName } from '../../utils/tuiHelperFunction';
import { formatOption } from '../../utils/utils';

export default function Select({
  id,
  value,
  onChange = () => {},
  options = [],
  placeholder = '请选择',
  opts = {},
}) {
  const _options = (options || []).map(formatOption);
  return (
    <AntSelect
      id={id}
      value={value}
      onChange={onChange}
      options={_options}
      placeholder={placeholder}
      showSearch
      filterOption={filterOptionByName}
      {...opts}
    />
  );
}
