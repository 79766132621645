import React, { useEffect } from 'react';
import { Divider } from 'antd';
import './index.less';

export default function LineWithTitle({ field, label, value = '', opt = {} }) {
  useEffect(() => {
    const a = document.getElementsByClassName('TUI-Line-String-Id');
    if (!a)
      return;
    for (let i = 0; i < a.length; i++)
      a[i].parentNode.parentNode.parentNode.parentElement.setAttribute('class', 'TUI-Line-String-Title');
  }, []);

  return (
    <Divider
      className="TUI-Line-String-Id"
      dashed
      orientation="left"
      plain
      {...opt}
    >
      <span style={{ color: '#969799' }}>
        {label}
      </span>
    </Divider>
  );
}
