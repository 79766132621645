export default (opts = [], ids = []) => {
  const tobeFined = ids;
  const finded = [];
  const match = (d) => {
    const i = tobeFined.indexOf(`${d.id}`);
    if (i > -1) {
      finded.push(d);
      tobeFined.splice(i, 1);
      return true;
    }
    return false;
  };
  const ended = () => {
    return !tobeFined.length;
  };
  const getLeafs = (opt) => {
    return opt.children && opt.children.length ? opt.children : undefined;
  };
  const tryDeep = (node, deepPath) => {
    if (ended())
      return;
    deepPath.push(node);// TODO 路径不正确
    const leafs = getLeafs(node);
    if (!match(node)) {
      deepPath.pop();
      return;
    }
    if (leafs) {
      for (const leaf of leafs)
        tryDeep(leaf, deepPath);
    }
  };
  const walk = (opts, path) => {
    const deepPath = path || [];
    for (const opt of opts)
      tryDeep(opt, deepPath);
  };
  walk(opts);
  return finded;
};
