import * as React from 'react';
import type { UniqueIdentifier } from '@dnd-kit/core';
import type { AnimateLayoutChanges, NewIndexGetter } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import type { Props, itemProps, onFetchServiceProps } from './types';
import { Item } from './components';

interface SortableItemProps {
  isDarag?: boolean;
  animateLayoutChanges?: AnimateLayoutChanges;
  disabled?: boolean;
  getNewIndex?: NewIndexGetter;
  id: UniqueIdentifier;
  index: number;
  handle: boolean;
  useDragOverlay?: boolean;
  style: (values: any) => React.CSSProperties;
  renderItem?: (args: any) => React.ReactElement;
  wrapperStyle: Props['wrapperStyle'];
  name?: string | React.ReactElement;
  icon?: React.ReactNode;
  selectItem?: itemProps;
  nameWidth?: number | string;
  extraContent?: onFetchServiceProps;
  item?: object;
}

export default function SortableItem({
  isDarag,
  disabled,
  animateLayoutChanges,
  getNewIndex,
  handle,
  id,
  index,
  name,
  item,
  icon,
  selectItem = { id: 0, name: '' },
  nameWidth,
  extraContent,
  style,
  renderItem,
  useDragOverlay,
  wrapperStyle,
}: SortableItemProps) {
  const {
    active,
    attributes,
    isDragging,
    isSorting,
    listeners,
    overIndex,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
    disabled,
    getNewIndex,
  });

  return (
    <Item
      ref={setNodeRef}
      isDarag={isDarag}
      value={name}
      item={item}
      icon={icon}
      disabled={disabled}
      dragging={isDragging}
      isSelected={selectItem.id === id}
      nameWidth={nameWidth}
      extraContent={extraContent}
      sorting={isSorting}
      handle={handle}
      handleProps={
        handle
          ? {
              ref: setActivatorNodeRef,
            }
          : undefined
      }
      renderItem={renderItem}
      index={index}
      style={style({
        index,
        id,
        isDragging,
        isSorting,
        overIndex,
      })}
      transform={transform}
      transition={transition}
      wrapperStyle={wrapperStyle?.({ index, isDragging, active, id })}
      listeners={listeners}
      data-index={index}
      data-id={id}
      dragOverlay={!useDragOverlay && isDragging}
      {...attributes}
    />
  );
}
