import { App, ConfigProvider, Spin, theme } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import type {
  RouterProviderProps,
} from 'react-router-dom';
import {
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import type { AppProps, ConfigProviderProps } from 'antd/lib';
import { getGlobalThemeToken, globalCommonStyle } from '../../index';
import type { AppConfigContextType } from '../../context/AppConfigContext';
import { AppConfigContextProvider } from '../../context/AppConfigContext';
import type { UserContextType } from '../../context/UserContext';
import { UserContextProvider } from '../../context/UserContext';
import type { ThemeContextType } from '../../context/ThemeContext';
import { ThemeContextProvider } from '../../context/ThemeContext';
import type { LayoutContextType } from '../../context/LayoutContext';
import { LayoutContextProvider } from '../../context/LayoutContext';

export interface TUIAppProps {
  /**
   * app context 初始值
   */
  appConfigDefaultStatus?: AppConfigContextType;
  /**
   * 用户信息初始值
   */
  userDefaultStatus?: UserContextType;
  themeDefaultStatus?: ThemeContextType;
  configProviderProps?: ConfigProviderProps;
  appProps?: AppProps;
  layoutDefaultStatus?: LayoutContextType;
  routerProviderProps?: RouterProviderProps;

  selfRoutes?: any;
}

/** 主题的步长 */
function getSizeStep(size) {
  const sizeOpt = {
    small: 3,
    middle: 4,
    large: 5,
  };
  return sizeOpt[size];
}

export default function TUIApp({
  appConfigDefaultStatus,
  userDefaultStatus,
  themeDefaultStatus,
  configProviderProps,
  appProps,
  layoutDefaultStatus,
  routerProviderProps,
  selfRoutes,
}: TUIAppProps) {
  return (
    <AppConfigContextProvider defaultStatus={appConfigDefaultStatus}>
      <UserContextProvider defaultStatus={userDefaultStatus}>
        <ThemeContextProvider defaultStatus={themeDefaultStatus}>
          {(themeStatus) => {
            const { sysMode, themeType, fontSize, size, borderRadius, defaultStyle = {} } = themeStatus;
            const globalThemeToken = getGlobalThemeToken({ themeType, sysMode });
            // 全局存的主题配置
            const resultThemeToken = {
              algorithm: sysMode === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
              token: {
                fontSize,
                fontSizeIcon: fontSize,
                sizeStep: getSizeStep(size),
                borderRadius,
                ...globalThemeToken,
              },
            };
            return (
              <ConfigProvider
                theme={{ cssVar: true, hashed: false, ...resultThemeToken }}
                locale={zhCN}
                {...configProviderProps}
              >
                <App {...appProps}>
                  <div style={{ ...globalCommonStyle(), ...defaultStyle }}>
                    <LayoutContextProvider defaultStatus={layoutDefaultStatus}>
                      {
                        (layoutStatus) => {
                          if (selfRoutes)
                            return selfRoutes(layoutStatus);

                          const router = createBrowserRouter(layoutStatus.routes);
                          return (
                            <RouterProvider
                              router={router}
                              fallbackElement={<Spin />}
                              {...routerProviderProps}
                            />
                          );
                        }
                      }
                    </LayoutContextProvider>
                  </div>
                </App>
              </ConfigProvider>
            );
          }}
        </ThemeContextProvider>
      </UserContextProvider>
    </AppConfigContextProvider>
  );
}
