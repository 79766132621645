import { Input } from 'antd';

export default function TUITextArea({ id, value, field, onChange, placeholder, opts = {} }) {
  return (
    <Input.TextArea
      id={id}
      name={field}
      value={value}
      placeholder={placeholder || '请输入'}
      onChange={e => onChange(e.target.value)}
      {...opts}
    />
  );
};
