/**
 * 获取搜索参数
 * @param key - 参数的键名
 * @param search - 搜索字符串，默认为location.search
 * @returns 搜索参数的值
 */
export function getSearchParam(key: string, search: string = location.search) {
  const searchParams = new URLSearchParams(search);
  return searchParams.get(key);
}

/**
 * 获取查询参数
 * @param search - 查询字符串，默认为location.search
 * @returns 查询参数对象
 */
export function getSearchParams(search: string = location.search) {
  const searchParams = new URLSearchParams(search);
  const params: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
}
