import { Button } from 'antd';
import TUIIcon from '../TUIIcon';
import './index.less';

export default function TUIButton({
  children,
  icon,
  iconStyle = {},
  className,
  ...rest
}) {
  return (
    <Button
      className={['TUI-Button', className].filter(d => !!d).join(' ')}
      icon={typeof (icon) == 'string' ? <TUIIcon type={icon} style={iconStyle} /> : icon}
      {...rest}
    >
      {children}
    </Button>
  );
};
