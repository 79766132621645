import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Card, Col, Flex, Row } from 'antd';
import { useThemeContext } from '../../index';
import './index.less';

function getSize(size = 'middle') {
  const sizeOpt = {
    small: 12,
    middle: 16,
    large: 24,
  };
  return sizeOpt[size];
}

interface cardItem {
  id: number | string;
  children?: undefined | ReactNode;
}
interface colSpanProps {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  llg: number;
}
interface TUICardProps {
  cardData: Array<cardItem>;
  ColProps?: object;
  renderCardProps?: Function;
  renderChildren?: Function;
  colSpan?: colSpanProps;
  size?: 'small' | 'middle' | 'large';
  style?: object;
  cardSelectedTitle?: string;
  onItemSelect?: Function;
}

export default function TUICardGrid({
  cardData = [],
  ColProps = {},
  renderCardProps,
  renderChildren,
  colSpan = {
    xs: 24,
    sm: 12,
    md: 8,
    lg: 6,
    llg: 4,
  },
  size: sizeDefault,
  cardSelectedTitle, // 是否在鼠标悬浮时展示选择按钮
  onItemSelect = () => {},
  style = {},
}: TUICardProps) {
  const { size: themeSize } = useThemeContext();

  const size = sizeDefault || themeSize;

  const cardProps = (item, index) => renderCardProps ? renderCardProps(item, index) : {};

  const CardRowRef = useRef();
  const [cardWidth, setCardWidth] = useState(0);

  /**
   * 放几个
   * 24/1   24
   * 24/2   12
   */
  const getColSpan = (cardWidth) => {
    const { xs, sm, md, lg, llg } = colSpan;
    if (cardWidth >= 1400)
      return llg;
    if (cardWidth >= 900 && cardWidth < 1400)
      return lg;
    if (cardWidth >= 600 && cardWidth < 900)
      return md;
    if (cardWidth >= 400 && cardWidth < 600)
      return sm;
    if (cardWidth < 400)
      return xs;

    return 6; // 4个
  };

  // 浏览器窗口高度发生改变触发
  const resizeChange = (e) => {
    setCardWidth(e.contentRect.width);
  };

  useEffect(() => {
    const element = CardRowRef.current;
    if (!element)
      return;

    // 监听的函数
    const resize = new ResizeObserver((e) => {
      if (!e || !Array.isArray(e) || !e.length)
        return;
      for (const ent of e)
        resizeChange(ent);
    });
    // 传入监听对象
    resize.observe(element);

    // 及时销毁监听函数（重要!!!）
    return () => {
      resize && resize.unobserve(element);
    };
  }, [cardData]);

  return (
    <Row className="TUI-CardGrid" ref={CardRowRef} align="stretch" gutter={[getSize(size), getSize(size)]}>
      {cardData.map((item, index) => (
        <Col
          key={item.id}
          span={getColSpan(cardWidth)}
          {...ColProps}
          className="TUI-CardGrid-Col-Item"
        >
          <Card hoverable bordered {...cardProps(item, index)} style={{ ...style }}>
            {renderChildren ? renderChildren(item, index) : item.children}
          </Card>
          {cardSelectedTitle && (
            <Flex
              align="center"
              justify="center"
              className="TUI-CardGrid-Col-Item-Selected"
              style={{ width: `calc(100% - ${getSize(size)}px)` }}
              onClick={() => onItemSelect(item)}
            >
              {cardSelectedTitle}
            </Flex>
          )}
        </Col>
      ))}
    </Row>
  );
};
