import { PureComponent } from 'react';
import { ConfigProvider, Table } from 'antd';
import { TUIIconPark, TUISkeleton } from '../../index';
import { TableCardIconColor } from '../../common';

class StandardTable extends PureComponent {
  handleTableChange(pagination, filters, sorter) {
    this.props.onChange(pagination, filters, sorter);
  }

  render() {
    const {
      data,
      pagination,
      loading,
      columns,
      rowKey = 'id',
      scrollX,
      rowSelection,
      className = '',
      size,
      notShowIcon,
      turnIcon,
      cardIcon = 'folder-close-dmbcdd9d',
      ...otherProps
    } = this.props;

    const paginationProps = {
      showSizeChanger: true,
      showQuickJumper: true,
      ...pagination,
      size: 'middle',
      showTotal: total => `共${total}条数据`,
    };

    const sizeOpts = {
      small: {
        cellPaddingInlineMD: 8,
        cellPaddingBlockMD: 8,
      },
      middle: {
        cellPaddingInlineMD: 8,
        cellPaddingBlockMD: 12,
      },
      large: {
        cellPaddingInlineMD: 16,
        cellPaddingBlockMD: 16,
      },
    };

    const getTableToken = () => {
      return sizeOpts[size];
    };

    const columnsResult = notShowIcon
      ? columns
      : [{
          dataIndex: 'cardIconType',
          key: 'cardIconType',
          title: '',
          // width: 34, 指定宽度的话，树形结构会有问题
          render: (text, record, index = 0) => {
            return <TUIIconPark type={record.cardIconType || cardIcon} style={{ color: turnIcon ? TableCardIconColor[index % 6] : `var(--ant-color-primary, #1677FF)` }} />;
          },
        }, ...columns];

    return (
      <div className={['TUI-Table', className].join(' ')}>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                /* 这里是你的组件 token */
                ...getTableToken(),
              },
            },
          }}
        >
          {
            loading && !columns.length
              ? <TUISkeleton type="table" />
              : (
                <Table
                  size="middle"
                  bordered
                  {...otherProps}
                  scroll={otherProps && otherProps.scroll ? otherProps.scroll : { x: scrollX || false }}
                  loading={loading}
                  rowKey={rowKey}
                  dataSource={data}
                  columns={columnsResult}
                  rowSelection={rowSelection || null}
                  pagination={pagination ? paginationProps : false}
                  onChange={this.handleTableChange.bind(this)}
                />
                )
}
        </ConfigProvider>
      </div>
    );
  }
}

export default StandardTable;
