import { Select } from '../../index';

export default function MultSelect({
  id,
  value,
  onChange = () => {},
  options = [],
  placeholder = '请选择',
  opts = {},
}) {
  return (
    <Select
      id={id}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      opts={{
        filterOption: (val, obj = {}) => (obj.name || '').includes(val),
        ...opts,
        mode: 'multiple',
      }}
    />
  );
};
