import React from 'react';
import './index.less';
import Modal from '../Modal';

export default class ImageList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scale: false,
      previewImg: null,
    };
  }

  onClose() {
    this.setState({
      scale: false,
    });
  }

  onShow(url) {
    this.setState({
      scale: true,
      previewImg: url,
    });
  }

  render() {
    const { scale, previewImg } = this.state;
    const { value = [], opts = {} } = this.props;
    const {
      previewWidth = 500,
      previewHeight = 'auto',
      width = 128,
      height = 128,
    } = opts;
    return (
      <span className="TUI-ImageList">
        {value.map(url => (
          <img
            src={url}
            onClick={() => this.onShow(url)}
            width={width}
            height={height}
          />
        ))}

        {scale
          ? (
            <Modal
              width={previewWidth + 44}
              title={null}
              visible={scale}
              onOk={this.onClose.bind(this)}
              onCancel={this.onClose.bind(this)}
              footer={null}
              wrapClassName="TUI-ImageList-Modal"
            >
              <img
                src={previewImg}
                alt=""
                width={previewWidth}
                height={previewHeight}
              />
            </Modal>
            )
          : null}
      </span>
    );
  }
}
