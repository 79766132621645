import { useState } from 'react';
import { Button, message } from 'antd';
import { RequrstAxios } from '../../utils/request';
import { downloadReport } from '../../utils/download';

interface ExportButtonProps {
  id?: string;
  api: string;
  params?: object;
  type?: string;
  children: Element | string;
  fileName?: string;
  endEss?: string;
  buttonType?: string;
  style?: object;
  opts?: object;
}
export default function ExportButton({
  id,
  api,
  params = {},
  endEss = 'zip',
  type = 'application/vnd.ms-excel',
  fileName,
  children,
  buttonType = 'default',
  style,
  opts = {},
}: ExportButtonProps) {
  const [loading, setLoading] = useState(false);
  const exportFile = (p) => {
    return RequrstAxios(api, {
      ...p,
    });
  };

  const onExport = async () => {
    setLoading(true);
    await exportFile({ ...params })
      .then((res) => {
        const { data } = res || {};
        if (data.type == 'application/json') {
          // 生成文件失败
          const reader = new FileReader();
          reader.onload = function (e) {
            const mesg = JSON.parse(reader.result).message;
            message.error(mesg || '导出失败');
          };
          reader.readAsText(res.data);
          return;
        }
        downloadReport(fileName, res, endEss, type);
      })
      .catch((e) => {
        message.error(e.message || '生成失败');
      });
    setLoading(false);
  };

  return (
    <Button
      id={id}
      type={buttonType}
      onClick={onExport}
      loading={loading}
      disabled={loading}
      style={style}
      {...opts}
    >
      {children}
    </Button>
  );
}
