import { Breadcrumb, ConfigProvider } from 'antd';
import ClassNames from 'classnames';
import { useMatches } from 'react-router-dom';
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { TUIThreeLevelMenu } from '../../index';
import './Body.less';
import { useLayoutContext } from '../../context/LayoutContext';
import { getMatchRoutes } from '../../utils/routes';

function findLastItemIndex(items, currentRoute) {
  let lastIdx = 0;
  for (let i = items.length - 1; i > 0; i--) {
    if (items[i].idx === currentRoute.idx) {
      lastIdx = i;
      break;
    }
  }
  return lastIdx;
}

function itemRender(currentRoute, _, items, paths) {
  const isLast = items.length === paths.length && currentRoute?.path === items[items.length - 1]?.path;
  return isLast
    ? (
      <span>{currentRoute.title}</span>
      )
    : (
      <a onClick={() => {
        const clickIdx = findLastItemIndex(items, currentRoute);
        const currentIdx = items.length - 1;
        const backLen = (clickIdx - currentIdx);
        backLen !== 0 && window.history.go(backLen);
      }}
      >
        {currentRoute.title}
      </a>
      );
}

export default function Body({
  children,
}) {
  const matches = useMatches();
  const { routes } = useLayoutContext();

  const matchRoutes = getMatchRoutes(routes, matches);
  const items: ItemType[] = matchRoutes.filter(r => r.fullPath !== '/' && r.label).map(({ label, icon, path }, idx) => ({
    title: <>
      {icon ? <span className="crumbIcon">{icon}</span> : null}
      {label}
    </>,
    path,
    idx,
  }));

  const TUIThreeLevelTabs = <TUIThreeLevelMenu />;

  return (
    <div className={ClassNames(`TUI-Body`)} id="body">
      <div className="TUI-Breadcrumb">
        <ConfigProvider>
          <Breadcrumb
            items={items}
            itemRender={itemRender}
          />
        </ConfigProvider>
      </div>
      {TUIThreeLevelTabs}
      <div className={ClassNames('TUI-Content', { 'TUI-Content-Three': !!TUIThreeLevelTabs })}>
        {children}
      </div>
    </div>
  );
}
