import { Card as AntCard, ConfigProvider } from 'antd';

export default function TUICard({ children, tokenProps = {}, title, extra, style = {}, opts = {} }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          ...tokenProps,
        },
      }}
    >
      <AntCard
        title={title}
        extra={extra}
        style={style}
        {...opts}
      >
        {children}
      </AntCard>
    </ConfigProvider>
  );
};
