function cloneCanvas(oldCanvas) {
  const newCanvas = document.createElement('canvas');
  const context = newCanvas.getContext('2d');
  newCanvas.width = oldCanvas.width;
  newCanvas.height = oldCanvas.height;
  context.drawImage(oldCanvas, 0, 0);
  return newCanvas;
}
export default (node) => {
  const n = node.cloneNode(true);
  const cs = node.querySelectorAll('canvas');
  const ns = n.querySelectorAll('canvas');
  cs.forEach((c, i) => {
    const t = ns[i]; // TODO 严谨一些
    const p = t.parentElement;
    const d = cloneCanvas(c);
    p.replaceChild(d, t);
  });
  return n;
};
