import React from 'react';

function cut(itor, len) {
  return Array.from({ length: len }).map(() => {
    const obj = itor.next();
    return obj.value;
  });
}
function reduce(arr) {
  return arr.reduce((p, c) => p.concat(c), []);
}
// source = [1,2,3]
// dest = [a, b, c]
// result = [0,a]...[2,c]
function one2one(itor, slen, dlen) {
  const select = (d, i) => [d[0][i], d[1][i]];
  const res = cut(itor, Math.max(dlen, slen)).map(select);
  return reduce(res);
}
/*
example:
    source = [null,1,2,3]
    dest = [a,b,c]
    result = [null, a, 1, b, 2, c , 3]
*/
export function combine({ select = one2one, source = [], dest = [] }) {
  let flag = true;
  let index = 0;
  const iterator = {
    [Symbol.iterator]: () => {
      return {
        next: (over) => {
          if (over) {
            return { done: true, value: [], index };
          }
          else {
            flag = !flag;
            return {
              index: index++,
              done: false,
              value: [[...source], [...dest]],
              source,
              dest,
              flag,
            };
          }
        },
      };
    },
  };
  const result = select(iterator[Symbol.iterator](), source.length, dest.length);
  return result.filter(d => d !== null);
}
function CombineComponent({ select, source, children }) {
  const dest = React.Children.toArray(children);
  return combine({
    select,
    source,
    dest,
  });
}
CombineComponent.combine = combine;
export default CombineComponent;
