import React, { useEffect } from 'react';
import { Menu } from 'antd';
import './index.less';

interface dataProps {
  id: number | string;
  name: string | React.ReactNode;
}
interface TUIListProps {
  data: Array<dataProps>;
  onItemClick: Function;
}
function TUIList(props) {
  const { data = [], onItemClick }: TUIListProps = props;

  const [active, setActive] = React.useState();

  useEffect(() => {
    if (data && data[0]) {
      setActive(data[0].id);
      onItemClick(data[0]);
    }
  }, []);

  return (
    <div className="ContentWarp">
      <Menu selectedKeys={[`${active}`]}>
        {data.map(d => (
          <Menu.Item
            key={d.id}
            onClick={() => {
              setActive(d.id);
              onItemClick(d);
            }}
          >
            <div className="Text">{d.name}</div>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
}

export default React.memo(TUIList);
