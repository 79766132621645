import {
  Suspense,
  forwardRef,
  lazy,
  useImperativeHandle,
  useState,
} from 'react';
import { Divider, Flex } from 'antd';
import Form from '../Form';
import Modal from '../Modal';
import Button from '../Button';
import Input from '../Input';
import Search from '../Search';
import { FieldWrap } from '../FormItem/wraper';
import { isValidate, validateSchema } from '../../lib/validator';
import './index.less';

function getShortSchemaFields(schema) {
  const keys = Object.keys(schema);
  if (!keys.length)
    return [];
  const shorts = keys.filter(key => !!schema[key].quickFilter);
  if (shorts.length)
    return shorts;
  return [keys[0]];
}
function getRestSchema(schema) {
  const shorts = getShortSchemaFields(schema);
  if (!shorts.length)
    return {};
  const keys = Object.keys(schema).filter(k => !shorts.includes(k));
  const r = {};
  for (const k of keys)
    r[k] = schema[k];

  return r;
}
function hasRestSchema(schema) {
  const scm = getRestSchema(schema);
  const keys = Object.keys(scm);
  return !!keys.length;
}
function getMainError(errors) {
  if (typeof errors !== 'object')
    return '';
  let error = '';
  for (const key in errors) {
    const e = errors[key];
    if (e !== undefined) {
      error = e;
      break;
    }
  }
  return error;
}
function hasRestValues(schema, fields) {
  return Object.keys(schema).some(key => fields[key]);
}
const AdvancedFilter = forwardRef((props, ref) => {
  const {
    defaultValue = {},
    fields,
    schema,
    onSearch = () => { },
    onReset,
    onFieldChange,
    children,
    onChange = () => { },
    setTempValue,
    showShortSchemaLabel = false,
    showClear = false,
    loading = false,
    ...nextProps
  } = props;
  const [visible, setVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [state, setState] = useState(defaultValue);
  const [modalPosition, setModalPosition] = useState(['auto', 'auto']);
  const shortSchemaFields = getShortSchemaFields(schema);
  const restSchema = getRestSchema(schema);
  const mainError = getMainError(errors);
  //   const shortSchema = shortSchemaFields.reduce(
  //     (p, c) => Object.assign(p, { [c]: schema[c] }),
  //     {}
  //   );
  const onModalClose = () => {
    setVisible(false);
    setState({
      ...shortSchemaFields.reduce(
        (p, c) => Object.assign(p, { [c]: state[c] }),
        {},
      ),
      ...fields,
    });
    setErrors({});
  };
  const onSubmit = () => {
    if (isValidate(restSchema, state)) {
      onChange({
        ...state,
      });
      onSearch(state).then(() => {
        setErrors({});
        setVisible(false);
      });
    }
    else {
      setErrors(validateSchema(restSchema, state));
    }
  };
  const onClear = () => {
    setState(defaultValue);
    onReset();
    setErrors({});
  };

  const willSearch = (e) => {
    const { x, y, right } = e.target.getBoundingClientRect();
    const w = window.document.body.clientWidth;
    const MODAL_WIDTH = 425;
    const GAP = 10;
    const leftmost = w - MODAL_WIDTH - GAP;
    if (w - x < MODAL_WIDTH)
      setModalPosition([right - MODAL_WIDTH, y + 30]);
    else
      setModalPosition([Math.min(leftmost, x), y + 30]);

    setVisible(true);
  };

  useImperativeHandle(ref, () => ({
    doClear: () => {
      onClear();
    },
    setFields: (k, v) => {
      setState({
        ...(typeof k == 'string'
          ? {
              ...state,
              [k]: v,
            }
          : k),
      });
    },
  }));

  if (!shortSchemaFields.length)
    return null;
  return (
    <div className="TUI-AdvancedFilter">
      {/* <div className="TUI-AdvancedFilter-Short"> */}
      <Flex gap={16} align="center" wrap="wrap" className="TUI-AdvancedFilter-Fields">
        {shortSchemaFields.map((shortKey, key) => {
          const shortSchema = schema[shortKey];
          const Field = shortSchema
            ? typeof shortSchema.type === 'string'
              ? ['Input', 'InputNumber', 'Textarea', 'TextArea'].includes(
                  shortSchema.type,
                )
                  ? Search
                  : lazy(() => import(`../${shortSchema.type}/index.tsx`))
              : shortSchema.type === Input
                ? Search
                : shortSchema.type
            : null;
          // TODO 其他组件
          if (!Field)
            return null;
          const elm = (
            <Suspense>
              <Field
                placeholder={shortSchema.placeholder}
                value={state[shortKey]}
                onChange={(v) => {
                  if (loading)
                    return;
                  const req = {
                    ...state,
                    [shortKey]: v,
                  };
                  setState(req);
                  onChange(req);
                  onSearch(req);
                }}
                fields={state}
                options={shortSchema.options}
                opts={{
                  allowClear: true,
                  ...shortSchema.opts,
                }}
              />
            </Suspense>
          );
          return (
            <span className="TUI-AdvancedFilter-Short-Value" key={key}>
              {Field === Search
                ? (
                    elm
                  )
                : (
                  <FieldWrap name={shortSchema.name}>{elm}</FieldWrap>
                  )}
            </span>
          );
        })}
        {hasRestSchema(schema) ? (
          <Flex align="center" className="TUI-AdvancedFilter-More">
            <span className="TUI-AdvancedFilter-Divider" />
            {/* <Divider type="vertical"/> */}
            <span
              className={[
                'TUI-AdvancedFilter-Filter',
                hasRestValues(restSchema, fields)
                  ? 'TUI-AdvancedFilter-Filtered'
                  : '',
              ].join(' ')}
              onClick={willSearch}
            >
              高级搜索
            </span>
            <span className="TUI-AdvancedFilter-Reset" onClick={onClear}>
              清空
            </span>
          </Flex>
        ) : showClear
          ? (
            <>
              <Divider type="vertical" />
              <span className="TUI-AdvancedFilter-Reset" onClick={onClear}>清空</span>
            </>
            )
          : null}

      </Flex>
      <Flex gap={16} justify="space-between">
        {children}
      </Flex>
      {/* </div> */}

      <Modal
        destroyOnClose={true}
        maskClosable={true}
        open={visible}
        footer={(
          <div className="TUI-AdvancedFilter-Submit">
            <span className="TUI-AdvancedFilter-MainError">{mainError}</span>
            <Button onClick={onModalClose}>
              取消
            </Button>
            <Button type="primary" onClick={onSubmit}>
              搜索
            </Button>
          </div>
        )}
        title="高级搜索"
        closable={false}
        onCancel={onModalClose}
        className="TUI-AdvancedFilter-Modal"
        wrapClassName="TUI-AdvancedFilter-Modal_wrap"
        width={500}
        style={{
          left: modalPosition[0],
          top: modalPosition[1],
        }}
        mask={false}
      >
        <Form
          schema={restSchema}
          fields={state}
          onChange={fields =>
            setState({
              ...state,
              ...fields,
            })}
          onFieldChange={(k, v) => {
            setState({
              ...state,
              [k]: v,
            });
            setTempValue({
              ...state,
              [k]: v,
            });
          }}
        />

      </Modal>
    </div>
  );
});

export default AdvancedFilter;
