import { useRef } from 'react';
import { Divider, Dropdown, Space } from 'antd';
import ClassNames from 'classnames';
import type { ComponentBaseProps } from '../../../types';
import { useAppConfigContext } from '../../../index';
import {
  AppBox,
  JumpSystem,
  ModifyPassword,
  RevisePhoneNumber,
  ThemeModal,
  UserInfo,
} from './components/index';
import { logout } from './components/service';
import './index.less';

const items = [
  {
    label: '主题配置',
    key: '4',
    icon: <i className="iconfont">&#xe74a;</i>,
  },
  {
    label: '修改手机号',
    key: '1',
    icon: <i className="iconfont">&#xe6d2;</i>,
  },
  {
    label: '修改密码',
    key: '2',
    icon: <i className="iconfont">&#xe65a;</i>,
  },
  {
    label: '退出系统',
    key: '3',
    icon: <i className="iconfont">&#xe679;</i>,
    danger: true,
  },
];

function Tools({
  classNames,
  children,
}: ComponentBaseProps) {
  const { logoutProps = {}, toolSlot, isShowAppIQR, isNotJumpTongyi } = useAppConfigContext();
  const { api = '/saas/user/logout', logoutService, callBack } = logoutProps;

  const resetRef = useRef();
  const modifyRef = useRef();
  const userInfoRef = useRef();
  const ThemeModalRef = useRef();

  const { account, info, simpleName, name } = JSON.parse(localStorage.getItem('userInfo') || '{}');

  /** 退出登录 */
  const handleLogout = () => {
    if (logoutService) {
      logoutService();
      return;
    }
    logout(api).finally(() => {
      if (callBack) {
        callBack();
        return;
      }
      const userFormUrl = localStorage.getItem('formUrl');
      window.location.href = userFormUrl ? `${userFormUrl}/login` : `/login?prePath=${encodeURIComponent(window.location.href)}`;
    });
  };
  const onToolMenuClick = (e) => {
    const id = +e.key;
    if (id === 4)
      ThemeModalRef.current?.handle();

    if (id === 1)
      resetRef.current?.handle();

    if (id === 2)
      modifyRef.current?.handle();

    if (id === 3)
      handleLogout();
  };

  const menuProps = {
    items,
    onClick: onToolMenuClick,
  };

  return (
    <div className={ClassNames(`TUI-ToolBox`, classNames)}>
      <Space split={<Divider type="vertical" />} align="center">
        {children}
        {toolSlot}
        {!isNotJumpTongyi && (
          <JumpSystem />
        )}
        {isShowAppIQR && (
          <AppBox />
        )}
        <Dropdown menu={menuProps}>
          <i className="iconfont">&#xe68c;</i>
        </Dropdown>

        <span
          className="User"
          onClick={() => {
            userInfoRef.current.handle();
          }}
        >
          <img className="Avatar" src={window.AVATAR || 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/ThemeAssets/avatar.png'} />
          {simpleName || name}
        </span>
      </Space>

      {/* 修改密码等 */}
      <RevisePhoneNumber ref={resetRef} />
      <ModifyPassword ref={modifyRef} />
      <UserInfo ref={userInfoRef} />

      {/* 主题配置 */}
      <ThemeModal ref={ThemeModalRef} />
    </div>
  );
}

export default Tools;
