import { Component } from 'react';
import { Upload } from 'antd';
import isEqual from 'lodash.isequal';
import { getToken } from 'tengits-fe-utils';
import { Button, message } from '../../index';
import Modal from '../Modal';
import { getNameFromUrl, getThumbUrl, guid } from './common';
import './index.less';

const Dragger = Upload.Dragger;

export default class DrawableUploader extends Component {
  state = {
    previewVisible: false,
    previewImage: '',
    fileList: [],
  };

  lastValue = [];
  mode = 'StringArray';

  format(props) {
    const { value = [] } = props;
    let temp;
    if (Array.isArray(value) && value.length > 0) {
      temp = value.map((item, index) => {
        this.setMode(item); // fix mutitime
        const uid = guid();
        if (typeof item === 'object') {
          const id = item.id ? item.id : uid;
          return {
            id,
            uid: id,
            name: getNameFromUrl(item.url || ''),
            status: 'done',
            url: item.url,
            delFlag: item.delFlag ? 1 : 0,
            thumbUrl: getThumbUrl(item.url),
          };
        }

        return {
          id: uid,
          uid,
          name: getNameFromUrl(item || ''),
          status: 'done',
          url: item,
          delFlag: item.delFlag ? 1 : 0,
          thumbUrl: getThumbUrl(item),
        };
      });
    }
    else {
      temp = [];
    }
    this.lastValue = [...temp];
    this.setState({
      fileList: temp.filter(d => !d.delFlag),
    });
  }

  componentDidMount() {
    this.format(this.props);
  }

  componentWillReceiveProps(props) {
    if (
      !isEqual(props.value, this.props.value)
      && !this.props.value
      && !this.state.fileList.length
    )
      this.format(props || []);
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file) => {
    const fileType = file.type || file.url.replace(/.*?(\.[^./]*)$/, '$1');
    const url = file.url ? file.url : URL.createObjectURL(file.originFileObj);
    if (!['.jpg', '.png', 'image/png', 'image/jpg', 'image/jpeg'].includes(fileType)) {
      window.open(url);
      return;
    }
    this.setState({
      previewImage: url,
      previewVisible: true,
    });
  };

  assetMaxLength = (fileList) => {
    const { maxLength } = this.props.opts || {};
    if (Array.isArray(fileList) && fileList.length > maxLength)
      throw new Error(`文件个数不能超过${maxLength}个`);
  };

  customRequest = ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials,
  }) => {
    const { opts } = this.props;
    const up = () => {
      opts
      && opts.customRequest(file, onProgress).then((res) => {
        this.onAdd(
          file,
          res.Location.startsWith('http')
            ? res.Location.replace('http://', 'https://')
            : `https://${res.Location}`,
        );
        onSuccess(res, file);
      });
    };
    try {
      this.assetMaxLength(this.state.fileList);
      opts && opts.customRequest && up();
    }
    catch (e) {
      message.error(e.message);
    }
    return {
      abort() {
        console.log('upload progress is aborted.');
      },
    };
  };

  setMode = (file) => {
    if (typeof file === 'string')
      this.mode = 'StringArray';
    if (typeof file === 'object')
      this.mode = 'ObjectArray';
  };

  formatObjectValue = (file, url) => {
    const data = {
      id: file.uid || 0,
      delFlag: 0,
      url,
      name: getNameFromUrl(url),
      thumbUrl: getThumbUrl(url),
    };
    return data;
  };

  onChange = (list) => {
    const { mode } = this;
    if (mode === 'StringArray') {
      this.props.onChange(list.map(d => d.url));
    }
    else {
      this.props.onChange(
        list.map(d => ({
          ...d,
          id: typeof d.id === 'number' ? d.id : 0,
        })),
      );
    }
  };

  onAdd = (file, url) => {
    const { mode, lastValue, formatObjectValue, onChange } = this;

    lastValue.push(formatObjectValue(file, url));

    onChange(lastValue);
  };

  onRemove = (file) => {
    const { mode, lastValue = [], onChange } = this;

    for (const i in lastValue) {
      const item = lastValue[i];
      if (item.id === file.uid) {
        if (typeof file.uid === 'number')
          item.delFlag = 1;
        else
          lastValue.splice(i, 1);

        break;
      }
    }

    onChange(lastValue);
  };

  handleChange = (info) => {
    const { fileList } = this.state;
    this.setState({
      fileList: info.fileList,
    });
  };

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const { id, opts = {} } = this.props;

    const { action, data, maxLength = 999, listType = 'picture-card', tplUrl = false, uploadTip = '' } = opts;
    const UploadComponent = opts.uploadMode === 'classics' ? Upload : Dragger;
    return (
      <div className="TUI-DrawableUploader">
        <Modal open={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <UploadComponent
          id={id}
          onChange={this.handleChange}
          data={data || {}}
          action={action}
          fileList={fileList}
          headers={{ Authorization: localStorage.getItem('token') }}
          onPreview={this.handlePreview}
          onRemove={this.onRemove}
          listType="picture"
          multiple={true}
          {...opts}
          customRequest={this.customRequest} // 防止opts覆盖customRequest
          disabled={opts.disabled ? true : !!(fileList && fileList.length >= maxLength)}
        >
          {opts.uploadMode === 'classics'
            ? (
                opts.disabled
                  ? null
                  : (
                    <Button className="TUI-DrawableUploader-Btn">
                      {opts.uploadIcon}
                      {' '}
                      {this.props.placeholder || '上传附件'}
                    </Button>
                    )
              )
            : (
              <p className="ant-upload-hint">
                {fileList && fileList.length >= maxLength
                  ? `${maxLength}/${maxLength} 达到最大上传数`
                  : this.props.placeholder || '点击或将文件拖拽至本处，实现上传'}
              </p>
              )}

        </UploadComponent>
        {uploadTip
        && (
          <span style={{ marginTop: 4, display: 'flex', alignItems: 'center' }}>
            <i className="iconfont">&#xe630;</i>
            {uploadTip}
          </span>
        )}
        {tplUrl
        && (
          <span style={{ marginTop: 4, display: 'flex', alignItems: 'center' }}>
            <i className="iconfont">&#xe62d;</i>
            <a href={`${tplUrl}&authorization=${getToken()}&systemId=${window.systemId}`}>
              下载模板
            </a>
          </span>
        )}
      </div>
    );
  }
}
