import * as React from 'react';
import { Flex, Tooltip, Typography, message } from 'antd';
import ClassNames from 'classnames';
import { Input, Select } from '../../../../../../index';
import type { MenuProps } from './libs';
import { TUIDropdown, getTooltipIcon } from './libs';
import './index.less';

const { useEffect, useRef, useState } = React;

const { Text } = Typography;
interface itemProps {
  id: number | string;
  pId?: number | string;
  name: string | React.ReactNode;
  type?: string;
  children?: Array<itemProps>;
}

interface ChangeDivComponentProps {
  type: 'tree' | 'leaf';
  mode?: 'string' | 'select';
  isSelected?: boolean;
  name: string | React.ReactNode;
  itr: itemProps;
  defaultLength?: number;
  editGroupItem?: null | itemProps;
  beforeAdd?: Function;
  onSaveLeaf?: Function;
  onCancelLeaf?: Function;
  sel_Options?: Array<itemProps>;
  menu?: Array<MenuProps>;
  setMenu?: Function;
  hasHandle?: boolean;
  nameWidth?: number | string;
  renderTooltip?: any;
}

export function ChangeDivComponent({
  hasHandle = false,
  type,
  mode = 'string',
  name = '',
  itr = { id: 0, name: '' },
  isSelected = false,
  editGroupItem = null,
  beforeAdd,
  onSaveLeaf,
  onCancelLeaf,
  sel_Options = [],
  setMenu = () => [],
  nameWidth,
  renderTooltip,
}: ChangeDivComponentProps) {
  const editInputRef = useRef();
  const [editInputValue, setEditInputValue] = useState(); //

  const showEditStatus = editGroupItem && editGroupItem.id === itr.id && (
    editGroupItem.type === 'leaf' && itr.pId ? editGroupItem.pId === itr.pId : true
  );

  useEffect(
    () => {
      if (editGroupItem) {
        setEditInputValue(editGroupItem.name);
        editInputRef.current && editInputRef.current.focus();
      }
    },
    [editGroupItem],
  );

  return (
    <Flex justify="space-between" align="center" className="flex folder">
      {showEditStatus
        ? mode === 'select'
          ? (
            <Select
              value={editInputValue}
              onChange={v => setEditInputValue(v)}
              options={sel_Options}
              placeholder="请选择分类"
              opts={{
                allowClear: false,
              }}
            />
            )
          : (
            <Input
              value={editInputValue}
              onChange={v => setEditInputValue(v)}
              placeholder="请输入分类名称"
              opts={{
                ref: editInputRef,
              }}
            />
            )
        : (renderTooltip
            ? (
              <Tooltip {...renderTooltip(name, itr)}>
                <Text
                  ellipsis
                  className={ClassNames('name_row', { hasHandle, hasDropMenu: setMenu(itr).length, isSelected })}
                  style={nameWidth ? { width: nameWidth } : {}}
                >
                  {name}
                </Text>
              </Tooltip>
              )
            : (
              <Text
                ellipsis={{ tooltip: name }}
                className={ClassNames('name_row', { hasHandle, hasDropMenu: setMenu(itr).length, isSelected })}
                style={nameWidth ? { width: nameWidth } : {}}
              >
                {name}
              </Text>
              )
          )}

      {showEditStatus
        ? (
          <span className="bts_bar">
            {(
              [
                {
                  title: '确认',
                  iconfont: 'ok',
                  onClick: (e) => {
                    e.stopPropagation();
                    // 校验
                    const isValidate = beforeAdd ? beforeAdd(editInputValue, itr) : true;
                    if (typeof isValidate === 'boolean' && isValidate === true) {
                      onSaveLeaf && onSaveLeaf(itr, editInputValue);
                      return;
                    }
                    message.error(isValidate);
                  },
                  color: '#6BC079',
                },
                {
                  title: '取消',
                  iconfont: 'cancel',
                  onClick: (e) => {
                    e.stopPropagation();
                    onCancelLeaf && onCancelLeaf(type, itr);
                  },
                  color: '#E14C4C',
                },
              ].map(it => getTooltipIcon({ ...it }))
            )}
          </span>
          )
        : (
          <div className={`TUIDropdownWarp `} style={type === 'tree' ? { marginRight: 12 } : {}}>
            <TUIDropdown
              menu={setMenu(itr)}
              itr={itr}
            />
          </div>
          )}
    </Flex>
  );
}
