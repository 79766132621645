import { Table as AntTable, ConfigProvider } from 'antd';

export function Table({ tokenProps = {}, columns = [], dataSource = [], pagination = {}, tableProps = {} }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          ...tokenProps,
        },
      }}
    >
      <AntTable
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        {...tableProps}
      />
    </ConfigProvider>
  );
}
