import React from 'react';
import { Empty, Flex } from 'antd';

const TUIEmpty: React.FC<{
  height?: number | string;
  image?: string;
}> = ({
  height = '100%',
  image = Empty.PRESENTED_IMAGE_SIMPLE,
}) => {
  return (
    <Flex align="center" justify="center" style={{ height, width: '100%' }}>
      <Empty image={image} />
    </Flex>
  );
};
export default TUIEmpty;
