import ClassNames from 'classnames';
import './index.less';

export default function BlockSelect({ value, onChange, options, opts = {} }) {
  const { multiple, filterValue, className } = opts || {};

  return (
    <div className={ClassNames('TUI-BlockSelect-Wrapper', className)}>
      {
      Array.isArray(options) && options.map((it) => {
        const selected = multiple ? (Array.isArray(value) ? value.includes(it.id) : false) : value == it.id;
        const isExit = Array.isArray(filterValue) && filterValue.length > 0 && !it.tagList.some(item => filterValue.includes(item));
        return (
          <div
            key={it.id}
            className={ClassNames('TUI-BlockSelect-BlockItem', { 'TUI-BlockItem-No-Selected': !selected, selected, isExit })}
            onClick={() => {
              if (multiple) {
                const t = Array.isArray(value) ? [...value] : [];
                if (t.includes(it.id)) {
                  t.splice(t.findIndex(item => item === it.id), 1);
                  onChange(t);
                }
                else {
                  t.push(it.id);
                  onChange(t);
                }
              }
              else if (value == it.id) {
                onChange(undefined);
              }
              else {
                onChange(it.id);
              }
            }}
          >
            {it.name}
          </div>
        );
      })
    }
    </div>
  );
};
