import * as TUI from '../../index';
import './index.less';

export default function ArrFields({
  id,
  value = [{ id: 0, value: '' }],
  options = [],
  placeholder,
  onChange,
  opts = {},
}) {
  const { fieldType = 'Input', maxNum = 99, disabled = false } = opts;
  const _onChange = (item, newValue) => {
    let data = [];
    if (!item) {
      data = [{ value: newValue }];
    }
    else {
      item.value = newValue;
      data = [...value];
    }
    onChange(data);
  };

  const _onAdd = () => {
    const data = [...value];
    data.push({ id: 0, value: '' });
    onChange(data);
  };

  const _onRemove = (index) => {
    const data = [...value];
    data.splice(index, 1);
    onChange(data);
  };

  const MyCompoent = TUI[fieldType] || TUI.Input;

  return (
    <div id={id}>
      {value.map((item, index) => (
        <div key={index} className="TUI-FieldItem">
          <MyCompoent
            style={{ flex: 1 }}
            value={item.value}
            placeholder={placeholder || '请输入'}
            onChange={e => _onChange(item, e)}
            options={options}
            opts={opts}
          />
          {!disabled && (
            <div onClick={index === 0 ? _onAdd : () => _onRemove(index)} className="TUI-Oprate">
              {index === 0 && value.length < maxNum
                ? (
                  <i className="iconfont">&#xe6b0;</i>
                  )
                : (
                  <i className="iconfont">&#xe62a;</i>
                  )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
