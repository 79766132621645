import dayjs from 'dayjs';
import { Button, Typography } from 'antd';
import { useCurrentMenuAuth } from '../AuthButton';
import * as TUI from '../../index';
import copy from '../../utils/copy';
import request from '../../utils/request';
import { serialRun } from '../../utils';
import DateTimePicker from '../DateTimePicker';
import Modal from '../Modal';
import { formatReq, formatRes } from './format';
import { getRule } from './rule';

// const Button = AuthButton();
const TUIIcon = TUI.TUIIcon;

const LIST_MODE = 'list';
const FORM_MODE = 'form';
const FILTER_MODE = 'filter';
const DETAIL_MODE = 'detail';
const _components = {
  DateTimePicker,
};
export function getComponent(name) {
  return _components[name];
}
export function addComponent(name, com) {
  _components[name] = com;
}
export function getDefaultFields(schema, record = {}) {
  return schema
    .map(s =>
      (typeof s.visible === 'function' ? !s.visible({ fields: record }) : s.visible === false) || !('defaultValue' in s)
        ? null
        : {
            [s.field]: s.defaultValue,
          },
    )
    .reduce((p, c) => Object.assign(p, c), {});
}
export function getOptions(params, schema, event) {
  const shs = schema.filter(
    d =>
      (d.optionApi
      && Array.isArray(d.optionTrigger)
      && d.optionTrigger.includes(event)) || Array.isArray(d.options),
  );
  return serialRun(
    shs.map((sh) => {
      // undefined不触发请求
      if (/onChange:/.test(event)) {
        const key = event.replace('onChange:', '');
        const value = params[key];
        if (value === undefined) {
          return Promise.resolve({
            [sh.field]: [],
          });
        }
      }
      if (!sh.optionApi && Array.isArray(sh.options)) {
        return {
          [sh.field]: sh.options,
        };
      }
      return request(sh.optionApi, formatReq(params, schema), sh.field).then(res => ({
        [sh.field]: res.data,
      }));
    }),
  ).then(res => res.reduce((p, c) => Object.assign(p, c), {}));
}
export function getRelatedOptionField(schema) {
  const res = schema
    .filter(d => Array.isArray(d.optionTrigger))
    .map(d =>
      d.optionTrigger
        .filter(x => x.startsWith('onChange:'))
        .map(m => m.replace('onChange:', '')),
    )
    .reduce((p, c) => p.concat(c), []);
  return Array.from(new Set(res));
}
export function getComputedField(schema) {
  const res = schema
    .filter(d => Array.isArray(d.computeTrigger))
    .map(d =>
      d.computeTrigger
        .filter(x => x.startsWith('onChange:'))
        .map(m => m.replace('onChange:', '')),
    )
    .reduce((p, c) => p.concat(c), []);
  return Array.from(new Set(res));
}
export function getComputed(params, schema, event, options) {
  const shs = schema.filter(
    d =>
      d.computeApi
      && Array.isArray(d.computeTrigger)
      && d.computeTrigger.includes(event),
  );
  const cachedReq = {};
  return serialRun(
    shs.map((sh) => {
      if (!cachedReq[sh.computeApi])
        cachedReq[sh.computeApi] = request(sh.computeApi, formatReq(params, schema), options);

      return cachedReq[sh.computeApi].then(
        res => ({
          [sh.field]: typeof res.data === 'object' ? res.data[sh.field] : res.data,
        }),
      );
    }),
  ).then(res => res.reduce((p, c) => Object.assign(p, c), {}));
}
export function getFiltedSchema(params, schema, event) {
  const sh = schema.find(
    d =>
      d.schemaApi
      && Array.isArray(d.schemaTrigger)
      && d.schemaTrigger.includes(event),
  );
  if (!sh)
    return Promise.resolve(schema);
  return request(sh.schemaApi, formatReq(params, schema), copy(schema), event);
}
export function getRelatedSchemaTrigger(schema) {
  const res = schema
    .filter(d => Array.isArray(d.schemaTrigger))
    .map(d =>
      d.schemaTrigger
        .filter(x => x.startsWith('onChange:'))
        .map(m => m.replace('onChange:', '')),
    )
    .reduce((p, c) => p.concat(c), []);
  return Array.from(new Set(res));
}
export function getFormSchema(schema, options, readonly = false) {
  const form = [...schema]
    .sort((a, b) => a.formOrder - (b.formOrder || 99999))
    .reduce((p, c) => {
      if (
        c.view.includes(FORM_MODE)
        || (readonly && c.view.includes(DETAIL_MODE))
      ) {
        p[c.field] = {
          type: TUI[c.formType] || TUI[c.type] || getComponent(c.type),
          name: c.name,
          inline: !!c.inline,
          visible: 'visible' in c ? typeof c.visible === 'function' ? c.visible : () => !!c.visible : undefined,
          rules: (c.rules || [])
            .filter(r => (!(readonly && r === 'required')))
            .map((r) => {
              if (r === 'required') {
                return {
                  required: true,
                };
              }
              return {
                validator: v => (v === undefined ? true : getRule(r)(v)),
              };
            }),
          opts: {
            ...c.opts,
            disabled: c.opts && 'disabled' in c.opts ? c.opts.disabled : !!readonly, // TODO 所有组件区分开disabled 和 readonly
            readonly: !!readonly,
          },
          options: options[c.field],
          placeholder: c.placeholder,
        };
      }
      return p;
    }, {});
  return form;
}
export function getFilterSchema(schema, options) {
  const form = [...schema]
    .sort((a, b) => a.filterOrder - (b.filterOrder || 99999))
    .reduce((p, c) => {
      if (c.view.includes(FILTER_MODE)) {
        p[c.field] = {
          type: TUI[c.filterType] || TUI[c.type] || getComponent(c.type),
          name: c.name,
          rules: [],
          opts: c.opts,
          options: options[c.field],
          placeholder: c.placeholder,
          quickFilter: c.quickFilter,
        };
      }
      return p;
    }, {});
  return form;
}
function getColumnGroup(columnSchema) {
  const group = {};
  const schema = {};
  let index = 1;
  const transform = (lef, col) => {
    return ({
      ...lef,
      title: lef.name,
      dataIndex: col,
      key: col,
    });
  };
  // TODO schema优化成数组
  for (const col in columnSchema) {
    const g = columnSchema[col].group;
    if (g) {
      if (group[g] && (JSON.stringify(group[g].node.name) === JSON.stringify(g))) {
        const { node } = group[g];
        node.children = node.children || [];
        node.children.push(transform(columnSchema[col], col));
      }
      else {
        const lef = columnSchema[col];
        const node = {
          name: g,
          children: [
            transform(lef, col),
          ],
        };
        group[g] = { field: col, node };
        schema[`$$group${index}`] = node;
        index++;
      }
    }
    else {
      schema[col] = columnSchema[col];
    }
  }
  return schema;
}
function getActionFromApiConfig(api, record) {
  let actions = [];
  Object.keys(api).map((key) => {
    const obj = api[key];
    const fn = obj.action; // 在对象，函数里加action属性
    if (typeof fn === 'function') {
      const act = fn(record);
      if (act) {
        actions.push({
          key,
          order:
            key === 'remove' ? ('order' in obj ? obj.order : -1) : obj.order,
        });
      }
    }
  });
  const len = actions.length;
  actions = actions.map((d, i) => {
    d.order
      = d.order !== undefined && typeof d.order === 'number'
        ? d.order < 0
          ? len + d.order
          : d.order
        : i;
    return d;
  });
  return actions
    .sort((m, n) => (n.order - m.order >= 0 ? -1 : 1))
    .map(d => d.key);
}
function getDefaultColumnRender(schema, options) {
  const { type, optionApi, field, group, listRender, maxLength } = schema;
  let render = null;
  if (listRender)
    return listRender;
  switch (type) {
    case 'Uploader':
    case 'DrawableUploader':
      render = ({ value }) => {
        // TODO 文档图标
        return <img src={value} width={48} height={48} />;
      };
      break;
    case 'Editor':
    case 'Textarea':
    case 'TextArea':
      render = ({ value }) => {
        const str = (`${value}` || '').replace(/<\/?.*?>/g, '');
        const max = 25;
        if (maxLength)
          return str;
        return str.length > max ? `${str.substr(0, max)}...` : str;
      };
      break;
    case 'DatePicker':
      render = ({ value }) => {
        return dayjs(value).isValid() ? dayjs(value).format('YYYY-MM-DD') : value;
      };
      break;
    case 'RangePicker':
      render = ({ value }) => {
        const [s, e] = Array.isArray(value) ? value : [];
        return [
          dayjs(s).isValid() ? dayjs(s).format('YYYY-MM-DD') : '',
          dayjs(s).isValid() ? dayjs(e).format('YYYY-MM-DD') : '',
        ].filter(d => !!d).join('~');
      };
      break;
    default:
      if (optionApi || Array.isArray(schema.options)) {
        render = (props) => {
          const value = group ? props : props.value;// TODO 统一render参数
          const ops = options ? options[field] : [];
          const n = (ops || []).find(d => d.id === value);
          if (n)
            return n.name;
          return value;
        };
      }
  }
  return render;
}
const CURRENT_ACTION = Symbol('current_action');
export function setCurrentAction(api, act) {
  api[CURRENT_ACTION] = act;
}
// TODO 设计Action对像规范现有的api action
export function getCurrentAction(api) {
  const act = api[CURRENT_ACTION];
  return act;
}
export function getModalTitleFromAction(api) {
  const act = getCurrentAction(api);
  const action = api[act];
  if (typeof action === 'object') {
    const { name, title } = action;
    if (title)
      return title;
    if (name)
      return name;
  }
  const modalTitle = {
    add: '新增',
    edit: '编辑',
    detail: '详情',
  }[act];
  return modalTitle || '操作';
}
export function getListSchema(schema, api, auth, setFormSchema, options, filterOptions, showColumnAction, columnActionFixed, AntApp) {
  const { modal: AppModal } = AntApp || {};
  const AppConfirm = AppModal && AppModal.confirm ? AppModal.confirm : Modal.confirm;

  const CrudAuths = useCurrentMenuAuth();

  const AuthCrud = (auth) => {
    if (auth) {
      return true;
      const as = Array.isArray(auth) ? auth : [auth];
      const rs = as.reduce((p, c) => p || CrudAuths.find(it => it.name === c), false);
      if (!rs)
        return false;

      return true;
    }
    return true;
  };

  let columns = schema.reduce((p, c) => {
    if (c.view.includes(LIST_MODE)) {
      p[c.field] = {
        ...c,
        group: c.group,
        name: c.name,
        render: getDefaultColumnRender(c, filterOptions),
        fixed: c.fixed,
        maxLength: c.maxLength,
        sorter: c.sorter,
      };
    }
    return p;
  }, {});
  columns = getColumnGroup(columns);
  showColumnAction && (columns.id = {
    name: '操作',
    ...columnActionFixed ? { fixed: 'right' } : {},
    render: (params) => {
      const { form, modal, record, setFormMode, query } = params;
      let actions = null;
      Object.keys(record).map((key) => {
        const value = record[key];
        const opt = filterOptions[key];
        if (!opt)
          return;
        const p = opt.find(d => d.id === value);
        if (!p || !p.action)
          return;
        if (!actions) {
          actions = [...p.action];
        }
        else {
          actions = p.action.reduce(
            (p, c) => (actions.includes(c) ? [...p, c] : p),
            [],
          );
        }
      });
      // TODO 保证现有平台正常运行的前提下，删除基于hookRequest的action配置
      actions = actions || getActionFromApiConfig(api, record) || [];
      const getDefaultActionName = (act) => {
        if (act === 'edit')
          return '编辑';
        if (act === 'detail')
          return '查看详情';
        return act;
      };
      /** 获取具体配置的值， string | function */
      const renderOperate = ({ key, field, defaultValue }) => {
        return api[key] && api[key][field] ? typeof api[key][field] === 'string' ? api[key][field] : api[key][field](params.record) : defaultValue;
      };
      /** 渲染按钮里的名称 -- card模式下可能会超出 */
      const renderName = (name) => {
        return (
          <Typography.Text
            className="TUI-Card_Footer-BtnNames"
            ellipsis={{ tooltip: name }}
          >
            {name}
          </Typography.Text>
        );
      };
      const renderBtn = (act, mode = 'edit', readonly = false, reorganizeSchema = a => a, icon) => {
        const name = getDefaultActionName(act);
        const showName = typeof api[act] === 'object' ? api[act].name || name : name;
        return (actions.includes(act) && AuthCrud(act in auth ? auth[act] : -9999)
          ? (
            <Button
              style={{ display: 'inline' }}
              type="link"
              key={act}
              // active={actions.includes(act)}
              // auth={act in auth ? auth[act] : -9999}
              icon={icon}
              onClick={() => {
                setCurrentAction(api, act);
                const fn = (red) => {
                  const formSchema = getFormSchema(reorganizeSchema(schema, record), options, readonly);
                  setFormSchema(formSchema);
                  form.setFields(formatRes(red, schema));
                  setFormMode(mode);
                  modal.show();
                };
                if (typeof api.detail === 'string' || (typeof api.detail === 'object' && api.detail.url)) {
                  query(typeof api.detail === 'object' ? api.detail.url : api.detail, record).then((res) => {
                    fn({
                      ...record,
                      ...res.data,
                    });
                  });
                }
                else {
                  fn(record);
                }
              }}
            >
              {renderName(showName)}
            </Button>
            )
          : null
        );
      };
      const renderCustomAction = (key) => {
        const fn = api[key];
        if (typeof fn === 'function') {
          const Com = fn(params, setFormSchema, setCurrentAction);
          return Com;
        }
        if (typeof fn === 'object') {
          let {
            url,
            name,
            confirm,
            onClick,
            mode,
            readonly,
            reorganizeSchema,
            icon,
          } = fn;
          // TODO 引入route组件，根据路由跳转
          if (typeof onClick === 'function') {
            return (actions.includes(key) && AuthCrud(auth[key])
              ? (
                <Button
                  key={key}
                  style={{ display: 'inline' }}
                  type="link"
                // active={}
                // auth={auth[key]}
                  icon={icon || <TUIIcon type="icon-setting" />}
                  onClick={() => {
                    setCurrentAction(api, key);
                    onClick(record, params);
                  }}
                >
                  {renderName(name)}
                </Button>
                ) : null
            );
          }
          if (mode)
            return renderBtn(key, mode, !!readonly, reorganizeSchema, icon || <TUIIcon type="icon-setting" />);

          return (actions.includes(key) && AuthCrud(auth[key])
            ? (
              <Button
                style={{ display: 'inline' }}
                type="link"
                key={key}
                icon={icon || <TUIIcon type="icon-browse" />}
                onClick={() => {
                  setCurrentAction(api, key);
                  const run = (qs = {}) => {
                    return query(url, { id: record.id, ...qs }, record)
                      .then(() => {
                        const { list } = params;
                        list.doFetch(
                          list.query,
                          list.pagination,
                          list.filters,
                          list.sorters,
                        );
                      })
                      .catch();
                  };
                  confirm = confirm || (key === 'publish'
                    ? '是否发布？'
                    : key === 'verify'
                      ? '请选择审核意见！'
                      : key === 'end'
                        ? '是否结束？'
                        : '');
                  if (confirm) {
                    if (key === 'verify' && confirm !== 'object') {
                      confirm = {
                        content: confirm,
                        okText: '通过',
                        cancelText: '不通过',
                        field: 'isPass',
                      };
                    }
                    if (typeof confirm === 'object') {
                      AppConfirm({
                        title: '提示',
                        content: eval(`\`${confirm.content}\``),
                        okText: confirm.okText || '确定',
                        cancelText: confirm.cancelText || '取消',
                        maskClosable: true,
                        onOk() {
                          run({
                            [confirm.field]: 1,
                          });
                        },
                        onCancel(e) {
                          if (e.triggerCancel) {

                          }
                          else {
                            run({
                              [confirm.field]: 0,
                            })
                              .then(() => {
                                e();
                              })
                              .catch();
                          }
                        },
                      });
                    }
                    else {
                      AppConfirm({
                        title: '提示',
                        content: eval(`\`${confirm}\``),
                        okText: '确定',
                        cancelText: '取消',
                        maskClosable: true,
                        onOk() {
                          run();
                        },
                        onCancel() { },
                      });
                    }
                  }
                  else {
                    run();
                  }
                }}
              >
                {renderName(name)}
              </Button>
              )
            : null
          );
        }
      };
      return (Array.isArray(actions) ? actions : [])
        .filter(key => !['add'].includes(key))
        .map((key) => {
          if (key === 'detail') {
            return renderBtn(
              'detail',
              'detail',
              true,
              typeof api.detail === 'object'
                ? api.detail.reorganizeSchema
                : undefined,
              api.detail && api.detail.icon ? api.detail.icon : <TUIIcon type="icon-browse" />,
            );
          }
          if (key === 'edit') {
            return renderBtn(
              'edit',
              'edit',
              false,
              typeof api.edit === 'object'
                ? api.edit.reorganizeSchema
                : undefined,
              api.edit && api.edit.icon ? api.edit.icon : <TUIIcon type="icon-edit-1" />,
            );
          }
          if (key === 'remove') {
            return (actions.includes('remove') && AuthCrud(api.remove ? auth.remove : -9999)
              ? (
                <Button
                  style={{ display: 'inline' }}
                  type="link"
                  key="remove"
                // active={actions.includes('remove')}
                // auth={api.remove ? auth.remove : -9999}
                  icon={api.remove && api.remove.icon ? api.remove.icon : <TUIIcon type="icon-delete" />}
                  onClick={() => {
                    setCurrentAction(api, 'remove');
                    params.list.handleItemById(
                      params.record,
                      api.remove && api.remove.confirm
                        ? typeof api.remove.confirm === 'string' ? eval(`\`${api.remove.confirm}\``) : api.remove.confirm(params.record)
                        : undefined,
                    );
                  }}
                >
                  {renderName(renderOperate({ key: 'remove', field: 'name', defaultValue: '删除' }))}
                  {/* {api.remove && api.remove.name ? api.remove.name : "删除"} */}
                </Button>
                ) : null
            );
          }
          return renderCustomAction(key);
        });
    },
  });
  return columns;
}
// 设置默认选项
export function useFirstOption({
  form,
  options,
  schema,
}) {
  const { fields } = form;
  const res = fields;
  let toucehd = 0;
  for (const field in options) {
    const sch = schema.find(d => d.field === field && (d.view.includes('form') || d.view.includes('detail')));
    const value = fields[field];
    if (value)
      continue;
    if (sch && sch.useFirstOption) {
      const opts = options[field] || [];
      const [first] = opts;
      if (!first)
        continue;
      res[field] = sch.opts && sch.opts.labelInValue ? first : first.id;
      toucehd++;
    }
  }
  // toucehd && form.setFields(res)
  return !!toucehd;
}
// 清除联动字段
export function removeValueOnOptionChanged({
  form,
  options,
}) {
  let toucehd = 0;
  for (const k in options) {
    const v = options[k];
    const t = form.fields[k];
    if (Array.isArray(t)) {
      const x = t.map(p => typeof p === 'object' ? p.id : p);
      const m = v.map(p => p.id).reduce((p, c) => x.includes(c) ? p.concat(c) : p, []);
      if (!m.length) {
        delete form.fields[k];
        toucehd++;
      }
      else {
        // 删除不匹配的项
        form.fields[k] = t.filter(p => m.includes(typeof p === 'object' ? p.id : p));
        if (form.fields[k].length !== t.length)
          toucehd++;
      }
    }
    else {
      const m = v.find(d => typeof t === 'object' ? d.id === t.key || d.id === t.id : d.id === t);
      if (!m) {
        delete form.fields[k];
        toucehd++;
      }
    }
  }
  // TODO 是否需要强刷
  // form.setFields(form.fields);
  return !!toucehd;
}
