import { Col, InputNumber, Row, Slider } from 'antd';

function InputSlider({
  value,
  onChange,
  opts = {},
}) {
  const { min = 1, max = 20, colSpan1 = 12, colSpan2 = 4 } = opts;

  const _onChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <Row>
      <Col span={colSpan1}>
        <Slider
          min={min}
          max={max}
          onChange={_onChange}
          value={typeof value === 'number' ? value : 0}
        />
      </Col>
      <Col span={colSpan2}>
        <InputNumber
          min={min}
          max={max}
          style={{
            margin: '0 16px',
          }}
          value={value}
          onChange={_onChange}
        />
      </Col>
    </Row>
  );
}

export default InputSlider;
