import React from 'react';
import { Tooltip } from 'antd';
import type { Argument } from 'classnames';
import ClassNames from 'classnames';

const TUIIconPark: React.FC<{
  title?: string;
  className?: Argument;
  /**
   * 等同 name
   */
  type: string;
  /**
   * 等同 type
   */
  name?: string;
  [key: string]: any;
}> = ({ title, className, type, name, ...rest }) => {
  return (
    <Tooltip title={title}>
      <iconpark-icon class={ClassNames('TUI-IconPark', className)} name={name || type} {...rest} />
    </Tooltip>
  );
};
export default TUIIconPark;
