import { Checkbox } from 'antd';

export default function TUICheckbox({ id, children, value, onChange, disabled = false, opts = {} }) {
  return (
    <Checkbox
      id={id}
      checked={value}
      onChange={e => onChange(e.target.checked)}
      disabled={disabled}
      {...opts}
    >
      {children}
    </Checkbox>
  );
};
