import { message } from '../../src/antd';

// 定义接口和类型
interface Config extends RequestInit {
  baseURL?: string;
  /**
   * 仅准备请求（fetch 参数），不执行
   */
  manual?: boolean;
  onError?: (data: any) => void;
  onUnauthorized?: (data: any) => void;
}

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

// 定义默认配置
const defaultConfig: Config = {
  baseURL: '',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem('token') || '',
  },
  manual: false,
  onError(msg: string) {
    message.error(msg);
  },
  onUnauthorized() {
    location.href = `/login?prePath=${encodeURIComponent(location.href)}`;
  },
};

// interface Result {
//   data: any;
//   message: string;
//   pagination: {
//     currentPage: number;
//     listRows: number;
//     totalRows: number;
//   };
//   status: number;
// }

type Result<T> = Promise<T> | (() => Promise<Response>);

function formatPagination(res: any) {
  // 判断返回结果中是否存在分页信息，并且分页信息是否为对象类型
  if (res.pagination && typeof res.pagination === 'object') {
    // 对分页信息进行格式化
    res.pagination = {
      ...res.pagination,
      current: res.pagination.currentPage,
      pageSize: res.pagination.listRows,
      total: res.pagination.totalRows,
    };
  }
  // 返回格式化后的结果
  return res;
}

export class HttpClient {
  private config: Config;

  constructor(config?: Config) {
    this.config = { ...defaultConfig, ...config };
  }

  request<T>(
    method: HttpMethod,
    url: string,
    data?: unknown,
    customConfig?: Config,
  ): Result<T> {
    // 合并配置
    const config: Config = {
      ...this.config,
      ...customConfig,
      method,
      headers: { ...this.config.headers, ...customConfig?.headers },
    };

    // 拼接完整url
    let fullUrl = config.baseURL + url;

    // 处理params/data
    if (data) {
      if (method === 'GET' || method === 'DELETE')
        fullUrl += `?${new URLSearchParams(data as Record<string, string>).toString()}`;
      else
        config.body = JSON.stringify(data);
    }

    // 仅准备请求
    if (config.manual === true)
      return () => fetch(fullUrl, config);

    // 执行请求
    return new Promise<T>((resolve, reject) => {
      fetch(fullUrl, config).then((response) => {
        if (!response.ok) {
          // 404 500 等
          config.onError?.(`${response.status}:${response.statusText}`);
          reject(response);
          return;
        }

        response.json().then((result) => {
          if (result.status === 1003) {
            // 未登录 登录失效
            config.onUnauthorized?.(result);
            reject(result);
            return;
          }
          if (result.status !== 0) {
            // 接口错误
            config.onError?.(result.message);
            reject (result);
            return;
          }

          resolve(formatPagination(result));
        }).catch(reject);
      }).catch(reject);
    });
  }

  get<T>(url: string, params?: Record<string, string | number>, config?: Config): Result<T>;
  get<T>(args: [string, Record<string, string | number>?, Config?]): Result<T>;
  get<T>(
    urlOrArgs: string | [string, Record<string, string | number>?, Config?],
    params?: Record<string, string | number>,
    config?: Config,
  ): Result<T> {
    if (Array.isArray(urlOrArgs))
      return this.request<T>('GET', ...urlOrArgs);

    return this.request<T>('GET', urlOrArgs, params, config);
  }

  post<T>(url: string, data?: unknown, config?: Config): Result<T>;
  post<T>(args: [string, unknown?, Config?]): Result<T>;
  post<T>(
    urlOrArgs: string | [string, unknown?, Config?],
    data?: unknown,
    config?: Config,
  ): Result<T> {
    if (Array.isArray(urlOrArgs))
      return this.request<T>('POST', ...urlOrArgs);

    return this.request<T>('POST', urlOrArgs, data, config);
  }

  put<T>(url: string, data?: unknown, config?: Config): Result<T>;
  put<T>(args: [string, unknown?, Config?]): Result<T>;
  put<T>(
    urlOrArgs: string | [string, unknown?, Config?],
    data?: unknown,
    config?: Config,
  ): Result<T> {
    if (Array.isArray(urlOrArgs))
      return this.request<T>('PUT', ...urlOrArgs);

    return this.request<T>('PUT', urlOrArgs, data, config);
  }

  delete<T>(url: string, params?: Record<string, string | number>, config?: Config): Result<T>;
  delete<T>(args: [string, Record<string, string | number>?, Config?]): Result<T>;
  delete<T>(
    urlOrArgs: string | [string, Record<string, string | number>?, Config?],
    params?: Record<string, string | number>,
    config?: Config,
  ): Result<T> {
    if (Array.isArray(urlOrArgs))
      return this.request<T>('DELETE', ...urlOrArgs);

    return this.request<T>('DELETE', urlOrArgs, params, config);
  }
}

export const httpClient = new HttpClient();
