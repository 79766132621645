import { useState } from 'react';

export default function usePaginationHooks({ defaultPageSize }) {
  const [pagenation, setPagenation] = useState({
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
  });

  const changePagenation = (record = {}) => {
    setPagenation({
      ...pagenation,
      ...record,
    });
  };

  const onChange = (page, pageSize) => {
    changePagenation({
      current: page,
      pageSize,
    });
  };

  const onShowSizeChange = (current, size) => {
  };

  return {
    ...pagenation,
    onChange,
    onShowSizeChange,
    changePagenation,
  };
};
