import { Radio } from 'antd';

const AntRadioGroup = Radio.Group;
export default function RadioButton({
  value,
  options = [],
  onChange,
  className,
  opts = {},
}) {
  return (
    <AntRadioGroup
      onChange={e => onChange(e.target.value)}
      value={value}
      className={['TUI-RadioButton', className].filter(d => !!d).join(' ')}
      {...opts}
    >
      {options.map(item => (
        <Radio.Button value={item.id} key={item.id} disabled={item.disabled}>
          {item.name}
        </Radio.Button>
      ))}
    </AntRadioGroup>
  );
}
