/* eslint-disable react-refresh/only-export-components */
import type { Dispatch } from 'react';
import { createContext, useContext, useEffect, useReducer } from 'react';
import type { RouteObject } from 'react-router-dom';
// import type { MenuItemType } from 'antd/es/menu/interface';
import type { MenuProps } from 'antd';
import { merge } from 'lodash-es';
import { mergeRoutes } from '../../utils/routes';
import TUI404 from '../../components/TUI404';
import { type DispatchType, getReducer } from '../types';
import { useThemeContext } from '../ThemeContext';

/**
 * 路由/菜单公用类型
 */
export interface RouteBaseType<T extends RouteBaseType<T>> {
  /**
   * 路由名称, 可用于菜单/面包屑
   */
  label?: string;
  /**
   * 路由图标，可用于菜单/面包屑
   */
  icon?: string;
  /**
   * 路径
   */
  path: string;
  /**
   * 完整路径，根到当前层级
   */
  fullPath?: string;
  /**
   * 子路由
   */
  children?: T[];
  menuAuth?: [];
};

/**
 * 路由类型
 */
export type RouteType = RouteBaseType<RouteType> & RouteObject;

type MenuItem = Required<MenuProps>['items'][number];

/**
 * 菜单类型
 */
export type MenuType = RouteBaseType<MenuType> & MenuItem;

/**
 * 布局上下文类型
 */
export interface LayoutContextType {
  /**
   * 菜单是否收缩
   */
  menuCollapsed: boolean;
  /**
   * 有权限的菜单
   */
  menus: MenuType[];
  /**
   * 当前菜单
   */
  activeMenu?: MenuType;
  /**
   * 所有路由 nest routes
   */
  routes: RouteType[];
  /**
   * 当前路由
   */
  activeRoute?: RouteType;
  /**
   * 菜单自定义事件
   */
  clickMenuBack?: Function | undefined;
  /**
   * 路由缓存
   */
  useAliveController?: Function | undefined;
};

/**
 * 布局分发上下文类型
 */
type LayoutDispatchType = DispatchType<LayoutContextType>;

/**
 * 布局初始化数据
 */
const initialState: LayoutContextType = {
  menuCollapsed: false,
  menus: [],
  routes: [{ path: '*', element: <TUI404 /> }],
  clickMenuBack: undefined,
  useAliveController: undefined,
};

/**
 * 布局上下文
 */
export const LayoutContext = createContext(initialState);

/**
 *  获取布局上下文
 * @returns {LayoutContextType} 布局上下文
 */
export function useLayoutContext() {
  return useContext(LayoutContext);
}

/**
 * 布局分发上下文
 */
export const LayoutDispatch = createContext<Dispatch<LayoutDispatchType>>(() => {});

/**
 * 获取布局分发上下文
 * @returns {Dispatch<LayoutDispatchType>} 布局分发上下文
 */
export function useLayoutDispatch() {
  return useContext(LayoutDispatch);
}

interface LayoutContextProviderProps {
  defaultStatus?: LayoutContextType;
  children: React.ReactNode | Function; // TODO: 写明具体类型;
};

/**
 * 布局上下文提供者
 * @param param0 子组件
 * @returns {React.ReactNode} 布局上下文提供者
 */
export function LayoutContextProvider({ defaultStatus, children }: LayoutContextProviderProps) {
  const { layoutMode } = useThemeContext();
  const _initialState = merge({}, initialState, defaultStatus);
  // 合并默认路由
  _initialState.routes = mergeRoutes(initialState.routes, defaultStatus?.routes || []);
  const [data, dispatch] = useReducer(
    getReducer<LayoutContextType, LayoutDispatchType>(initialState),
    _initialState,
  );

  useEffect(() => {
    dispatch({
      type: 'setData',
      payload: {
        menuCollapsed: !['default', 'ExpandMenu'].includes(layoutMode),
      },
    });
  }, [layoutMode]);

  return (
    <LayoutContext.Provider value={data}>
      <LayoutDispatch.Provider value={dispatch}>
        {typeof children === 'function' ? children(data, dispatch) : children}
      </LayoutDispatch.Provider>
    </LayoutContext.Provider>
  );
}
