import { Tree as AntTree } from 'antd';

export default function TreeSelect({ id, value, onChange, options = [], opts = {} }) {
  const onSelect = (selectedKeys: any) => {
    onChange(selectedKeys);
  };

  return (
    <div id={id}>
      <AntTree
        checkable={false}
        multiple={false}
        selectable
        blockNode
        checkedKeys={value}
        onSelect={onSelect}
        treeData={options}
        {...opts}
      />
    </div>
  );
};
