import { useRef } from 'react';
import { Carousel } from 'antd';
import './index.less';

export default function TUICarousel({ children, dots = false, style, ...rest }) {
  const ref = useRef();
  return (
    <div className="TUI-Carousel" style={style}>
      <div className="TUI-Carousel--body">
        <Carousel ref={ref} dots={dots} {...rest}>
          {children}
        </Carousel>
      </div>
      {!dots
        ? (
          <div
            className="TUI-Carousel--prev"
            onClick={() => {
              ref.current.prev();
            }}
          >
          </div>
          )
        : null}
      {!dots
        ? (
          <div
            className="TUI-Carousel--next"
            onClick={() => {
              ref.current.next();
            }}
          >
          </div>
          )
        : null}
    </div>
  );
};
