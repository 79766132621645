import { AutoComplete as TAutoComplete } from 'antd';
import { transData } from '../../common/index';

type voidFunc = () => void;
type onChange = (value: any) => void;
interface option {
  id: number | string;
  name: string;
  value?: number | string;
  label?: string;
}
interface AutoCompleteOpts {
  dropdownMenuStyle?: React.CSSProperties;
  autoFocus?: boolean;
  backfill?: boolean;
  optionLabelProp?: string;
}
export interface AutoCompleteProps {
  placeholder?: string;
  value?: string;
  field: string;
  options: option[];
  onChange: voidFunc;
  opts: AutoCompleteOpts;
}
export default function AutoComplete({
  id,
  value,
  field,
  options = [],
  onChange,
  placeholder,
  opts = {},
}: AutoCompleteProps) {
  return (
    <TAutoComplete
      id={id}
      name={field}
      options={transData(options)}
      value={value || undefined}
      onChange={onChange}
      placeholder={placeholder || '请输入'}
      {...opts}
    />
  );
}
