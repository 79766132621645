import { useLayoutEffect, useRef, useState } from 'react';

export default function NullWhenNoChildren({ children, wrap }) {
  const ref = useRef();
  const childCountRef = useRef({});
  const [isChildrenVisible, showChildren] = useState(false);
  useLayoutEffect(() => {
    if (!ref.current)
      return;
    childCountRef.current.count = ref.current.childElementCount;
    childCountRef.current.display = ref.current.style.display;
    childCountRef.current.first = true;
    showChildren(true);
  }, []);
  useLayoutEffect(() => {
    if (!ref.current)
      return;
    if (childCountRef.current.first) {
      childCountRef.current.first = false;
      return;
    }
    if (ref.current.childElementCount - childCountRef.current.count <= 0)
      ref.current.style.display = 'none';
    else
      ref.current.style.display = childCountRef.current.display;
  });

  return wrap
    ? (
      <wrap.type {...wrap.props} ref={ref}>
        {wrap.props.children}
        {isChildrenVisible ? children : null}
      </wrap.type>
      )
    : (
        children
      );
};
