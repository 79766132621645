import { ValidateRules } from '../../index';

function isEmpty(v) {
  return v === undefined || v !== v || v === null;
}
export const rules = {
  'naturalNumber': v =>
    /^\+?[1-9]\d*$|^0$/.test(v) ? true : '请输入非负整数！',
  'number': v => (/^[+\-]?\d+(\.\d+)?$/.test(v) ? true : '请输入数字！'),
  'interger': v => (/^[+\-]?[1-9]\d*$|^0$/.test(v) ? true : '请输入整数！'),
  'maxLength:*': n => v =>
    isEmpty(v) || `${v}`.length <= n ? true : `最大长度${n}！`,
  'minLength:*': n => v =>
    isEmpty(v) || `${v}`.length >= n ? true : `最小长度${n}！`,
  'mileage': v => (/^[A-Z]+\d+\+\d{3}$/i.test(v) ? true : '桩号格式不对！'),
  'longitude': (v) => {
    const len = getRule('maxLength:10');
    const num = getRule('number');
    if (num(v) !== true)
      return num(v);
    if (len(v) !== true)
      return len(v);
    if (+v <= 180 && +v >= -180)
      return true;
    return '经度范围-180~180';
  },
  'latitude': (v) => {
    if (+v <= 90 && +v >= -90)
      return true;
    return '纬度范围-90~90';
  },
  'RangePicker': (v) => {
    const [start, end] = v || [];
    if (!start || !end)
      return '请选择开始时间和结束时间！';
    return true;
  },
  'maxPrecision:*': n => v =>
    isEmpty(v) || v === '' || /^\d+$/.test(v) || new RegExp(`\\.\\d{1,${n}}$`).test(v)
      ? true
      : `超出最大精度${n}位！`,
  'email': v => ValidateRules.ValidateEmail(v),
  'phoneNumber': v => ValidateRules.ValidatePhoneNum(v),
};
export function addRule(name, fn) {
  rules[name] = fn;
}
export function getRule(name) {
  const n = name.split(':');
  const [f, t] = n;
  if (t !== undefined) {
    const fn = rules[`${f}:*`];
    return fn(t);
  }
  else {
    const fn = rules[f];
    return fn;
  }
}
