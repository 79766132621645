import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import type { UniqueIdentifier } from '@dnd-kit/core';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';

import { VirtualList, useThemeHook } from '../../index';
import { initStoreData } from '../DragLeft/common';
import { dropAnimationConfig } from './utilities';
import { Item, Wrapper } from './components';
import SortableItem from './SortableItem';
import type { Props, virtualListStyleProps } from './types';

import styles from './Sortable.module.less';

const defaultVirtualListStyle = {
  width: 258,
  height: '100%',
  itemSize: 48,
  overscanCount: 16,
};
export function Sortable({
  isDarag = false,
  storeData = initStoreData,
  icon = <i className="iconfont" style={{ marginRight: 4 }}>&#xe63d;</i>,
  onClickLeaf = () => {},
  onDragAfter,
  extraContent,
  isDisabled = () => false,
  renderItem,
  reorderItems = arrayMove,
  animateLayoutChanges,
  adjustScale = false,
  dropAnimation = dropAnimationConfig,
  getItemStyles = () => ({}),
  getNewIndex,
  handle = false,
  strategy = verticalListSortingStrategy,
  style = {},
  nameWidth,
  useDragOverlay = true,
  wrapperStyle = () => ({}),
  virtualListStyle: virtualListStyleTemp,
}: Props) {
  const { token } = useThemeHook();

  const { initData = [], treeData = [], selectItem = {} } = storeData;
  const virtualListStyle: virtualListStyleProps = { ...defaultVirtualListStyle, ...virtualListStyleTemp };

  const [data, setData] = useState(initData);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const getIndex = (id: UniqueIdentifier) => data.findIndex(it => it.id === id);
  const activeIndex = activeId ? getIndex(activeId) : -1;

  useEffect(() => {
    setData(treeData);
  }, [treeData]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      scrollBehavior: 'Cypress' in window ? 'auto' : undefined,
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={({ active }) => {
        if (!active)
          return;

        setActiveId(active.id);
      }}
      onDragEnd={({ over }) => {
        setActiveId(null);

        if (over) {
          const overIndex = getIndex(over.id);
          if (activeIndex !== overIndex) {
            setData(datas => reorderItems(datas, activeIndex, overIndex));
            const resultData = reorderItems(data, activeIndex, overIndex);
            onDragAfter && onDragAfter({
              resultData,
              activeIndex,
              overIndex,
            });
          }
        }
      }}
      onDragCancel={() => setActiveId(null)}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
    >
      <Wrapper center style={style}>
        <SortableContext items={data} strategy={strategy}>
          <VirtualList
            width={virtualListStyle.width}
            height={virtualListStyle.height}
            itemSize={virtualListStyle.itemSize}
            overscanCount={virtualListStyle.overscanCount}
            className={styles.VirtualList}
            itemCount={data.length}
            {...virtualListStyle}
            stickyIndices={activeId ? [data.findIndex(it => it.id === activeId)] : undefined}
            renderItem={({ index, style }) => {
              const item = data[index];
              return (
                <div onClick={() => onClickLeaf(item)} key={`${item.id}`}>
                  <SortableItem
                    isDarag={isDarag}
                    key={`${item.id}`}
                    id={item.id}
                    name={item.name}
                    item={item}
                    handle={handle}
                    index={index}
                    icon={icon}
                    selectItem={selectItem}
                    nameWidth={nameWidth}
                    extraContent={extraContent}
                    style={getItemStyles}
                    // wrapperStyle={wrapperStyle} // 会抖动？
                    wrapperStyle={() => ({
                      ...style,
                      padding: 4,
                    })}
                    disabled={isDisabled(item.id)}
                    renderItem={renderItem}
                    animateLayoutChanges={animateLayoutChanges}
                    useDragOverlay={useDragOverlay}
                    getNewIndex={getNewIndex}
                  />
                </div>
              );
            }}
          />
        </SortableContext>
      </Wrapper>
      {/* 拖拽起来的显示 */}
      {useDragOverlay
        ? createPortal(
          <DragOverlay
            adjustScale={adjustScale}
            dropAnimation={dropAnimation}
          >
            {activeId
              ? (
                <Item
                  color={token.colorPrimary}
                  colorBg={token.colorPrimaryBg}
                  borderRadios={token.borderRadius}
                  isDarag={isDarag}
                  value={data[activeIndex].name}
                  item={data[activeIndex]}
                  index={activeIndex}
                  icon={icon}
                  nameWidth={nameWidth}
                  extraContent={extraContent}
                  handle={handle}
                  renderItem={renderItem}
                  wrapperStyle={wrapperStyle({
                    active: { id: activeId },
                    index: activeIndex,
                    isDragging: true,
                    id: data[activeIndex].id,
                  })}
                  style={getItemStyles({
                    id: data[activeIndex].id,
                    index: activeIndex,
                    isSorting: activeId !== null,
                    isDragging: true,
                    overIndex: -1,
                    isDragOverlay: true,
                  })}
                  dragOverlay
                />
                )
              : null}
          </DragOverlay>,
          document.body,
        )
        : null}
    </DndContext>
  );
}
