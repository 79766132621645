import Upload from '../Upload';
import { message } from '../../index';

export default function UploadImg({
  value,
  onChange,
  opts = {
    fileSize: 512,
  },
}) {
  const uploadButton = (
    <div>
      <span>+</span>
      <div className="TUI-upload-text">上传</div>
    </div>
  );
  const beforeUpload = (file) => {
    const { fileSize } = opts;
    const isLt = file.size < fileSize * 1024;
    if (!isLt)
      message.error(`Banner图片不可大于${fileSize}KB，请重新选择`);

    return isLt;
  };

  const handleImgChange = (info, onChange) => {
    if (info.file.status === 'uploading')
      return;

    if (info.file.status === 'done')
      onChange(info.file.response.data.url);
      // getBase64(info.file.originFileObj, imageUrl => onChange(imageUrl));
  };

  return (
    <Upload
      name="file"
      listType="picture-card"
      className="TUI-UploadImg"
      showUploadList={false}
      accept="image/jpg,image/jpeg,image/png"
      beforeUpload={beforeUpload}
      onChange={info => handleImgChange(info, onChange)}
      {...opts}
    >
      {value
        ? (
          <img src={value} width="100" height="100" alt="avatar" />
          )
        : (
            uploadButton
          )}
    </Upload>
  );
};
