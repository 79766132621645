/** 获得localStorage里的用户权限 */
export default function getLocalUserPermissions() {
  // TODO 优化用户权限逻辑
  const s = localStorage.getItem('userInfo');
  if (s) {
    try {
      return JSON.parse(s).permissions;
    }
    catch (e) {
      return [];
    }
  }
  else {
    return [];
  }
}
