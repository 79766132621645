import React, { useEffect, useState } from 'react';
import ClassNames from 'classnames';
import { ConfigProvider, Menu } from 'antd';
import { useMatches, useNavigate } from 'react-router-dom';
import { TUIIcon, useThemeHook } from '../../index';
import { useThemeContext } from '../../context/ThemeContext';
import { useLayoutContext, useLayoutDispatch } from '../../context/LayoutContext';
import { getMatchRoutes, getTwoLevelMenus } from '../../utils/routes';
import { getMenuToken } from './getThemeStyles';
import './Menus.less';

interface MenusProps {
  top?: undefined | React.ReactNode;
}

export default function Menus({
  top,
}: MenusProps) {
  const { layoutMode, themeType, sysMode } = useThemeContext();
  const { menuCollapsed, menus, routes, useAliveController = () => ({}), clickMenuBack } = useLayoutContext();
  const dispatch = useLayoutDispatch();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const navigate = useNavigate();
  const matches = useMatches();
  const { token } = useThemeHook();

  const { clear } = useAliveController() || {};

  /** menu的主题token */
  const MenuToken = getMenuToken({ token, sysMode, layoutMode, themeType, menuCollapsed });

  const handleMenuClick = (e) => {
    // TODO: 自定义单击事件
    // 清除所有缓存
    clear && clear();
    clickMenuBack && clickMenuBack(e.key);
    navigate(e.key);
  };

  const setMenuCollapse = (val) => {
    dispatch?.({ type: 'setData', payload: { menuCollapsed: val } });
  };

  useEffect(() => {
    const matchRoutes = getMatchRoutes(routes, matches);
    const openKeys = matchRoutes.slice(1, 2).map(c => c.fullPath!);
    setOpenKeys(openKeys);
    const selectedKeys = matchRoutes.slice(1, 3).map(c => c.fullPath!);
    setSelectedKeys(selectedKeys);
  }, [matches, routes]);

  return (
    <div className="Sider">
      {top || null}
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              // 这里是你的组件 token
              ...MenuToken,
            },
          },
        }}
      >
        <Menu
          theme={['shrinkMenu', 'ExpandMenu'].includes(layoutMode) ? 'dark' : 'light'}
          mode={['top'].includes(layoutMode)
            ? 'horizontal'
            : ['shrinkMenu', 'ExpandMenu'].includes(layoutMode) && menuCollapsed
                ? 'vertical'
                : 'inline'}
          className={ClassNames(`SideMenu`)}
          items={getTwoLevelMenus(menus, sysMode)}
          onClick={handleMenuClick}
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onSelect={({ selectedKeys }) => {
            setSelectedKeys(selectedKeys);
          }}
          onOpenChange={setOpenKeys}
          inlineCollapsed={menuCollapsed}
        />
      </ConfigProvider>

      {['default', 'ExpandMenu', 'shrinkMenu'].includes(layoutMode) && (
        <TUIIcon
          type="icon-menu-unfold"
          className={ClassNames('TUI-MenuTrigger', ['default'].includes(layoutMode) && sysMode === 'light' ? 'light' : 'default')}
          style={{
            transform: `rotate(${menuCollapsed ? 180 : 0}deg)`,
          }}
          onClick={() => {
            setMenuCollapse(!menuCollapsed);
          }}
        />
      )}
    </div>
  );
}
