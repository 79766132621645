import ClassNames from 'classnames';
import { useThemeHook } from '../../index';
import Head from './Head';
import SystemTitle from './SystemTitle';
import LoginFrom from './LoginFrom';
import ResetPassFrom from './ResetPassFrom';
import Footer from './Footer';
import './index.less';

interface LoginPageProps {
  loginTitleColor?: 'light' | 'dark';
  themeMode?: 'light' | 'dark';
  page: 'login' | 'resetPass';
  loginType?: 'rightModal' | 'rightDrawer' | 'Center';
  loginBGMask?: 'default' | 'whiteMask' | 'darkMask';
  navigate?: Function;
  sysTitleType: 'img' | 'string';
  sysTitle: string | React.ReactNode;
  sysSubTitle?: string;
  LoginBckType: 'img' | 'video';
  LoginBck: string | any;
  LoginLogo: string | any;
  TCAPTCHE_APPID?: number | string;
  footer?: string | React.ReactNode;
  loginCallback?: Function;
  NotShowSliderValidate?: boolean;
  classNames?: string;
  style?: React.CSSProperties;
  backgroundStyle?: React.CSSProperties;
  formWidth?: number;
  codeLogin?: {
    workwx?: boolean;
    wechat?: boolean;
    dingtalk?: boolean;
  } | boolean | null;
  LoginService?: Function;
  ResetService?: Function;
  SliderValidateService?: Function;
  otherLoginPara?: object;
  otherResetPara?: object;
}

export default function LoginPage({
  page, // page 登录/重置密码 对应 'login'/'resetPass'
  loginTitleColor = 'light',
  themeMode = 'light', // 默认/暗黑模式
  loginType = 'rightModal', // 显示的三种布局模式
  loginBGMask = 'default', // 默认 | 白蒙版 | 黑蒙版
  sysTitleType = 'string', // 标题的2种模式，图片/文字 对应传 sysTitle
  sysTitle,
  sysSubTitle,
  LoginBckType = 'img', // 背景图 图片/视频 对应传 LoginBck
  LoginBck, // 背景图
  LoginLogo, // 左上角logo
  TCAPTCHE_APPID = '2001749235', // 滑块验证码
  footer, // 自定义版权等信息
  loginCallback, // 登录完成后的操作
  NotShowSliderValidate = false, // 是否显示滑块 false - 显示
  classNames = '',
  backgroundStyle = {}, // 弹窗的背景样式
  style = {},
  formWidth = 430,
  LoginService,
  ResetService,
  SliderValidateService,
  codeLogin,
  otherLoginPara = {},
  otherResetPara = {},
}: LoginPageProps) {
  const { token } = useThemeHook();

  const commStyle = LoginBckType === 'img' && LoginBck
    ? {
        backgroundImage: `url(${LoginBck})`,
      }
    : {};

  const themeStyle = {
    '--colorPrimary': token.colorPrimary,
    '--diff-border-radius': `${token.borderRadius - 6}px`,
    '--form-with': `${formWidth}px`,
    // '--text-color': loginBGMask === 'default' ? '' : loginBGMask === 'whiteMask' ? '#000' : '#fff',
    '--text-secend-color': loginBGMask === 'default' ? '' : loginBGMask === 'whiteMask' ? 'rgb(100, 101, 102)' : 'rgb(200, 201, 204)',
  };

  return (
    <div key={page} className={ClassNames(`TUI-LoginLayout TUI-LoginLayout_${loginType} ${classNames}`)} style={{ ...commStyle, ...themeStyle, ...style }}>
      {LoginBckType === 'video' && <video className="TUI-BackVideo" src={LoginBck} autoPlay loop muted />}
      <div className="LoginPage">
        <Head LoginLogo={LoginLogo} />
        <div className={ClassNames(`TUI-TitleWarp TUI-TitleWarp_${loginType} TUI-TitleWarp_${loginTitleColor}`)}>
          <SystemTitle
            sysTitleType={sysTitleType}
            sysTitle={sysTitle}
            sysSubTitle={sysSubTitle}
          />
        </div>
        <div className={ClassNames(`TUI-FormWarp TUI-FormWarp_${loginType} TUI-FormWarp_${themeMode} TUI-FormWarp_${loginBGMask}`)} style={{ ...backgroundStyle }}>
          {page === 'login' && (
            <LoginFrom
              loginCallback={loginCallback}
              TCAPTCHE_APPID={TCAPTCHE_APPID}
              NotShowSliderValidate={NotShowSliderValidate}
              colorPrimary={token.colorPrimary}
              LoginService={LoginService}
              SliderValidateService={SliderValidateService}
              otherLoginPara={otherLoginPara}
              codeLogin={codeLogin}
            />
          )}
          {page === 'resetPass' && (
            <ResetPassFrom
              ResetService={ResetService}
              otherResetPara={otherResetPara}
            />
          )}
        </div>
        <Footer footer={footer} />
      </div>
    </div>
  );
}
