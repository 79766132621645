import { Children, useEffect, useState } from 'react';
import ClassNames from 'classnames';
import { Flex } from 'antd';
import { useThemeContext } from '../../index';
import TableDefault from '../Table/index';
import TableCard from '../TableCard';
import FilterDefault from '../Filter';
import AdvancedFilter from '../AdvancedFilter';
import ModalDefault from '../Modal';
import DrawerDefault from '../Drawer';
import FormDefault from '../Form';
import ColumnSetting from '../ColumnSetting';
import ColumnDensity from '../ColumnDensity';
// import isEqual from 'lodash.isequal';
import RadioButton from '../RadioButton';
import TUIIcon from '../TUIIcon';
import AddModal from './AddModal';
import './index.less';

const slots = [
  'CRUD-FILTER-TOP',
  'CRUD-FILTER-BOTTOM',
  'CRUD-TABLE-TOP',
  'CRUD-TABLE-BOTTOM',
  'CRUD-FORM-TOP',
  'CRUD-FORM-BOTTOM',
  'CRUD-FILTER-INLINE',
  'CRUD-BATTCH', // 批量操作slot,在list.selectedKeys不为空时展示
];

export default function Crud(props) {
  const {
    listProps = {},
    filterProps = {},
    modalProps = {},
    formProps = {},

    filter,
    modal,
    form,
    list,

    FilterSchema,
    FormSchema,
    ListSchema,

    filterMode,
    filterResetCallBack,
    className,
    style,
    notShowColumnsDensity = false,
    notShowColumnsSetting = false,
    notShowListModeSwitch = false,
    notShowIcon = false,
    turnIcon = false, // 是否显示轮换icon
    defaultListViewMode = 'list',
    defaultModalMode,
    ...restProps
  } = props;

  const { size, modalMode: modalModeTheme = 'drawer' } = useThemeContext();
  const modalMode = defaultModalMode || modalModeTheme;
  const { pagination } = listProps || {};

  const [state, setState] = useState({
    defaultColumns: [],
    columns: [],
    listViewMode: defaultListViewMode,
    size,
  });

  const { defaultColumns, columns, listViewMode } = state;
  const Table = listViewMode === 'list' ? TableDefault : listViewMode === 'card' ? TableCard : (props.Table || TableDefault);
  const Filter = props.Filter ? props.Filter : filterMode ? FilterDefault : AdvancedFilter;
  const Modal = props.Modal || ModalDefault;
  const Drawer = props.Drawer || DrawerDefault;
  const Form = props.Form || FormDefault;

  const onChangeSize = (e) => {
    setState({
      ...state,
      size: e,
    });
  };

  const getSlot = (key) => {
    return Children.map(props.children, (child, i) => {
      return child && child.key === key ? child : null;
    });
  };

  const getOtherSlot = () => {
    return Children.map(props.children, (child, i) => {
      return child && slots.includes(child.key) ? null : child;
    });
  };

  const setColumns = (e) => {
    setState({
      ...state,
      columns: e,
    });
  };

  const hanleViewModeChange = (e) => {
    setState({
      ...state,
      listViewMode: e,
    });
  };

  useEffect(() => {
    onChangeSize(size);
  }, [size]);

  useEffect(() => {
    if (props.list) {
      const t = props.list.createColumn(props);
      setState({
        ...state,
        defaultColumns: t,
        ...(notShowColumnsSetting ? { columns: t } : {}),
      });
    }
  }, [props]);

  return (
    <div className={ClassNames('TUI-Crud', className)} style={style}>
      {getSlot('CRUD-FILTER-TOP')}
      {FilterSchema
        ? (
          <Filter
            schema={FilterSchema}
            defaultValue={filter.defaultValue}
            onSearch={query =>
              list.doFetch(
                filterMode ? filter.fields : query,
                {
                  ...list.pagination,
                  current: 1,
                },
                list.filters,
                list.sorters,
            )}
            onReset={() =>
              filter
                .reset(filter.defaultValue)
                .then((filter) => {
                  filterResetCallBack && (typeof filterResetCallBack === 'function') && filterResetCallBack();
                  list.doFetch(
                    filter,
                    {
                      ...list.pagination,
                      current: 1,
                    },
                  );
                })}
            onFieldChange={filter.setField}
            onChange={filter.setFields}
            fields={filter.fields}
            errors={filter.errors}
            validate={filter.validate}
            setTempValue={filter.setTempValue}
            ref={filter.ref}
            loading={list.loading}
            {...filterProps}
          >
            <Flex gap={16} wrap="wrap" align="center" className="TUI-AdvancedFilter-AddOn">
              {getSlot('CRUD-FILTER-INLINE')}
            </Flex>
          </Filter>
          )
        : null}

      <Flex gap={16} justify="space-between" align="flex-start" className="TUI-AdvancedFilter-Bottom">
        <Flex gap={16} wrap="wrap" align="center" className="TUI-AdvancedFilter-Btns">
          {getSlot('CRUD-FILTER-BOTTOM')}
          { list.selectedKeys?.length > 0 ? getSlot('CRUD-BATTCH') : null}
          {/* 没有值时，占位 */}
          <div></div>
        </Flex>
        <Flex gap={16} align="end">
          {
            !notShowColumnsDensity
              ? <ColumnDensity size={state.size} onChangeSize={onChangeSize} />
              : null
          }
          {!notShowColumnsSetting
            ? (
              <ColumnSetting
                defaultColumns={defaultColumns}
                columns={columns}
                setColumns={(e) => {
                  setState({
                    ...state,
                    columns: e,
                  });
                }}
              />
              )
            : null}
          {!notShowListModeSwitch
            ? (
              <RadioButton
                className="TUI-Crud-cardListSwitch"
                value={listViewMode}
                onChange={hanleViewModeChange}
                options={[
                  { id: 'card', name: <TUIIcon type="icon-apps-line1" /> },
                  { id: 'list', name: <TUIIcon type="icon-bulletpoint" /> },
                ]}
              />
              )
            : null}
        </Flex>
      </Flex>
      {getSlot('CRUD-TABLE-TOP')}
      {ListSchema
        ? (
          <Table
            loading={list.loading}
            data={list.data}
            columns={state.columns}
            rowSelection={{
              onChange: (selectedKeys, selectedRows) => {
                list.setSelected(selectedKeys, selectedRows);
              },
              selectedRowKeys: list.selectedKeys,
              getCheckboxProps: record => ({
                disabled: !!record.disabled,
              }),
            }}
            onChange={list.onChange}
            size={state.size}
            notShowIcon={notShowIcon}
            turnIcon={turnIcon}
            {...listProps}
            pagination={{ defaultPageSize: 20, ...pagination, ...list.pagination }}// 放最后，避免取不到list.pagination
          />
          )
        : null}
      {getSlot('CRUD-TABLE-BOTTOM')}

      <AddModal
        modalMode={modalMode}
        Modal={modalMode == 'drawer' ? Drawer : Modal}
        open={modal.visible}
        modalProps={modalProps}
        onOk={async () => {
          return await form.submit().then((r) => {
            modal.close();
            form.reset();
            list.doFetch(
              filter.fields,
              list.pagination,
              list.filters,
              list.sorters,
            );
            return r;
          });
        }}
        onCancel={() => {
          modal.close();
          form.reset();
        }}
      >
        <div className="TUI-AddForm-Container">
          {getSlot('CRUD-FORM-TOP')}
          <Form
            schema={FormSchema}
            defaultValue={form.defaultValue}
            fields={form.fields}
            errors={form.errors}
            onFieldChange={form.setField}
            onChange={form.setFields}
            {...formProps}
          />
          {getSlot('CRUD-FORM-BOTTOM')}
        </div>
      </AddModal>

      {getOtherSlot()}
    </div>
  );
}
