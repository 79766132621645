import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import { useLayoutContext } from '../../../context/LayoutContext';
import { getMatchMenus, getMatchRoutes } from '../../../utils/routes';
import './index.less';

interface TUIThreeLevelMenuProps {
  children?: React.ReactNode;
}

// 考虑 children 支持 Outlet
export default function TUIThreeLevelMenu({ children }: TUIThreeLevelMenuProps) {
  const [activeKey, setActiveKey] = useState('');
  const navigate = useNavigate();
  const { menus, routes } = useLayoutContext();
  const matches = useMatches();
  const matchMenus = getMatchMenus(menus, matches);
  const matchRoutes = getMatchRoutes(routes, matches);
  // 匹配路由后，设置 activeKey
  useEffect(() => {
    if (matchRoutes.length > 3)
      setActiveKey(matchRoutes[3].fullPath!);
  }, [matchRoutes]);
  // 空判断
  const twoLevelMenu = matchMenus[1];
  if (!twoLevelMenu)
    return null;
  const threeLevelMenus = twoLevelMenu.children;
  if (!threeLevelMenus || !threeLevelMenus.length)
    return;

  const onChange = (key: string) => {
    // TODO: 自定义单击事件
    navigate(key);
  };

  return (
    <>
      <div className="tui-three-level-menu">
        <Tabs
          size="large"
          activeKey={activeKey}
          items={threeLevelMenus.map(menu => ({ key: `${menu.key}`, label: menu.label }))}
          onChange={onChange}
        />
      </div>
      {children}
    </>
  );
}
