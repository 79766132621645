function S4() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}
export function guid() {
  return (`${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`);
}

export function getThumbUrl(url) {
  let ext = (/\.([^.]*)$/.exec(url) || [])[1];
  const cdn
    = 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/tui/assets/';
  if (!ext)
    return;
  ext = ext.toLowerCase();
  if (['bmp', 'jpg', 'jpeg', 'png', 'gif', 'webp'].includes(ext))
    return `${cdn}jpg` + `.png`;
  if (['pdf'].includes(ext))
    return `${cdn}pdf` + `.png`;
  if (['doc', 'docx'].includes(ext))
    return `${cdn}word` + `.png`;
  if (['xls', 'xlsx', 'xlsm'].includes(ext))
    return `${cdn}exl` + `.png`;
}

export function getNameFromUrl(url) {
  if (typeof url !== 'string')
    return ' ';
  return decodeURIComponent(url.replace(/^.*?\/([^/]*?\.[^/]*)$/, '$1'));
}
