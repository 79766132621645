import type { Argument } from 'classnames';

export interface ComponentBaseProps {
  children?: React.ReactNode;
  classNames?: Argument;
  style?: React.CSSProperties;
}

/**
 * 本地存储用到的key，规整到此处
 */
export enum LOCAL_STORAGE_KEY {
  /**
   * 用户信息
   */
  USERSTATUS = 'userInfo',
  /**
   * 主题信息
   */
  THEMESTATUS = 'tui-theme-status',
}
