export default (url, loaded, callback) => {
  if (loaded()) {
    callback && callback();
    return;
  }
  const jsapi = document.createElement('script');
  jsapi.src = url;
  jsapi.onload = () => {
    setTimeout(() => {
      callback && callback();
    }, 0);
  };
  document.head.appendChild(jsapi);
};
