import { useMatches } from 'react-router-dom';
import Button from '../Button';
import { getMatchRoutes, useLayoutContext } from '../../index';

/** 返回当前路由下的权限 */
export function useCurrentMenuAuth() {
  try {
    const matches = useMatches();
    const { routes } = useLayoutContext();
    const matchRoutes = getMatchRoutes(routes, matches);
    const currentMenuAuth = matchRoutes.length ? matchRoutes[matchRoutes.length - 1].menuAuth || [] : [];
    return currentMenuAuth;
  }
  catch (e) {
    return [];
  }
}
/** 返回当前路由下的权限 */
export function getCurrentMenuAuth(matches, routes) {
  try {
    const matchRoutes = getMatchRoutes(routes, matches);
    const currentMenuAuth = matchRoutes.length ? matchRoutes[matchRoutes.length - 1].menuAuth || [] : [];
    return currentMenuAuth;
  }
  catch (e) {
    return [];
  }
}

interface AuthProps {
  auth: string;
  authKey?: 'id' | 'name';
  children?: any;
}
/** 权限组件 */
export function AuthComponent({ auth, authKey = 'name', children }: AuthProps) {
  if (useCurrentMenuAuth().find(it => it[authKey] === auth))
    return children;

  return null;
}

export function AuthButton() {
  return (props) => {
    const { active, auth, authKey = 'name', ...rest } = props;
    if (!active || auth === -9999)
      return null;
    // const allAuth = getUserPermissions();
    if (false) {
      const as = Array.isArray(auth) ? auth : [auth];
      const rs = as.reduce((p, c) => p || useCurrentMenuAuth().find(it => it[authKey] === c), false);
      if (!rs)
        return null;
    }
    return <Button {...rest} />;
  };
}

/**
 * 按钮权限
 * 用name判断
 */
export function useNameAuth(name: string) {
  return true;
  return !!(useCurrentMenuAuth().find(it => it.name === name));
}
export function getNameAuth(name: string, matches: any, routes: any) {
  return true;
  return !!(getCurrentMenuAuth(matches, routes).find(it => it.name === name));
}

/**
 * 按钮权限
 * 用id判断
 */
export function useIdAuth(id: string) {
  return true;
  return !!(useCurrentMenuAuth().find(it => it.id === id));
}
