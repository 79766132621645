import type { UIMatch } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { isArray, mergeWith } from 'lodash-es';
import { processEveryNodeOfTree, removeEmptyChildrenOfTree } from 'tengits-fe-utils';
import TUILayout from '../components/TUILayout';
import type { MenuType, RouteBaseType, RouteType } from '../context/LayoutContext';
import TUIThreeLevelMenu from '../components/TUILayout/TUIThreeLevelMenu';

/**
 * 根据路由或菜单数组和匹配项数组获取匹配的路由或菜单列表
 *
 * @param routesOrMenus 路由或菜单数组
 * @param matches 匹配项数组
 * @returns 匹配的路由或菜单列表
 */
function getMatchs<T extends RouteBaseType<T>>(routesOrMenus: T[], matches: UIMatch[]) {
  const matchList: T[] = [];
  matches.forEach((m) => {
    const list = matchList.length > 0 ? matchList[matchList.length - 1].children : routesOrMenus;
    let pathname;
    if (m.params['*'] && m.pathname !== '/') {
      // 处理 * 结尾的路由，useMatches 返回的 pathname 会重复
      pathname = m.pathname.split('/').slice(0, m.id.split('-').length).join('/');
    }
    else {
      pathname = m.pathname;
    }

    const route = list?.find(r => r.fullPath === pathname);
    if (route)
      matchList.push(route);
  });
  return matchList;
}

/**
 * 获取匹配的路由
 *
 * @param routes 路由列表
 * @param matches 匹配项列表
 * @returns 匹配的路由
 */
export function getMatchRoutes(routes: RouteType[], matches: UIMatch[]) {
  return getMatchs(routes, matches);
}

/**
 * 根据匹配项获取匹配的菜单列表
 *
 * @param menus 菜单列表
 * @param matches 匹配项列表
 * @returns 匹配的菜单列表
 */
export function getMatchMenus(menus: MenuType[], matches: UIMatch[]) {
  return getMatchs(menus, matches);
}

export const rootRoute: RouteType = {
  path: '/',
  fullPath: '/',
  element: (
    <TUILayout>
      <Outlet></Outlet>
    </TUILayout>
  ),
  // errorElement: <h1>404</h1>,
};

export const commonRoutes: RouteType[] = [

];

type MenuItem = Required<MenuProps>['items'][number];

function getMenuItem(menuType: MenuType, theme: 'dark' | 'light'): MenuItem {
  return {
    key: menuType.key,
    icon: menuType.icon,
    label: menuType.label,
    title: menuType.label,
    theme,
  };
}

/**
 * 获取两级菜单列表
 *
 * @param menus 菜单列表
 * @returns 两级菜单列表
 */
export function getTwoLevelMenus(menus: MenuType[], sysMode: 'dark' | 'light') {
  const theme = sysMode;
  return menus.map((one) => {
    const menu = getMenuItem(one, theme);
    if (one.children && one.children.length > 0)
      menu.children = one.children.map(item => getMenuItem(item, theme));

    return menu;
  });
}

/**
 * 将菜单列表转换为路由列表
 *
 * @param menus 菜单列表
 * @param fun 回调函数，用于自定义处理路由对象
 * @returns 转换后的路由列表
 */
export function menusToRoutes(menus: MenuType[], fun: (route: RouteType, menu: MenuType) => void) {
  if (!menus || !menus.length)
    return [];

  const routes = menus.map((menu) => {
    const { label, icon, path, fullPath, children, menuAuth } = menu;
    const route: RouteType = {
      label,
      icon,
      path,
      fullPath,
      children: [],
      menuAuth,
    };
    if (children && children.length > 0) {
      route.children = route.children?.concat(menusToRoutes(children, fun));
      return route;
    }
    fun && fun(route, menu);
    return route;
  });
  const [first] = routes;
  routes.push({ path: '', element: <Navigate to={first.path} replace /> });

  return routes;
}

/**
 * 合并路由数组
 *
 * @param menuRoutes 菜单路由数组
 * @param customRoutes 自定义路由数组
 * @returns 合并后的路由数组
 */
export function mergeRoutes<T extends RouteType = RouteType>(menuRoutes: T[], customRoutes: T[]) {
  const routes: T[] = [];
  customRoutes.forEach((s) => {
    const find = menuRoutes.find(t => t.path === s.path);
    if (find) {
      routes.push(mergeWith({}, find, s, (objValue, srcValue) => {
        if (isArray(objValue))
          return mergeRoutes(objValue, srcValue);

        return undefined;
      }));
    }
    else { routes.push(s); }
  });
  return routes.concat(menuRoutes.filter(c => !routes.map(r => r.path).includes(c.path)));
}

/**
 * 补充三级菜单路由出口
 *
 * @param routes 路由数组，理论上是 / 路由的 children
 */
export function supplementThreeLevelOutlet(routes: RouteType[]) {
  routes.forEach((route1) => {
    if (route1.children && route1.children.length > 0) {
      route1.children.forEach((route2) => {
        if (route2.children && route2.children.length > 0)
          route2.element = <TUIThreeLevelMenu><Outlet></Outlet></TUIThreeLevelMenu>;
      });
    }
  });
  return routes;
}

/**
 * 获取懒加载组件
 * https://reactrouter.com/en/main/route/lazy
 * https://github.com/remix-run/react-router/discussions/10539#discussioncomment-6334500
 *
 * @param load 加载组件的异步函数
 * @returns 返回一个异步函数，该函数返回Promise对象，Promise解析后的值为一个对象，包含组件对象（Component）和其他属性（rest）
 * @template T 组件类型，必须包含default属性，该属性值为React组件类型或null
 */
export function getLazy<T extends { default: React.ComponentType | null }>(
  load: () => Promise<T>,
): () => Promise<
  Omit<T, 'default'> & { Component: React.ComponentType | null }
> {
  return async () => {
    const { default: Component, ...rest } = await load();
    return { ...rest, Component };
  };
}

/**
 * 根据菜单生成路由并合并自定义路由和其他路由
 *
 * @param menus 菜单列表
 * @param fn 菜单转路由的转换函数
 * @param customRoutes 自定义路由列表
 * @param otherRoutes 其他路由列表
 * @returns 返回一个包含菜单和路由的对象
 */
export function getMenusAndRoutes(menus, fn, customRoutes = [], otherRoutes = []) {
  // 1. 通过菜单生成路由
  const menuRoutes = menusToRoutes(menus, fn);
  // 2. 合并自定义路由
  const mergedRoutes = mergeRoutes(menuRoutes, customRoutes);
  // 3. 合并 / 根路由，或其他不在 / 下的路由
  const routes = [{ ...rootRoute, children: mergedRoutes }, ...otherRoutes];
  // 4. 移除空子节点
  removeEmptyChildrenOfTree(menus);
  // 5. 补充菜单的 fullPath
  processEveryNodeOfTree(menus, (node, p) => {
    if (p)
      node.key = node.fullPath = `${p.fullPath}/${node.path}`;
    else
      node.key = node.fullPath = `/${node.path}`;
  });
  // 6. 补充路由的 fullPath
  processEveryNodeOfTree(routes, (node, p) => {
    if (p && node.path && !node.fullPath)
      node.fullPath = `${p.fullPath === '/' ? '' : p.fullPath}/${node.path.replace('/*', '')}`;
  });
  // 6. / 根路由的默认跳转
  const route = routes.find(route => route.fullPath === '/');
  const rootChildren = route?.children;
  const firstMenu = menus[0];
  const firstMenuChildren = firstMenu?.children;
  const to = firstMenuChildren ? firstMenuChildren?.[0].fullPath : firstMenu?.fullPath;
  rootChildren?.push({
    path: '',
    element: <Navigate to={to || '/'} replace />,
  });
  return {
    menus,
    routes,
  };
}
