import React from 'react';

export default function Group({ selector, children, wrapers }) {
  const groups = {};
  React.Children.map(children, (c, i) => {
    const m = selector(c, i);
    groups[m] = groups[m] || [];
    groups[m].push(c);
  });

  return (
    <>
      {Object.values(groups).map((d, i) => {
        if (wrapers && wrapers[i])
          return React.cloneElement(wrapers[i], {}, d);

        return null;
      })}
    </>
  );
};
