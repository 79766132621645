import React from 'react';
import { Button, Modal, Upload, message } from 'antd';
import isEqual from 'lodash.isequal';
import { getNameFromUrl, guid } from '../../utils/index';
import './index.less';

export default class PicturesWall extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    fileList: [],
    spinning: false,
  };

  lastValue = [];
  mode = 'StringArray';

  format(props) {
    const { value = [] } = props;
    let temp;
    if (Array.isArray(value) && value.length > 0) {
      temp = value.map((item, index) => {
        this.setMode(item); // fix mutitime
        const uid = guid();
        if (typeof item === 'object') {
          const id = item.id ? item.id : uid;
          return {
            id,
            uid: id,
            name: getNameFromUrl(item.url || ''),
            status: 'done',
            url: item.url,
            delFlag: item.delFlag ? 1 : 0,
          };
        }

        return {
          id: uid,
          uid,
          name: getNameFromUrl(item || ''),
          status: 'done',
          url: item,
          delFlag: item.delFlag ? 1 : 0,
        };
      });
    }
    else {
      temp = [];
    }
    this.lastValue = [...temp];
    this.setState({
      fileList: temp.filter(d => !d.delFlag),
    });
  }

  componentDidMount() {
    this.format(this.props);
  }

  componentWillReceiveProps(props) {
    const befValue = this.props.value || [];
    const currentValue = props.value || [];
    if (
      !isEqual(props.value, this.props.value)
      && this.state.fileList.length != currentValue.length
      //  && !this.props.value
      // && !this.state.fileList.length
    )
      this.format(props || []);
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file) => {
    const fileType = file.type || file.url.replace(/.*?(\.[^./]*)$/, '$1');
    const url = file.url ? file.url : URL.createObjectURL(file.originFileObj);
    if (
      !['.jpg', '.png', 'image/png', 'image/jpg', 'image/jpeg'].includes(
        fileType,
      )
    ) {
      window.open(url);
      return;
    }
    this.setState({
      previewImage: url,
      previewVisible: true,
    });
  };

  beforeUpload = (file) => {
    const { opts } = this.props;
    const { maxSize = 500 } = opts;
    const { fileList } = this.state;
    const size = file.size;
    // B转换成M
    const sizeM = (size / 1024 ** 2).toFixed(2);
    if (sizeM > maxSize) {
      message.error(`文件超过${maxSize}M，请重新上传`, 3);
      setTimeout(() => {
        // this.onChange([]);
        this.setState({
          fileList,
        });
      }, 1000);
      return false;
    }
    console.log('成功');
    return true;
  };

  assetMaxLength = (fileList) => {
    const { maxLength } = this.props.opts || {};
    if (Array.isArray(fileList) && fileList.length >= maxLength)
      throw new Error(`文件个数不能超过${maxLength}个`);
  };

  customRequest = ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials,
  }) => {
    const { uploadFileToCloud, opts = {} } = this.props;

    // try {
    //   this.assetMaxLength(this.state.fileList);
    // } catch (e) {
    //   message.error(e.message);
    // }

    // console.log('上传---', opts, file, onProgress)

    this.setState({ spinning: true });
    if (file && uploadFileToCloud) {
      uploadFileToCloud(file, onProgress)
        .then((res) => {
          this.onAdd(
            file,
            res.Location.startsWith('http')
              ? res.Location
              : `https://${res.Location}`,
          );
          onSuccess(res, file);
        })
        .catch(console.log);
      this.setState({ spinning: false });
    }

    return {
      abort() {
        console.log('upload progress is aborted.');
      },
    };
  };

  setMode = (file) => {
    if (typeof file === 'string')
      this.mode = 'StringArray';
    if (typeof file === 'object')
      this.mode = 'ObjectArray';
  };

  formatObjectValue = (file, url) => {
    const data = {
      id: file.uid || 0,
      delFlag: 0,
      url,
      name: getNameFromUrl(url),
    };
    return data;
  };

  onChange = (list) => {
    const { mode } = this;
    if (mode === 'StringArray') {
      this.props.onChange(list.map(d => d.url));
    }
    else {
      this.props.onChange(
        list.map(d => ({
          ...d,
          id: typeof d.id === 'number' ? d.id : 0,
        })),
      );
    }
  };

  onAdd = (file, url) => {
    const { mode, lastValue, formatObjectValue, onChange } = this;

    lastValue.push(formatObjectValue(file, url));

    onChange(lastValue);
  };

  onRemove = (file) => {
    const { mode, lastValue = [], onChange } = this;

    for (const i in lastValue) {
      const item = lastValue[i];
      if (item.id === file.uid) {
        if (typeof file.uid === 'number')
          item.delFlag = 1;
        else
          lastValue.splice(i, 1);

        break;
      }
    }

    onChange(lastValue);
  };

  handleChange = (info) => {
    this.setState({
      fileList: info.fileList,
    });
  };

  render() {
    const { previewVisible, previewImage, fileList, spinning } = this.state;
    const { value, opts = {} } = this.props;

    const {
      action,
      data,
      maxLength = 999,
      listType = 'picture',
      disabled = false,
      supportText = '点击上传',
      uploadMode = 'drawer',
    } = opts;

    const hasMaxLength = fileList && fileList.length >= maxLength;

    return (
      <div
        className="TUI-DraggerUploader"
        style={{ marginBottom: '20px' }}
      >
        <Modal
          className="imgModalCloseX"
          open={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <Upload
          onChange={this.handleChange}
          data={data || {}}
          beforeUpload={this.beforeUpload}
          customRequest={this.customRequest}
          action={action}
          fileList={fileList}
          headers={{ Authorization: localStorage.getItem('token') }}
          onPreview={this.handlePreview}
          onRemove={this.onRemove}
          {...opts}
          uploadMode={uploadMode}
          listType={listType}
          disabled={!!disabled}
        >
          <Button
            className={`TUI-DraggerUploader-Div ${
                hasMaxLength || disabled
                  ? 'TUI-DraggerUploader-DisabledBtn'
                  : ''
            }`}
            disabled={hasMaxLength || disabled}
          >
            <span className="TUI-DraggerUploader-Tips">
              {hasMaxLength
                ? `${maxLength}/${maxLength} 达到最大上传数`
                : supportText || '点击上传'}
            </span>
          </Button>
        </Upload>
      </div>
    );
  }
}
