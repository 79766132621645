import { useEffect, useState } from 'react';
import { Button, Flex, Pagination, Space, Spin } from 'antd';
import classNames from 'classnames';
import usePaginationHooks from '../../hooks/usePaginationHooks';
import { Search } from '../../index';

export default function TUICardTable({
  PageId = 'pageId',
  dependPara = {},
  dependField = '',
  refresh,
  onFetchCard,
  SearchPara = {},
  AddPara = {},
  ButtonExtra,
  onAdd = () => {},
  RenderCard = item => '--',
  defaultPageSize = 10,
  pageSizeOptions = [10, 15, 20, 25],
  onFeatchStart = it => true, // 请求前的判断
  isRenderSelf = false,
  className,
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState();

  const {
    total,
    current,
    pageSize,
    onChange,
    onShowSizeChange,
    changePagenation,
  } = usePaginationHooks({ defaultPageSize });

  const getCardData = async (current, pageSize, keyWord) => {
    if (!onFetchCard)
      return;

    if (dependField && !dependPara[dependField])
      return;

    if (!onFeatchStart(dependPara))
      return;

    setLoading(true);
    const r = await onFetchCard({
      pageIndex: current || 1,
      pageCount: pageSize || defaultPageSize,
      keyWord,
      ...dependPara,
    });
    setLoading(false);
    changePagenation({
      ...r.pagination,
    });
    setData(r.data);
  };

  const onSearch = (val) => {
    setSearchVal(val);
    getCardData(1, pageSize, val);
  };

  useEffect(() => {
    refresh && getCardData(current, pageSize, searchVal);
  }, [refresh]);

  useEffect(() => {
    getCardData(current, pageSize, searchVal);
  }, [current, pageSize]);

  try {
    useEffect(() => {
      if (dependField && dependPara[dependField]) {
        setSearchVal('');
        getCardData(1, defaultPageSize, '');
      }
    }, [dependPara[dependField]]);
  }
  catch (e) {
    console.log(e);
  }

  return (
    <div className={classNames('TUI-CardTable', className)} id={PageId} key={PageId}>
      <Space gap="calc(var(--diff-margin, 0px) + 16px)" style={{ marginBottom: `calc(var(--diff-margin, 0px) + 24px)` }}>
        <Search
          value={searchVal}
          onChange={onSearch}
          placeholder={SearchPara.placeholder || '搜索'}
        />
        <Button
          id="add_Id"
          type="primary"
          onClick={onAdd}
        >
          {AddPara.addText || '新增'}
        </Button>
        {
            ButtonExtra
        }
      </Space>
      <Spin spinning={loading}>
        {isRenderSelf
          ? RenderCard({ data, loading })
          : (
            <Flex wrap="wrap" gap="--size">
              {data.map(item => RenderCard({ item, loading }))}
            </Flex>
            )}
        <Pagination
          style={{ textAlign: 'right', marginTop: `calc(var(--diff-margin, 0px) + 24px)` }}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={pageSizeOptions}
          total={total}
          current={current}
          pageSize={pageSize}
          onChange={onChange}
          onShowSizeChange={onShowSizeChange}
          showTotal={total => `共${total}条数据`}
        />
      </Spin>

    </div>
  );
}
