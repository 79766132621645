import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { message } from '../../index';
import request from '../../utils/request';
import useFormController from '../../hooks/useFormController';
import Form from '../Form';
import { formatReq, formatRes } from './format';
import './Crud.less';
import {
  getComputed,
  getComputedField,
  getDefaultFields,
  getFiltedSchema,
  getFormSchema,
  getOptions,
  getRelatedOptionField,
  getRelatedSchemaTrigger,
  removeValueOnOptionChanged,
  useFirstOption,
} from './schemahelper';

interface AdvancedFormProps {
  schema?: any;
  api?: object;
  auth?: object;
  defaultService?: object;
  defaultFormValue?: object;
  formMode?: string;
  onStateChange?: Function;
}

function AdvancedFormT({
  schema,
  api = {},
  auth = {},
  defaultService = {},
  defaultFormValue,
  formMode = 'add',
  onStateChange,
  ...formProps
}, ref) {
  const [formSchema, setFormSchema] = useState(
    getFormSchema(schema, [], formMode === 'detail'), // TODO 抽取readonly逻辑
  );
  const [formOptions, setFormOptions] = useState({});
  const formOptionsRef = useRef();
  formOptionsRef.current = formOptions;
  const service = {
    ...defaultService,
    submit: async (params) => {
      if (params.id) {
        return request(api.edit, formatReq(params, schema, 'form')).catch(
          (e) => {
            message.error(e.message);
            throw e;
          },
        );
      }
      else {
        return request(api.add, formatReq(params, schema, 'form')).catch(
          (e) => {
            message.error(e.message);
            throw e;
          },
        );
      }
    },
  };
  const form = useFormController({
    schema: formSchema,
    defaultValue: defaultFormValue,
    doSubmit: service.submit,
    onStateChange,
  });
  useImperativeHandle(ref, () => {
    return Object.assign(form, {
      setOptions: setFormOptions,
    });
  }, [form]);
  const relatedOptionKey = getRelatedOptionField(schema);
  const relatedSchemaKey = getRelatedSchemaTrigger(schema);
  const computedKey = getComputedField(schema);
  useEffect(() => {
    getOptions(defaultFormValue || {}, schema, 'onFormMount')
      .then((p) => {
        setFormOptions(p);
        const used = useFirstOption({
          form,
          options: p,
          schema,
        });
        if (used)
          form.setFields(form.fields);
      })
      .catch((e) => {
        message.error(e.message || '获取下拉参数失败！');
      });
  }, [formMode]);
  computedKey.map((key) => {
    useEffect(() => {
      // if (form.fields[key] === undefined) return;
      if (!Object.keys(form.fields).length)
        return;
      getComputed(
        form.fields,
        schema,
        `onChange:${key}`,
        formOptionsRef.current,
      )
        .then((p) => {
          form.setFields({
            ...form.fields,
            ...p,
          });
        })
        .catch((e) => {
          console.log(e);
          message.error(e.message || '获取计算参数失败！');
        });
    }, [form.fields[key]]);
  });
  relatedSchemaKey.map((key) => {
    useEffect(() => {
      if (form.fields[key] === undefined)
        return;
      getFiltedSchema(form.fields, schema, `onChange:${key}`)
        .then((p) => {
          setFormSchema(
            getFormSchema(
              p,
              formOptionsRef.current,
              formMode === 'detail',
            ),
          );
        })
        .catch((e) => {
          console.log(e);
          message.error(e.message || '获取表单参数失败！');
        });
    }, [form.fields[key]]);
  });
  relatedOptionKey.map((key) => {
    useEffect(() => {
      // 关闭表单触发
      if (!Object.keys(form.fields).length) {
        setFormOptions({});
        return;
      }
      getOptions(form.fields, schema, `onChange:${key}`)
        .then((p) => {
          setFormOptions(pre => ({ ...pre, ...p }));
          const removed = removeValueOnOptionChanged({
            form,
            options: p,
          });
          const used = useFirstOption({
            form,
            options: p,
            schema,
          });
          if (removed || used)
            form.setFields(form.fields);
        })
        .catch((e) => {
          message.error(e.message || '获取下拉参数失败！');
        });
    }, [form.fields[key]]);
  });

  useEffect(() => {
    setFormSchema((sch) => {
      Object.keys(formOptions).map((key) => {
        if (sch[key])
          sch[key].options = formOptions[key];
      });
      return { ...sch };
    });
  }, [formOptions]);
  useEffect(() => {
    const defaultValues = getDefaultFields(schema);
    if (!Object.keys(defaultValues).length)
      return; // 避免多余的渲染
    form.setFields({
      ...formatRes(defaultValues, schema),
    });
  }, []);
  return (
    <Form
      schema={formSchema}
      defaultValue={defaultFormValue}
      fields={form.fields}
      errors={form.errors}
      onFieldChange={form.setField}
      onChange={form.setFields}
      {...formProps}
    />
  );
};

const AdvancedForm = forwardRef<HTMLUListElement, AdvancedFormProps>(AdvancedFormT);
export default AdvancedForm;
