import { Tag } from 'antd';

export function TUITag(props) {
  const { colors = {}, style = {}, ...otherProps } = props;
  return (
    <Tag
      style={{
        color: colors.cr,
        background: colors.bgc,
        borderColor: colors.cr,
        textAlign: 'center',
        ...style,
      }}
      {...otherProps}
    />
  );
}
