import { Children, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { App, Flex } from 'antd';
import { Crud, TUIIcon, message } from '../../index';
import request from '../../utils/request';
import { AuthButton } from '../AuthButton';
import NullWhenNoChildren from '../NullWhenNoChildren';
import useCrudController from '../../hooks/useCrudController';
import {
  addComponent,
  getComponent,
  getComputed,
  getComputedField,
  getCurrentAction,
  getDefaultFields,
  getFiltedSchema,
  getFilterSchema,
  getFormSchema,
  getListSchema,
  getModalTitleFromAction,
  getOptions,
  getRelatedOptionField,
  getRelatedSchemaTrigger,
  removeValueOnOptionChanged,
  setCurrentAction,
  useFirstOption,
} from './schemahelper';
import { addRule, getRule } from './rule';
import { addReqTransformer, addResTransformer, formatReq, formatRes, getReqTransformer, getResTransformer } from './format';
import getDownLoadUrl from './getDownLoadUrl';
import './Crud.less';

export { getComponent, addComponent } from './schemahelper';

const Button = AuthButton();
// shcmea view list: 列表， form： 表单 filter:过滤 可叠加
const defaultSchema = [
  {
    field: 'field1',
    name: '字段1',
    view: ['list', 'form'],
    type: 'DatePicker',
    rules: ['required'],
    placeholder: '',
  },
  {
    field: 'field2',
    name: '字段2',
    view: ['list', 'form', 'filter'],
    optionApi: '/api/test/option/field2',
    optionTrigger: ['onChange:field1', 'onFilterMount', 'onFormMount'],
    type: 'Select',
    rules: ['required', 'number'],
    placeholder: '',
    listRender: ({ record }) => {
      return `${record.field1}/`;
    },
  },
];
function AdvancedCrudCom({
  schema = defaultSchema,
  api = {},
  auth = {},
  rowKey = 'id',
  defaultService = {},
  modalWrapClassName,
  hookBeforeAdd,
  showColumnAction = true,
  columnActionFixed = true,
  Table,
  Form,
  Modal,
  Filter,
  children,
  onFormStateChange,
  modalProps,
  listProps,
  defaultFilterValue,
  defaultFormValue,
  onFilterStateChange,
  defaultListViewMode = 'list',
  defaultModalMode,
  ...rest
}, ref) {
  const AntApp = App.useApp();

  const [formSchema, setFormSchema] = useState(
    getFormSchema(schema, [], false),
  );
  const [formOptions, setFormOptions] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [formMode, setFormMode] = useState('');
  const formOptionsRef = useRef();
  formOptionsRef.current = formOptions;
  const modalTitle = getModalTitleFromAction(api);
  const modalFooter = {
    add: {},
    edit: {},
    detail: { noFooter: true },
  }[formMode];
  const service = {
    ...defaultService,
    list: async ({ query, pagination, sorters }) => {
      const { field, order } = sorters || {};
      const task = request(api.list, {
        ...formatReq(query, schema, 'filter'),
        pageIndex: pagination.current || 1,
        pageCount: pagination.pageSize || 10,
        sortField: field,
        sortOrder: order,
      });
      const res = await task;
      return res;
    },
    submit: async (params) => {
      if (formMode === 'edit') {
        return request(typeof api.edit === 'object' ? api.edit.url : api.edit, formatReq(params, schema, 'form'), schema).catch(
          (e) => {
            message.error(e.message);
            throw e;
          },
        );
      }
      else {
        return request(typeof api.add === 'object' ? api.add.url : api.add, formatReq(params, schema, 'form'), schema).catch(
          (e) => {
            message.error(e.message);
            throw e;
          },
        );
      }
    },
    doHandleItemById: async (params) => {
      try {
        const task = request(
          typeof api.remove === 'object' ? api.remove.url : api.remove,
          {
            id: params.id,
          },
          params,
        );
        const res = await task;
        return res;
      }
      catch (e) {
        message.error(e.message);
        throw e;
      }
    },
    query: async (url, params, record) => {
      try {
        const task = request(url, params, record);
        const res = await task;
        return res;
      }
      catch (e) {
        message.error(e.message);
        throw e;
      }
    },
  };
  const filterSchema = getFilterSchema(schema, filterOptions);
  const columns = getListSchema(
    schema,
    api,
    auth,
    setFormSchema,
    formOptions,
    filterOptions,
    showColumnAction,
    columnActionFixed,
    AntApp,
  );
  const crud = useCrudController({
    ListSchema: columns,
    FilterSchema: filterSchema,
    FormSchema: formSchema,
    service,
    onFormStateChange,
    defaultFilterValue,
    defaultFormValue,
    onFilterStateChange,
    AntApp,
    listProps,
  });
  useImperativeHandle(ref, () => {
    Object.assign(crud.filter, {
      setOptions: setFilterOptions,
      options: filterOptions,
    });
    Object.assign(crud.form, {
      setOptions: setFormOptions,
      options: formOptions,
    });
    return crud;
  });
  useEffect(() => {
    if (api.list)
      crud.list.doFetch();
  }, []);
  const relatedOptionKey = getRelatedOptionField(schema);
  const relatedSchemaKey = getRelatedSchemaTrigger(schema);
  const computedKey = getComputedField(schema);
  useEffect(() => {
    getOptions({ event: 'onFilterMount' }, schema, 'onFilterMount')
      .then((p) => {
        setFilterOptions(p);
      })
      .catch((e) => {
        message.error(e.message || '获取下拉参数失败！');
      });
  }, []);
  useEffect(() => {
    if (!crud.modal.visible)
      return;
    getOptions(crud.form.fields, schema, 'onFormMount')
      .then((p) => {
        setFormOptions(p);
        const used = useFirstOption({
          form: crud.form,
          options: p,
          schema,
        });
        if (used)
          crud.form.setFields(crud.form.fields);
      })
      .catch((e) => {
        message.error(e.message || '获取下拉参数失败！');
      });
  }, [formMode, crud.modal.visible]);
  computedKey.map((key) => {
    useEffect(() => {
      // if (crud.form.fields[key] === undefined) return;
      if (!Object.keys(crud.form.fields).length)
        return;
      getComputed(
        crud.form.fields,
        schema,
        `onChange:${key}`,
        formOptionsRef.current,
      )
        .then((p) => {
          crud.form.setFields({
            ...crud.form.fields,
            ...p,
          });
        })
        .catch((e) => {
          console.log(e);
          message.error(e.message || '获取计算参数失败！');
        });
    }, [crud.form.fields[key]]);
  });
  relatedSchemaKey.map((key) => {
    useEffect(() => {
      if (crud.form.fields[key] === undefined)
        return;
      getFiltedSchema(crud.form.fields, schema, `onChange:${key}`)
        .then((p) => {
          setFormSchema(
            getFormSchema(
              p,
              formOptionsRef.current,
              formMode === 'detail',
            ),
          );
        })
        .catch((e) => {
          console.log(e);
          message.error(e.message || '获取表单参数失败！');
        });
    }, [crud.form.fields[key]]);
  });
  relatedOptionKey.map((key) => {
    useEffect(() => {
      // 关闭表单触发
      if (!Object.keys(crud.form.fields).length) {
        setFormOptions({});
        return;
      }
      getOptions(crud.form.fields, schema, `onChange:${key}`)
        .then((p) => {
          setFormOptions(pre => ({ ...pre, ...p }));
          // 清除联动字段
          const removed = removeValueOnOptionChanged({
            form: crud.form,
            options: p,
          });
          const used = useFirstOption({
            form: crud.form,
            schema,
            options: p,
          });
          if (removed || used)
            crud.form.setFields(crud.form.fields);
        })
        .catch((e) => {
          message.error(e.message || '获取下拉参数失败！');
        });
    }, [crud.form.fields[key]]);
  });
  relatedOptionKey.map((key) => {
    useEffect(() => {
      if (!Object.keys(crud.filter.tempValue).length) {
        // setFilterOptions({});
        return;
      }
      getOptions(crud.filter.tempValue, schema, `onChange:${key}`)
        .then((p) => {
          setFilterOptions(pre => ({ ...pre, ...p }));
          const removed = removeValueOnOptionChanged({
            form: {
              fields: crud.filter.tempValue,
            },
            options: p,
          });
          const used = useFirstOption({
            form: {
              fields: crud.filter.tempValue,
            },
            schema,
            options: p,
          });
          if (removed || used)
            crud.filter.ref.current.setFields({ ...crud.filter.tempValue });
        })
        .catch((e) => {
          message.error(e.message || '获取下拉参数失败！');
        });
    }, [crud.filter.tempValue[key]]);
  });
  relatedOptionKey.map((key) => {
    useEffect(() => {
      if (!Object.keys(crud.filter.fields).length) {
        // setFilterOptions({});
        return;
      }
      getOptions(crud.filter.fields, schema, `onChange:${key}`)
        .then((p) => {
          setFilterOptions(pre => ({ ...pre, ...p }));
        })
        .catch((e) => {
          message.error(e.message || '获取下拉参数失败！');
        });
    }, [crud.filter.fields[key]]);
  });
  useEffect(() => {
    setFormSchema((sch) => {
      Object.keys(formOptions).map((key) => {
        if (sch[key])
          sch[key].options = formOptions[key];
      });
      return { ...sch };
    });
  }, [formOptions]);
  const { filter } = crud;
  const downloadApi = typeof api.download === 'object' ? api.download : '';
  const addApi = typeof api.add === 'object' ? api.add : '';
  const downloadApiUrl = typeof api.download === 'object' ? api.download.url : api.download;

  const downloadUrl = downloadApiUrl
    ? getDownLoadUrl(downloadApiUrl, {
      ...filter.fields,
    })
    : '';
  const inlineSolts = [];
  const otherSolts = [];
  // fix React.Children.map 会修改key
  Children.forEach(children, (child) => {
    if (child && ['CRUD-FILTER-BOTTOM', 'CRUD-FILTER-INLINE'].includes(child.key)) {
      if (!child.props.selfRender) {
        inlineSolts.push(child.props.children);
        return;
      }
      inlineSolts.push(child);
    }
    else {
      otherSolts.push(child);
    }
  });

  return (
    <Crud
      className="TUI-AdvancedCrud"
      modal={crud.modal}
      form={crud.form}
      FormSchema={crud.FormSchema}
      filter={crud.filter}
      FilterSchema={crud.FilterSchema}
      list={crud.list}
      ListSchema={crud.ListSchema}
      listProps={{ scrollX: true, rowKey, ...listProps }}
      modalProps={{
        title: modalTitle,
        wrapClassName: modalWrapClassName,
        ...modalFooter,
        ...modalProps,
      }}
      setFormMode={setFormMode}
      query={service.query}
      formOptions={formOptions}
      filterOptions={filterOptions}
      Table={Table}
      Form={Form}
      Modal={Modal}
      Filter={Filter}
      defaultListViewMode={defaultListViewMode}
      defaultModalMode={defaultModalMode}
      {...rest}
    >
      <NullWhenNoChildren
        key="CRUD-FILTER-BOTTOM"
        wrap={(
          <div
            className="TUI-Crud-Toolbox"
          >
          </div>
        )}
      >
        <Button
          type="primary"
          active={true}
          auth={api.add ? auth.add : -9999}
          icon={addApi ? addApi.icon || <TUIIcon type="icon-add" /> : <TUIIcon type="icon-add" />}
          onClick={addApi && api.add.onClick ? api.add.onClick : async () => {
            // TODO 是否要处理api.add.onClick这种情况
            setCurrentAction(api, 'add');
            const fn = (red) => {
              const formSchema = getFormSchema(schema, formOptions, false);
              setFormSchema(formSchema);
              setFormMode('add');
              crud.form.setFields({
                ...formatRes(getDefaultFields(schema, red), schema),
                ...red,
              });
              crud.modal.show();
            };
            if (typeof hookBeforeAdd === 'function') {
              try {
                const rec = await hookBeforeAdd({
                  crud,
                  formMode,
                  formOptions,
                  defaultFormValue,
                });
                fn(rec);
              }
              catch (e) {
                message.error(e.message);
              }
            }
            else {
              fn(defaultFormValue);
            }
          }}
        >
          {addApi ? api.add.name : '新增'}
        </Button>
        <Button
          icon={downloadApi ? downloadApi.icon || <TUIIcon type="icon-download" /> : <TUIIcon type="icon-download" />}
          href={downloadUrl}
          active={true}
          auth={api.download ? auth.download : -9999}
          target="blank"
        >
          {downloadApi ? auth.download.name : '导出数据'}
        </Button>
        <Flex wrap="wrap" className="TUI-inlineSolts">
          {
            inlineSolts.map(it => it)
          }
        </Flex>
      </NullWhenNoChildren>
      {
        otherSolts
      }
    </Crud>
  );
}
const AdvancedCrud = forwardRef(AdvancedCrudCom);
AdvancedCrud.addComponent = addComponent;
AdvancedCrud.getComponent = getComponent;
AdvancedCrud.addRule = addRule;
AdvancedCrud.getRule = getRule;
AdvancedCrud.addReqTransformer = addReqTransformer;
AdvancedCrud.addResTransformer = addResTransformer;
AdvancedCrud.getReqTransformer = getReqTransformer;
AdvancedCrud.getResTransformer = getResTransformer;
AdvancedCrud.getCurrentAction = getCurrentAction;
export default AdvancedCrud;
