import {
  SettingOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons';
import {
  Checkbox,
  Popover,
  Space,
  type TableColumnType,
  Tooltip,
  Tree,
  Typography,
} from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { DataNode } from 'antd/lib/tree';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Container, TableContext } from './provide';
import { filterHiddenLeaves, genColumnKey, sortByFixedAndSortKeyColumns } from './lib';
import styles from './index.module.less';

interface SettingOptionType {
  draggable?: boolean;
  checkable?: boolean;
  showListItemOption?: boolean;
  checkedReset?: boolean;
  listsHeight?: number;
  extra?: React.ReactNode;
  children?: React.ReactNode;
  settingIcon?: React.ReactNode;
}

interface ColumnsState {
  show?: boolean;
  fixed?: 'right' | 'left' | undefined;
  order?: number;
  disable?:
    | boolean
    | {
      checkbox: boolean;
    };
}

type ColumnSettingProps<T = any> = SettingOptionType & {
  columns: TableColumnType<T>[];
  defaultColumns: TableColumnType<T>[];
  setColumns: any;
};

function useRefFunction<T extends (...args: any) => any>(reFunction: T) {
  const ref = useRef<any>(null);
  ref.current = reFunction;
  return useCallback((...rest: Parameters<T>): ReturnType<T> => {
    return ref.current?.(...(rest as any));
  }, []);
}

function runFunction<T extends any[]>(valueEnum: any, ...rest: T) {
  if (typeof valueEnum === 'function')
    return valueEnum(...rest);

  return valueEnum;
}

const ToolTipIcon: React.FC<{
  title: string;
  columnKey: string | number;
  show: boolean;
  fixed: 'left' | 'right' | undefined;
  children?: React.ReactNode;
}> = ({ title, show, children, columnKey, fixed }) => {
  const { columnsMap, setColumnsMap } = useContext(TableContext);
  if (!show)
    return null;

  return (
    <Tooltip title={title}>
      <span
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const config = columnsMap[columnKey] || {};
          const columnKeyMap = {
            ...columnsMap,
            [columnKey]: { ...config, fixed } as ColumnsState,
          };
          setColumnsMap(columnKeyMap);
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
};

const CheckboxListItem: React.FC<{
  columnKey: string | number;
  className?: string;
  title?: React.ReactNode;
  fixed?: boolean | 'left' | 'right';
  showListItemOption?: boolean;
  isLeaf?: boolean;
}> = ({ columnKey, isLeaf, title, className, fixed, showListItemOption }) => {
  const dom = (
    <span className={styles.TUI_ColumnSetting_list_item_option}>
      <ToolTipIcon
        columnKey={columnKey}
        fixed="left"
        title="固定在列首"
        show={fixed !== 'left'}
      >
        <VerticalAlignTopOutlined />
      </ToolTipIcon>
      <ToolTipIcon
        columnKey={columnKey}
        fixed={undefined}
        title="不固定"
        show={!!fixed}
      >
        <VerticalAlignMiddleOutlined />
      </ToolTipIcon>
      <ToolTipIcon
        columnKey={columnKey}
        fixed="right"
        title="固定在列尾"
        show={fixed !== 'right'}
      >
        <VerticalAlignBottomOutlined />
      </ToolTipIcon>
    </span>
  );
  return (
    <span className={styles.TUI_ColumnSetting_list_item} key={columnKey}>
      <div>
        {title}
      </div>
      {showListItemOption && !isLeaf ? dom : null}
    </span>
  );
};

const CheckboxList: React.FC<{
  list: any;
  className?: string;
  title: string;
  draggable: boolean;
  checkable: boolean;
  showListItemOption: boolean;
  showTitle?: boolean;
  listHeight?: number;
}> = ({
  list,
  draggable,
  checkable,
  showListItemOption,
  className,
  showTitle = true,
  title: listTitle,
  listHeight = 280,
}) => {
  const { columnsMap, setColumnsMap, sortKeyColumns, setSortKeyColumns }
      = useContext(TableContext);
  const show = list && list.length > 0;
  const treeDataConfig = useMemo(() => {
    if (!show)
      return {};
    const checkedKeys: string[] = [];
    const treeMap = new Map<string | number, DataNode>();

    const loopData = (
      data: any[],
      parentConfig?: ColumnsState & {
        columnKey: string;
      },
    ): DataNode[] =>
      data.map(({ key, dataIndex, children, ...rest }) => {
        const columnKey = genColumnKey(
          key,
          [parentConfig?.columnKey, rest.index].filter(Boolean).join('-'),
        );
        const config = columnsMap[columnKey || 'null'] || { show: true };
        if (config.show !== false && !children)
          checkedKeys.push(columnKey);

        const item: DataNode = {
          key: columnKey,
          ...rest,
          selectable: false,
          disabled: config.disable === true,
          disableCheckbox:
              typeof config.disable === 'boolean'
                ? config.disable
                : config.disable?.checkbox,
          isLeaf: parentConfig ? true : undefined,
        };

        if (children) {
          item.children = loopData(children, {
            ...config,
            columnKey,
          });
          // 如果children 已经全部是show了，把自己也设置为show
          if (
            item.children?.every(childrenItem =>
              checkedKeys?.includes(childrenItem.key as string),
            )
          )
            checkedKeys.push(columnKey);
        }
        treeMap.set(key, item);
        return item;
      });
    return { list: loopData(list), keys: checkedKeys, map: treeMap };
  }, [columnsMap, list, show]);

  /** 移动到指定的位置 */
  const move = useRefFunction(
    (id: React.Key, targetId: React.Key, dropPosition: number) => {
      const newMap = { ...columnsMap };
      const newColumns = [...sortKeyColumns];
      const findIndex = newColumns.findIndex(columnKey => columnKey === id);
      const targetIndex = newColumns.findIndex(
        columnKey => columnKey === targetId,
      );
      const isDownWard = dropPosition >= findIndex;
      if (findIndex < 0)
        return;
      const targetItem = newColumns[findIndex];
      newColumns.splice(findIndex, 1);

      if (dropPosition === 0) {
        newColumns.unshift(targetItem);
      }
      else {
        newColumns.splice(
          isDownWard ? targetIndex : targetIndex + 1,
          0,
          targetItem,
        );
      }
      // 重新生成排序数组
      newColumns.forEach((key, order) => {
        newMap[key] = { ...(newMap[key] || {}), order };
      });
      // 更新数组
      setColumnsMap(newMap);
      setSortKeyColumns(newColumns);
    },
  );

  /** 选中反选功能 */
  const onCheckTree = useRefFunction((e) => {
    const newColumnMap = { ...columnsMap };

    const loopSetShow = (key: string | number) => {
      const newSetting = { ...newColumnMap[key] };
      newSetting.show = e.checked;
      // 如果含有子节点，也要选中
      if (treeDataConfig.map?.get(key)?.children) {
        treeDataConfig.map
          .get(key)
          ?.children?.forEach(item => loopSetShow(item.key as string));
      }
      newColumnMap[key] = newSetting;
    };
    loopSetShow(e.node.key);
    setColumnsMap({ ...newColumnMap });
  });

  if (!show)
    return null;

  const listDom = (
    <Tree
      itemHeight={24}
      draggable={
          draggable
          && !!treeDataConfig.list?.length
          && treeDataConfig.list?.length > 1
        }
      checkable={checkable}
      onDrop={(info) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const { dropPosition, dropToGap } = info;
        const position
            = dropPosition === -1 || !dropToGap ? dropPosition + 1 : dropPosition;
        move(dragKey, dropKey, position);
      }}
      blockNode
      onCheck={(_, e) => onCheckTree(e)}
      checkedKeys={treeDataConfig.keys}
      showLine={false}
      titleRender={(_node) => {
        const node = { ..._node, children: undefined };
        if (!node.title)
          return null;
        const normalizedTitle = runFunction(node.title, node);
        const wrappedTitle = (
          <Typography.Text
            style={{ width: 80 }}
            ellipsis={{ tooltip: normalizedTitle }}
          >
            {normalizedTitle}
          </Typography.Text>
        );

        return (
          <CheckboxListItem
            className={className}
            {...node}
            showListItemOption={showListItemOption}
            title={wrappedTitle}
            columnKey={node.key as string}
          />
        );
      }}
      height={listHeight}
      treeData={treeDataConfig.list?.map(
        ({
          disabled /* 不透传 disabled，使子节点禁用时也可以拖动调整顺序 */,
          ...config
        }) => config,
      )}
    />
  );
  return (
    <>
      {showTitle && (
        <span className={styles.TUI_ColumnSetting_list_item_title}>
          {listTitle}
        </span>
      )}
      {listDom}
    </>
  );
};

const GroupCheckboxList: React.FC<{
  localColumns: any;
  className?: string;
  draggable: boolean;
  checkable: boolean;
  showListItemOption: boolean;
  listsHeight?: number;
}> = ({
  localColumns = [],
  className,
  draggable,
  checkable,
  showListItemOption,
  listsHeight,
}) => {
  const rightList: any = [];
  const leftList: any = [];
  const list: any = [];

  localColumns.forEach((item) => {
    /** 不在 setting 中展示的 */
    if (item.hideInSetting)
      return;

    const { fixed } = item;
    if (fixed === 'left') {
      leftList.push(item);
      return;
    }
    if (fixed === 'right') {
      rightList.push(item);
      return;
    }
    list.push(item);
  });

  const showRight = rightList && rightList.length > 0;
  const showLeft = leftList && leftList.length > 0;
  return (
    <div
      className="TUI-ColumnSetting-list-group"
    >
      <CheckboxList
        title="固定在左侧"
        list={leftList}
        draggable={draggable}
        checkable={checkable}
        showListItemOption={showListItemOption}
        className={className}
        listHeight={listsHeight}
      />
      {/* 如果没有任何固定，不需要显示title */}
      <CheckboxList
        list={list}
        draggable={draggable}
        checkable={checkable}
        showListItemOption={showListItemOption}
        title="不固定"
        showTitle={showLeft || showRight}
        className={className}
        listHeight={listsHeight}
      />
      <CheckboxList
        title="固定在右侧"
        list={rightList}
        draggable={draggable}
        checkable={checkable}
        showListItemOption={showListItemOption}
        className={className}
        listHeight={listsHeight}
      />
    </div>
  );
};

function defaultColumnKeyMap(columns, columnKeyMap = {} as Record<string, any>) {
  columns?.forEach(({ key, dataIndex, fixed, disable, children }, index) => {
    const columnKey = genColumnKey(key ?? (dataIndex as React.Key), index);
    if (columnKey) {
      columnKeyMap[columnKey] = {
        show: true,
        fixed,
        disable,
      };
    }
    if (children)
      defaultColumnKeyMap(children, columnKeyMap);
  });
  return columnKeyMap;
}

function defaultSortKeyColumns(columns, keys = []) {
  columns.map((it) => {
    if (it.key)
      keys.push(it.key);

    if (it.children)
      defaultSortKeyColumns(it.children, keys);
  });

  return keys;
}
// 排除render属性进行比较，解决defaultColumns变动问题
function isEqual(obj1, obj2) {
  // 如果两个对象是同一个引用，直接返回true
  if (obj1 === obj2)
    return true;

  // 如果类型不同，直接返回false
  if (typeof obj1 !== typeof obj2)
    return false; ;

  // 处理基本类型比较
  if (typeof obj1 !== 'object')
    return obj1 === obj2;

  // null的情况
  if (obj1 === null && obj2 === null)
    return true;
  if (obj1 === null || obj2 === null)
    return false; ;

  // 数组和对象的处理
  const keys1 = Object.keys(obj1).filter(key => !(key === 'render' || key === 'name' || key === 'title'));
  const keys2 = Object.keys(obj2).filter(key => !(key === 'render' || key === 'name' || key === 'title'));

  // 比较键的数量是否相同
  if (keys1.length !== keys2.length)
    return false; ;

  // 遍历所有键，递归比较每个键对应的值
  for (const key of keys1) {
    if (!isEqual(obj1[key], obj2[key]))
      return false; ;
  }

  return true;
}

function ColumnSetting<T>(props: ColumnSettingProps<T>) {
  const { checkedReset = true } = props;
  const [columnsMap, setColumnsMap] = useState<any>({});
  const [localColumns, setLocalColumns] = useState<any>([]);
  const [initColumns, setInitColumns] = useState<any>([]);// 排除render与name后的初始化columns,只有其他项发生改变才更新
  const [sortKeyColumns, setSortKeyColumns] = useState([]);

  useEffect(() => {
    if (!isEqual(props.defaultColumns, initColumns)) {
      setInitColumns(props.defaultColumns);
      getDefaultSetting(props.defaultColumns);
    }
    else {
      handleColumnChange(props.defaultColumns, false);
      const init = setRender(initColumns, props.defaultColumns);
      setInitColumns(init);
    }
  }, [props.defaultColumns]);

  const setRender = (list, columns) => {
    list.forEach((it, index) => {
      const defaultColumn = columns.find(col => col.key === it.key);
      if (defaultColumn) {
        if (defaultColumn.render)
          it.render = defaultColumn.render;

        if (it.children)
          setRender(it.children, defaultColumn.children);
      }
    });
    return list;
  };

  function customDeepClone(item) {
    if (item === null || typeof item !== 'object')
      return item;

    if (Array.isArray(item))
      return item.map(customDeepClone);

    const newItem = {};
    Object.keys(item).forEach((key) => {
      const value = item[key];
      // 检查值是否为 React 组件或元素
      if (typeof value === 'function' && value.prototype && value.prototype.isReactComponent) {
        // 类组件
        newItem[key] = value;
      }
      else if (React.isValidElement(value) || (typeof value === 'function' && !value.prototype)) {
        // 函数组件或其他 React 元素
        newItem[key] = value;
      }
      else {
        // 深拷贝其他类型
        newItem[key] = customDeepClone(value);
      }
    });
    return newItem;
  }

  const handleColumnChange = (columns, changeLocal) => {
    const fixedColumns = customDeepClone(columns);

    function setAttr(list) {
      list.map((it) => {
        it.fixed = columnsMap[it.key]?.fixed;
        it.show = columnsMap[it.key]?.show;
        if (it.children)
          setAttr(it.children);
      });
    }
    setAttr(fixedColumns);
    // 排序
    const sortedColumns = sortByFixedAndSortKeyColumns(fixedColumns, sortKeyColumns);
    const visibleColumns = filterHiddenLeaves(customDeepClone(sortedColumns));
    changeLocal && setLocalColumns(sortedColumns);
    props.setColumns(visibleColumns);
  };

  useEffect(() => {
    handleColumnChange(initColumns, true);
  }, [sortKeyColumns, columnsMap]);

  /**
   * 设置全部选中，或全部未选中
   *
   * @param show
   */
  const setAllSelectAction = useRefFunction((show: boolean = true) => {
    const columnKeyMap = {} as Record<string, any>;
    const loopColumns = (columns: any) => {
      columns.forEach(({ key, fixed, index, children, disable }: any) => {
        const columnKey = genColumnKey(key, index);
        if (columnKey) {
          columnKeyMap[columnKey] = {
            // 子节点 disable 时，不修改节点显示状态
            show: disable ? columnsMap[columnKey]?.show : show,
            fixed,
            disable,
            order: columnsMap[columnKey]?.order,
          };
        }
        if (children)
          loopColumns(children);
      });
    };
    loopColumns(localColumns);
    setColumnsMap(columnKeyMap);
  });

  /** 全选和反选 */
  const checkedAll = useRefFunction((e: CheckboxChangeEvent) => {
    if (e.target.checked)
      setAllSelectAction();
    else
      setAllSelectAction(false);
  });

  const getDefaultSetting = (columns) => {
    setColumnsMap(defaultColumnKeyMap(columns));
    setSortKeyColumns(defaultSortKeyColumns(columns));
    setLocalColumns(columns);
  };

  /** 重置项目 */
  const clearClick = useRefFunction(() => {
    getDefaultSetting(initColumns);
  });

  // 未选中的 key 列表
  const unCheckedKeys = Object.values(columnsMap).filter(
    value => !value || value.show === false,
  );

  // 是否已经选中
  const indeterminate
    = unCheckedKeys.length > 0 && unCheckedKeys.length !== localColumns.length;

  return (
    <Container
      initValue={{
        columnsMap,
        setColumnsMap,
        sortKeyColumns,
        setSortKeyColumns,
      }}
    >
      <Popover
        arrow={false}
        title={(
          <div className="TUI-ColumnSetting">
            {props.checkable === false
              ? (
                <div />
                )
              : (
                <Checkbox
                  indeterminate={indeterminate}
                  checked={
                unCheckedKeys.length === 0
                && unCheckedKeys.length !== localColumns.length
              }
                  onChange={(e) => {
                    checkedAll(e);
                  }}
                >
                  列展示
                </Checkbox>
                )}
            {checkedReset
              ? (
                <a
                  onClick={clearClick}
                  className={`TUI-ColumnSetting-action-rest-button`.trim()}
                >
                  重置
                </a>
                )
              : null}
            {props?.extra
              ? (
                <Space size={12} align="center">
                  {props.extra}
                </Space>
                )
              : null}
          </div>
        )}
        overlayClassName={`TUI-ColumnSetting-overlay`.trim()}
        trigger="click"
        placement="bottomRight"
        content={(
          <GroupCheckboxList
            checkable={props.checkable ?? true}
            draggable={props.draggable ?? true}
            showListItemOption={props.showListItemOption ?? true}
          // className={className}
            localColumns={localColumns}
            listsHeight={props.listsHeight}
          />
        )}
      >
        {props.children || (
          <Tooltip
            title="列设置"
          >
            {props.settingIcon ?? <SettingOutlined style={{ height: 32 }} />}
          </Tooltip>
        )}
      </Popover>
    </Container>
  );
}

export default ColumnSetting;
