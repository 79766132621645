import { useEffect, useState } from 'react';
import { Button, Flex, Form, Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { encrypt } from '../../../utils';
import { getSMSCaptcha, resetSMSPass } from '../service';
import Result from './Result';
import '../LoginFrom/index.less';
import './index.less';

const countDown = 60 * 10;
const Password = Input.Password;

interface ResetPassFromProps {
  ResetService?: Function;
  otherResetPara?: object;
}
function ResetPassFrom({
  ResetService,
  otherResetPara = {},
}: ResetPassFromProps) {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [count, setCount] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false); // 重置密码成功

  let interval;

  const backLogin = (time = 1) => {
    clearInterval(interval);
    // setTimeout(() => {
    navigate && navigate('/login');
    // }, time * 1000);
  };

  /** 开启倒计时 */
  const runGetCaptchaCountDown = () => {
    let cot = countDown || 59;
    setCount(cot);
    interval = setInterval(() => {
      cot -= 1;
      setCount(cot);
      if (cot === 0)
        clearInterval(interval);
    }, 1000);
  };

  const onGetCaptcha = () =>
    new Promise((resolve, reject) => {
      form.validateFields([['phoneNum']]).then(async (values) => {
        setLoadingCode(true);
        if (!values.phoneNum)
          return;

        await getSMSCaptcha({
          phoneNumber: values.phoneNum,
        }).then((res) => {
          if (res.status === 0) {
            message.success('发送验证码成功~');
            runGetCaptchaCountDown();
          }
          else {
            message.error(res.message || '发送验证码失败');
          }
        }).catch((e) => {
          message.error(e.message || '获取验证码失败');
        });
        setLoadingCode(false);
      }).catch((err) => {
        reject();
      });
    });

  const onFinish = (values) => {
    setLoading(true);
    if (ResetService) {
      return ResetService(values).then((r) => {
        setLoading(false);
        if (r.status === 0)
          setIsSuccess(true);

        return r;
      }).catch(e => setLoading(false));
    }
    resetSMSPass({
      newPassword: encrypt(values.sNewUserPwd),
      confirmPassword: encrypt(values.sNewUserPwd),
      phoneNumber: values.phoneNum,
      code: values.verifyCode,
      ...otherResetPara,
    }).then((res) => {
      setLoading(false);
      if (res.status === 0)
        setIsSuccess(true);
        // message.success('重置密码成功，稍后返回登录页', 1)
        // backLogin();
      else
        message.error(res.message || '重置密码失败');
    }).catch((e) => {
      message.error(e.message || '重置密码失败');
      setLoading(false);
    });
  };

  useEffect(() => {
    return () => clearInterval(interval);
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="TUI-resetPassForm"
    >
      <div className={classNames(`TUI-LoginTitle`)}>重置密码</div>
      {isSuccess
        ? <Result toLogin={backLogin} />
        : (
          <>
            <Form.Item
              label="新密码"
              name="sNewUserPwd"
              rules={[({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value)
                    return Promise.reject(new Error('请输入新密码'));

                  if (value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{6,}$/.test(value))
                    return Promise.reject(new Error('密码需包含大小写英文字母、符号和数字，长度不低于6位'));
                  else
                    return Promise.resolve();
                },
              })]}
            >

              <Password style={{ width: '100%', height: '40px' }} placeholder="大小写英文字母、符号和数字，最少6位" />
            </Form.Item>
            <Form.Item
              label="确认新密码"
              name="sAffirmPwd"
              rules={[({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value)
                    return Promise.reject(new Error('请重复新密码'));

                  if (value && value !== getFieldValue('sNewUserPwd'))
                    return Promise.reject(new Error('两次输入的密码不匹配'));
                  else
                    return Promise.resolve();
                },
              })]}
            >

              <Password style={{ width: '100%', height: '40px' }} placeholder="再次输入新密码" />
            </Form.Item>
            <Form.Item
              label="手机号"
              name="phoneNum"
              rules={[({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value)
                    return Promise.reject(new Error('请输入手机号'));

                  const rex = /^1([3-9])\d{9}$/;
                  if (value && !rex.test(value))
                    return Promise.reject(new Error('请输入正确的手机号码'));
                  else
                    return Promise.resolve();
                },
              })]}
            >

              <Input style={{ width: '100%', height: '40px' }} placeholder="创建账号时填写的手机号" />
            </Form.Item>
            <Form.Item
              label="短信验证码"
              name="verifyCode"
              rules={[({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value)
                    return Promise.reject(new Error('请输入短信验证码'));

                  if (value && !/^\d{6}$/.test(value))
                    return Promise.reject(new Error('验证码格式不正确'));
                  else
                    return Promise.resolve();
                },
              })]}
            >
              <Flex align="center" gap="small">
                <Input style={{ width: '100%', height: '40px' }} />
                <Button
                  onClick={onGetCaptcha}
                  disabled={!!count}
                  loading={loadingCode}
                  style={{ height: '40px' }}
                >
                  {count ? `${count} s` : '获取验证码'}
                </Button>
              </Flex>
            </Form.Item>

            <div className={classNames(`TUI-JunmpLink`)}>
              <span
                onClick={() => {
                  console.log('返回登录');
                  backLogin();
                }}
              >
                返回登录
              </span>
            </div>

            <Button
              block
              type="primary"
              htmlType="submit"
              loading={loading}
              size="large"
              onClick={() => { form.submit(); }}
              className={classNames('TUI-LoginBtn')}
            >
              重置密码
            </Button>
          </>
          )}
    </Form>
  );
}

export default ResetPassFrom;
