import React, { useState } from 'react';
import { Button, Modal, Form, Input } from 'antd';
import { encrypt, useThemeHook, useUserDispatch } from '../../../../index';
import { useParams } from 'react-router-dom';
import { bindLogin } from '../../constants';

interface IProps {
  open?: boolean;
  isTenantId?: boolean;
  onCancel?: () => void;
  bindInfo: string;
}

const AccountBindModal: React.FC<IProps> = ({ open, isTenantId, onCancel, bindInfo }) => {
  const [loading, setLoading] = useState(false);
  const { token } = useThemeHook();
  const { colorPrimary } = token;
  const { project, type } = useParams();
  const dispatch = useUserDispatch();

  const onFinish = (values) => {
    const { tenantId } = JSON.parse(localStorage.getItem('TenantSession')) || {};
    const params = {
      mobile: values.mobile,
      code: encrypt(values.code),
      authType: type, //社交登录类型
      username: bindInfo, // 社交登录唯一标识
    };
    bindLogin({ params, dispatch, isTenantId })
  }

  return (
    <Modal
      open={open}
      width={450}
      onOk={() => { }}
      onCancel={() => { onCancel && onCancel() }}
      footer={null}
    >
      <Form onFinish={onFinish} className="TUI-LoginForm" style={{ marginTop: 16 }} layout="vertical">
        <div className="TUI-LoginTitle">账号绑定</div>
        <Form.Item
          name="mobile"
          rules={[{ required: true, message: '账号不能为空' }]}
        >
          <Input
            prefix={<i className="iconfont" style={{ color: colorPrimary }}>&#xe6e0;</i>}
            placeholder="请输入账号"
            style={{ width: '100%', height: '40px' }}
          />
        </Form.Item>
        <Form.Item
          name="code"
          rules={[{ required: true, message: '密码不能为空' }]}
        >
          <Input.Password
            prefix={<i className="iconfont" style={{ color: colorPrimary }}>&#xe722;</i>}
            type="password"
            placeholder="请输入密码"
            style={{ width: '100%', height: '40px' }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            size="large"
            htmlType="submit"
            loading={loading}
            className="TUI-LoginBtn"
          >
            绑定并登录
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AccountBindModal;