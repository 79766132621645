import { useEffect, useRef } from 'react';
import { validateSchema } from '../../lib/validator';
import Form from '../AdvancedCrud/AdvancedForm';
import { addComponent, getFormSchema } from '../AdvancedCrud/schemahelper';
import { addRule } from '../AdvancedCrud/rule';
import type { AdvancedSchema } from '../AdvancedCrud/index.d';

type Layout = (props: object) => Array<Element>;
interface ComposeProps {
  schema: AdvancedSchema;
  Layout: Layout;
  name: string;
};
export default function Compose({ schema, Layout, name, ...rest }: ComposeProps) {
  const Component = ({ value, onChange, opts }) => {
    const ref = useRef();
    const changedFromProps = useRef(false);
    const onStateChange = (state) => {
      if (!state)
        return;
      if (state.fields === value)
        return;
      // setFields 导致state change
      if (changedFromProps.current) {
        changedFromProps.current = false;
        return;
      }
      onChange(state.fields);
    };
    useEffect(() => {
      if (value !== ref.current.fields) {
        changedFromProps.current = true;
        ref.current.setState({
          ...ref.current.state,
          fields: value ? { ...value } : {},
        });
      }
    }, [value]);
    const readonly
      = opts && opts.disabled
        ? typeof opts.disabled === 'function'
          ? opts.disabled(value)
          : !!opts.disabled
        : false;
    return (
      <Form
        defaultValue={undefined}
        schema={schema}
        ref={ref}
        onStateChange={onStateChange}
        Layout={Layout}
        {...rest}
        formMode={readonly ? 'detail' : rest.formMode}
      />
    );
  };
  const Validator = (value) => {
    const sch = getFormSchema(schema, {}, false);
    const errors = validateSchema(sch, value);
    if (errors && Object.keys(errors).length) {
      return Object.values(errors)
        .filter(d => !!d)
        .join(';');
    }
    return true;
  };
  // TODO 保证唯一性，或可多次注册
  addRule(name, Validator);
  addComponent(name, Component);
  return Component;
};
