import { useEffect, useState } from 'react';
import type { ResultProps } from 'antd';
import { Button, Layout, Result, Space } from 'antd';
import TUISkeleton from '../TUISkeleton';

interface TUI404Props {
  resultProps?: ResultProps;
  delay?: number;
}

export default function TUI404({ resultProps, delay = 500 }: TUI404Props) {
  const [showSkeleton, setShowSkeleton] = useState(true);

  // delay避免路由初始化之前的闪烁
  useEffect(() => {
    setTimeout(() => {
      setShowSkeleton(false);
    }, delay);
  }, []);

  return (
    <Layout style={{ height: '100vh' }}>
      {showSkeleton ?
        <TUISkeleton />
        :
        <Result
          status="404"
          title="404"
          subTitle="抱歉，您访问的页面不存在。"
          extra={(
            <Space>
              <Button onClick={() => history.go(-1)}>返回</Button>
              <Button type="primary" onClick={() => location.href = '/'}>首页</Button>
            </Space>
          )}
          {...resultProps}
        />}
    </Layout>
  );
}
