import FormItem from '../FormItem';
import * as TUI from '../../index';
import makeLinelayout from './layout';
import './index.less';

function colVisible(rule, fields) {
  if (typeof rule.visible !== 'function')
    return true;
  return rule.visible({ fields });
}

function lineVisible(line = [], schema, fields) {
  let visibleCount = line.length;
  for (const field of line) {
    const rule = schema[field];
    if (!colVisible(rule, fields))
      visibleCount--;
  }
  return !!visibleCount;
}

function lineError(line, errors) {
  const errorTip = line.reduce((p, c) => p || errors[c], '');
  return errorTip;
}
function lineStatus(line, errors, schema) {
  for (const c of line) {
    if ('validateStatus' in schema[c])
      return schema[c].validateStatus;
  }
  const e = line.reduce((p, c) => p || errors[c], '');
  return e ? 'error' : '';
}

function lineHelp(line, schema) {
  const helpTip = line.reduce((p, c) => p || schema[c].help, '');
  return helpTip;
}

function colRequired(rule) {
  return (rule.rules || []).reduce((p, c) => p || !!c.required, false);
}
function lineRequired(line, schema) {
  return line.reduce((p, c) => p || colRequired(schema[c]), false);
}

function firstField(line) {
  const [firstField] = line;
  return firstField;
}
function lineName(line, schema) {
  const first = firstField(line);
  return schema[first].name;
}
const DefaultLayout = ({ children }) => children;
const DefaultInputArr = ['Input', 'InputNumber', 'AutoComplete', 'TextArea', 'TUIInputReminder'];
export default function Form({
  fields = {},
  onFieldChange,
  onChange,
  schema,
  errors = {},
  children,
  Layout = DefaultLayout,
  hideLabel,
  className,
  defaultValue = {},
}) {
  const schemaLayout = makeLinelayout(schema);
  return (
    <div className={['TUI-Form', className].filter(d => !!d).join(' ')}>
      <Layout>
        {schemaLayout.map((line) => {
          if (!lineVisible(line, schema, fields))
            return null;
          const label = hideLabel ? null : lineName(line, schema);
          return (
            <FormItem
              key={`line-${firstField(line)}`}
              label={label}
              validateStatus={lineStatus(line, errors, schema)}
              help={
                (lineStatus(line, errors, schema) === 'error' ? lineError(line, errors) : '')
                || lineHelp(line, schema)
              }
              required={lineRequired(line, schema)}
            >
              {line.map((field) => {
                const rule = schema[field];
                const ItemType = typeof rule.type === 'string' ? TUI[rule.type] : rule.type;
                if (!colVisible(rule, fields))
                  return null;
                let disabled = false;
                if (rule.opts && rule.opts.disabled) {
                  const { disabled: temp } = rule.opts;
                  disabled = typeof temp === 'function' ? temp(fields) : temp;
                }
                const placeholder = typeof rule.type === 'string' ? `请${DefaultInputArr.includes(rule.type) ? '输入' : '选择'}${rule.name}` : undefined;
                return (
                  <ItemType
                    id={`${field}_Id`}
                    key={field}
                    name={field}
                    label={label}
                    value={fields[field]}
                    placeholder={rule.placeholder || placeholder}
                    fields={fields}
                    options={
                      rule.optionCompute ? rule.optionCompute(fields, rule.options) : rule.options
                    }
                    onChange={(value) => {
                      if (rule.opts && rule.opts.handleChange)
                        rule.opts.handleChange(value, onChange, fields, rule.options);
                      else
                        onFieldChange(field, value);
                    }}
                    opts={{ ...rule.opts, disabled }}
                  />
                );
              })}
            </FormItem>
          );
        })}
        {children}
      </Layout>
    </div>
  );
}
