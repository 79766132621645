export async function serialRun(arr) {
  const result = [];
  for (const a of arr) {
    const t = await a;
    result.push(t);
  }
  return result;
}
export { default as cloneNode } from './cloneNode';
export { default as copy } from './copy';
export { default as download, downloadReport } from './download';
export { default as getDownloadUrl } from './getDownloadUrl';
export { default as getKeyValueMapOfProps } from './getKeyValueMapOfProps';
export { default as getNameFromUrl } from './getNameFromUrl';
export { default as loadScript } from './loadScript';
export { default as setPropsByKeyPath } from './setPropsByKeyPath';
export { default as uuid, guid } from './uuid';
export { default as getUrlParams } from './getUrlParams';
export { default as ValidateRules, DB_KEYWORDS_ARR, validateCascaderKey } from './validator';
export { default as getLocalUserPermissions } from './getLocalUserPermissions';
export { findBreadcrumbByUrl, checkIsShowPageTitle, getFilterMenusByAuth } from './layoutLibs';
export * as Utils from './utils';

/**
 * 密码加密
 * @param word
 * @returns
 */
export function encrypt(word) {
  const CryptoJS = window.CryptoJS;
  const key = CryptoJS.enc.Utf8.parse('3afctxu9sda6x2rh'); // 十六位十六进制数作为密钥
  const iv = CryptoJS.enc.Utf8.parse('1234567812345678'); // 十六位十六进制数作为密钥偏移量
  const encrypted = CryptoJS.AES.encrypt(word, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

export function checkUpdate() {
  const getManifestUrl = () => fetch('/.vite/manifest.json').then(res => res.json());
  // 进页面写最新 entryfile
  getManifestUrl().then((manifest) => {
    const key = '__tl_entryfile__';
    const newEntryFile = manifest['index.html'].file;
    localStorage.setItem(key, newEntryFile);
  });
  // 标记是否处于 confirm 状态
  let confirming = false;
  const flag = setInterval(() => {
    if (confirming)
      return;
    getManifestUrl().then((manifest) => {
      const key = '__tl_entryfile__';
      const oldEntryFile = localStorage.getItem(key);
      const newEntryFile = manifest['index.html'].file;
      if (oldEntryFile === newEntryFile) {
        // 未更新
        return;
      }
      confirming = true;
      // eslint-disable-next-line no-alert
      if (confirm('系统有更新，是否刷新？')) {
        location.reload();
        return;
      }
      confirming = false;
      clearInterval(flag);
    });
  }, 1000 * 60 * 5);
}
