import { useRef, useState } from 'react';
import { message as AntMessage, Modal, Tooltip } from 'antd';
import { renderDropdown } from '../index';

// ShowNoEmpty true 默认为 '' 都显示 --
export default function useListController({
  schema,
  doList = () => Promise.resolve(),
  query = {},
  defaultValue,
  doHandleItemById = () => { },
  AntApp = {},
  listProps,
}) {
  const { modal: AppModal, message: AppMessage } = AntApp || {};
  const confirm = AppModal && AppModal.confirm ? AppModal.confirm : Modal.confirm;
  const message = AppMessage || AntMessage;
  const { pagination = {}, ShowNoEmpty = false } = listProps || {};
  const { defaultPageSize = 10 } = pagination || {};
  const handler = useRef({});
  const [state, setState] = useState({
    data: [],
    pagination: { currentIndex: 1, pageCount: defaultPageSize, pageSize: defaultPageSize },
    filters: {},
    sorters: {},
    schema,
    loading: false,
    selected: [],
    selectedKeys: [],
  });

  const { pagination: listPagination = {} } = state;

  const createColumn = ({ filter, modal, list, form, ...restProps }) => {
    const cols = [];
    for (const field in schema) {
      const item = schema[field];
      const col = {
        title: item.name,
        key: field,
        // sorter: !!item.sorter,
        // filters: item.filters,
        sorterKey: field,
        dataIndex: field,
        ...item,
        ...(item.filterOpts
          ? {
              ...item.filterOpts,
              filteredValue: JSON.stringify(state.filters) === '{}' ? null : state.filters[field],
            }
          : {}),
        ...(item.sorterOpts
          ? {
              ...item.sorterOpts,
              sorter: true,
              sortOrder: JSON.stringify(state.sorters) === '{}' ? false : (state.sorters.columnKey && state.sorters.columnKey === field) ? state.sorters.order : false,
            }
          : {}),
      };

      if (typeof item.render === 'function') {
        if (field === 'id') { // 操作列
          col.render = (value, record, index) => {
            const tempT = item.render({
              value,
              record,
              index,
              filter,
              modal,
              form,
              list,
              ...restProps,
            }).filter(it => !!it);
            const temp = ShowNoEmpty ? tempT || '--' : tempT;
            const finalActions = temp.length > 3 ? [...temp.slice(0, 2), temp.slice(2).length ? renderDropdown({ oprateMenu: temp.slice(2), text: '更多' }) : null] : temp;
            return finalActions.filter(it => !!it);
          };
        }
        else {
          col.render = (value, record, index) => {
            const tempT = item.render({
              value,
              record,
              index,
              filter,
              modal,
              form,
              list,
              ...restProps,
            });
            const temp = ShowNoEmpty ? tempT || '--' : tempT;
            return item.maxLength
              && (typeof temp === 'string' && temp.length > item.maxLength)
              ? (
                <Tooltip title={temp} placement="bottomLeft">
                  <span
                    style={{
                      display: 'inline-block',
                      width: `${item.maxLength}em`,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {temp}
                  </span>
                </Tooltip>
                )
              : (
                  temp
                );
          };
        }
      }
      else {
        col.render = (vT) => {
          const v = ShowNoEmpty ? vT || '--' : vT;
          return item.maxLength
            && (typeof v === 'string' && v.length > item.maxLength)
            ? (
              <Tooltip title={v} placement="bottomLeft">
                <span
                  style={{
                    display: 'inline-block',
                    width: `${item.maxLength}em`,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {v}
                </span>
              </Tooltip>
              )
            : (
                v
              );
        };
      }

      if (typeof item.visible === 'function') {
        const render = item.render;
        item.render = (value, record, index) => {
          item.visible({
            value,
            record,
            index,
            filter,
            modal,
            form,
            list,
            ...restProps,
          })
            ? render(value, record, index)
            : '';
        };
      }

      if ('fixed' in item)
        col.fixed = item.fixed;

      cols.push(col);
    }

    return cols;
  };

  const setSelected = (selectedKeys, selected) => {
    setState({
      ...state,
      selectedKeys,
      selected,
    });
  };

  const onChange = (pagination, filters, sorters) => {
    doFetch(query, pagination, filters, sorters);
  };

  const doFetch = (query = {}, pagination = listPagination, filters = {}, sorters = {}) => {
    setState({
      ...state,
      loading: true,
    });
    return doList({
      query: { ...defaultValue, ...query },
      pagination,
      filters,
      sorters,
    })
      .then((res) => {
        setState({
          ...state,
          loading: false,
          query: { ...defaultValue, ...query },
          data: res ? (res.data ? res.data : []) : [],
          pagination: res.pagination || {},
          filters,
          sorters,
        });
      })
      .catch((e) => {
        setState({
          ...state,
          filters,
          sorters,
          loading: false,
        });
        message.error(e.message);
      });
  };

  const toggleLoading = () => {
    setState({
      ...state,
      loading: !state.loading,
    });
  };

  const handleItemById = (record, reminder, callback) => {
    confirm({
      title: '提示',
      content: reminder || '请确认是否继续进行删除操作' + '?',
      okText: '确定',
      // okType: 'danger',
      cancelText: '取消',
      onOk() {
        setState({ ...state, loading: true });
        doHandleItemById(record)
          .then((res) => {
            setState({ ...state, loading: false });
            message.success('操作成功');
            doFetch(
              state.query,
              state.pagination,
              state.filters,
              state.sorters,
            );
            typeof callback === 'function' && callback();
          })
          .catch((e) => {
            setState({ ...state, loading: false });
          });
      },
    });
  };
  return Object.assign(handler.current, {
    ...state,
    setState,
    createColumn,
    setSelected,
    onChange,
    handleItemById,
    doFetch,
    toggleLoading,
  });
}
