import { forwardRef, useEffect } from 'react';
import { Checkbox, Empty, Flex, Pagination, Typography } from 'antd';
import ClassNames from 'classnames';
import TUICardGrid from '../TUICardGrid';
import renderDropdown from '../TUICardGrid/renderDropdown';
import usePaginationHooks from '../../hooks/usePaginationHooks';
import { TableCardIconColor } from '../../common';
import { TUIIconPark } from '../../index';
import './index.less';

const { Text } = Typography;
function flatColumns(columns, arr) {
  columns.map((it) => {
    if (it.children)
      flatColumns(it.children, arr);
    else
      arr.push(it);
  });
  return arr;
}
/**
 *
 * isCardTitle： 标题
 * isCardSubTitle： 副标题
isTagContent: 展示在中间区域的tag
isSourceContent： 左下角的来源
isStatusContent：右下角的状态
isNotShow：不展示在卡片上
 */
function TableCardT(props: any, ref) {
  const { columns = [], rowKey = 'id', form, modal, setFormMode, query, rowSelection, cardIcon = 'folder-close-dmbcdd9d', notShowIcon, turnIcon, size } = props;

  const {
    onChange = (selectedKeys, selectedRows) => { },
    selectedRowKeys = [],
    getCheckboxProps = record => ({}),
  } = rowSelection || {};

  const { data = [], loading, pagination, cardSelectedTitle, onCardItemSelected = () => { } } = props;
  const { defaultPageSize = 10, showSizeChanger = true } = pagination || {};
  // 标题列
  const { key, render: renderList } = columns.find(it => !!it.isCardTitle) || columns.find(it => it.fixed === 'left') || (columns.length ? columns[0] : {});

  // 副标题列
  const { key: subTitleKey, render: subTitleRender, maxLength: subTitleMaxLength } = columns.find(it => !!it.isCardSubTitle) || {};

  const flat = flatColumns(columns, []);
  const filted = flat.filter(it => it.fixed === 'left');

  // 展示在中间区域的tag
  const tagContent = flat.filter(it => !!it.isTagContent);

  // 如果有固定列，则取固定列的第2个与第三个，第一个作为标题用
  const listColumn = filted.length > 1 ? filted.slice(1, 3) : flat.slice(1, 3);

  // 展示在坐下角的卡片数据来源：例如： 来自腾路智行
  const sourceContent = flat.filter(it => !!it.isSourceContent) || [];
  const [firstScource = {}] = sourceContent || [];
  // 展示在右下角的卡片状态
  const statusContent = flat.filter(it => !!it.isStatusContent) || [];
  const [firstStatus = {}] = statusContent || [];

  const {
    total,
    current,
    pageSize,
    onShowSizeChange,
    changePagenation,
  } = usePaginationHooks({ defaultPageSize });

  useEffect(() => {
    changePagenation({
      ...pagination,
    });
  }, [pagination]);

  const renderCard = (record, index) => {
    const { render = () => { } } = columns.find(it => it.key === 'id') || {};
    const params = { ...record, form, modal, setFormMode, query };
    const actions = (render('', params, index) || []).filter(it => !!it);

    const finalActions = actions.length > 3 ? [...actions.slice(0, 2), actions.slice(2).length ? renderDropdown({ oprateMenu: actions.slice(2), text: '更多' }) : null].filter(it => !!it) : actions;
    const firstActions = finalActions.length ? finalActions[0] : '';
    return {
      loading,
      actions: finalActions,
      cover: typeof record.cardCover === 'string' ? <img src={record.cardCover} onClick={() => firstActions && firstActions.props.onClick(record)} /> : record.cardCover,
    };
  };

  const renderContent = (record, index, ...rest) => {
    const params = { ...record, form, modal, setFormMode, query };

    const { actions: finalActions } = renderCard(record, index);
    const firstActions = finalActions.length ? finalActions[0] : '';

    const selectkey = typeof (rowKey) == 'function' ? rowKey(record, index) : record[rowKey];
    const onCardSelected = (e) => {
      e.stopPropagation();
      const checked = e.target.checked;
      const t = selectedRowKeys ? [...selectedRowKeys] : [];
      if (t.includes(selectkey) && !checked) {
        t.splice(t.findIndex(item => item === selectkey), 1);
        onChange(t, index);
      }
      else if (checked) {
        t.push(selectkey);
        onChange(t, index);
      }
    };

    const Title = renderList ? renderList(record[key], params, index) : record[key] || '请设置卡片标题';
    // record.maxLength 如果存在会出现双重tooltips
    const subTitle = subTitleRender && !subTitleMaxLength ? subTitleRender(record[subTitleKey], params, index) : record[subTitleKey] || '--';

    const firstScourceTextT = firstScource.render ? firstScource.render(record[firstScource.key], params, index) : '';
    const firstScourceText = firstScourceTextT && firstScourceTextT === '--' ? '' : firstScourceTextT;

    return (
      <div
        key={index}
        className="TUI-TableCard"
      >
        {rowSelection ? <Checkbox className="cardCheck" checked={selectedRowKeys.includes(selectkey)} onChange={onCardSelected}></Checkbox> : null}
        <div
          onClick={(e) => {
          // if (e.target === e.currentTarget) // 列表非操作列 点击 会触发
            firstActions && firstActions.props.onClick(record);
          }}
        >
          <Flex align="center" className="header">
            {notShowIcon
              ? null
              : (
                <div className="listBadge" style={turnIcon ? { backgroundColor: TableCardIconColor[index % 6] } : {}}>
                  <TUIIconPark type={record.cardIconType || cardIcon} />
                </div>
                )}

            <Flex vertical className="headerContent">
              {key !== 'id' ? <Text ellipsis={{ tooltip: Title }} className={ClassNames('listItemTitle', notShowIcon)}>{Title}</Text> : null}
              {subTitleKey !== 'id' ? <Text ellipsis={{ tooltip: subTitle }} className={ClassNames('listItemSubTitle', notShowIcon)}>{subTitle}</Text> : null}
            </Flex>
          </Flex>
          {
          Array.isArray(tagContent) && tagContent.length
            ? (
              <Flex className="body">
                {tagContent.filter(it => it.key !== 'id' && !it.isNotShow).map((it, index) => {
                  const tagTextT = it.render ? it.render(record[it.key], params, index) : record[it.key];
                  const tagText = tagTextT && tagTextT === '--' && index !== 0 ? '' : tagTextT;
                  return (
                    <Flex align="center" className="listItem2" key={it.key}>
                      <span>{tagText}</span>
                    </Flex>
                  );
                })}
              </Flex>
              )
            : (
              <Flex vertical className="body">
                {listColumn.filter(it => it.key !== 'id' && !it.isCardTitle && !it.isCardSubTitle && !it.isNotShow).map((it) => {
                  return (
                    <Flex align="center" className="listItem2" key={it.key}>
                      <span className="listItemContent">{it.render ? it.render(record[it.key], params, index) : record[it.key]}</span>
                    </Flex>
                  );
                })}
              </Flex>
              )
        }
        </div>
        {firstScource.render || firstStatus.render
          ? (
            <Flex horizontal justify="space-between" className="firstStatusFlex">
              <span className="cardScource">{firstScourceText}</span>
              <span className="cardStatus">{firstStatus.render ? firstStatus.render(record[firstStatus.key], params, index) : ''}</span>
            </Flex>
            )
          : null}
      </div>
    );
  };

  const selectedAll = data?.length > 0 && data?.every((it, index) => selectedRowKeys.includes(typeof (rowKey) == 'function' ? rowKey(it, index) : it[rowKey]));
  const indeterminate = data?.some((it, index) => selectedRowKeys.includes(typeof (rowKey) == 'function' ? rowKey(it, index) : it[rowKey]));

  const onSelectAll = (e) => {
    const checked = e.target.checked;
    if (checked) {
      const a = [];
      data.map((it, index) => {
        const v = typeof (rowKey) == 'function' ? rowKey(it, index) : it[rowKey];
        a.push(v);
      });
      onChange(a);
    }
    else {
      onChange([]);
    }
  };

  return (
    <div className="TUI-TableCard-Wrapper">
      {rowSelection && data?.length > 0 ? <Checkbox className="AllCheck" checked={selectedAll} indeterminate={!selectedAll && indeterminate} onChange={onSelectAll}>全选</Checkbox> : null}
      {
        data.length
          ? (
            <TUICardGrid
              cardData={data}
              renderCardProps={renderCard}
              renderChildren={renderContent}
              size={size}
              cardSelectedTitle={cardSelectedTitle}
              onItemSelect={onCardItemSelected}
            />
            )
          : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
      <Pagination
        style={{ textAlign: 'right', marginTop: `calc(var(--diff-margin, 0px) + 24px)` }}
        showSizeChanger={showSizeChanger}
        showQuickJumper
        total={total}
        current={current}
        pageSize={pageSize}
        onChange={(current, pageSize) => props.onChange({ ...pagination, current, pageSize })}
        onShowSizeChange={onShowSizeChange}
        showTotal={total => `共${total}条数据`}
      />
    </div>
  );
};

const TableCard = forwardRef<HTMLUListElement>(TableCardT);
export default TableCard;
