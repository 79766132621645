import { InputNumber as AntInputNumber, ConfigProvider } from 'antd';

export default function InputNumber({
  id,
  value,
  onChange = () => {},
  placeholder = '请输入',
  tokenProps = {},
  opts = {},
}) {
  return (
    <ConfigProvider
      theme={{
        token: {
          ...tokenProps,
        },
      }}
    >
      <AntInputNumber
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...opts}
      />
    </ConfigProvider>
  );
}
