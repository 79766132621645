import { Input as AntInput } from 'antd';

export default function Input({ id, value, onChange, placeholder = '请输入', opts = {} }) {
  return (
    <AntInput
      id={id}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      placeholder={placeholder}
      {...opts}
    />
  );
}
