import React, { Children, forwardRef, useImperativeHandle } from 'react';
import ClassNames from 'classnames';
import { Form, TUIAddModal, objectValueAllEmpty, useFormController, useThemeContext } from '../../index';
import ModalDefault from '../Modal';
import DrawerDefault from '../Drawer';
import './index.less';

interface DrawerProps {
  title: string;
  visible: boolean;
  hideLabel?: boolean;
  okText?: string;
  cancelText?: string;
  children?: any;
  schema?: any;
  onOk?: Function;
  onCancel?: Function;
  /**
   * 等同 onCancel
   */
  onClose?: Function;
  className?: string | object;
  width?: number;
  defaultModalMode?: 'drawer' | 'modal';
  formMode?: 'form' | 'AdvanceForm';
  formProps?: Record<PropertyKey, any>;
  modalProps?: Record<PropertyKey, any>;
  FormControllerProps?: Record<PropertyKey, any>;
  errorMsg?: string;
  FotterButtons?: React.ReactNode;
}
const TUIDrawer = forwardRef((props: DrawerProps, ref) => {
  const {
    title,
    visible,
    hideLabel = false,
    okText = '提交',
    cancelText = '取消',
    children,
    schema,
    onOk,
    onCancel,
    onClose: _onClose,
    className = '',
    width: defaultWidth,
    defaultModalMode,
    formMode = 'form',
    errorMsg,
    formProps = {},
    modalProps = {},
    FormControllerProps = {},
    FotterButtons,
  } = props || {};
  const { modalMode: modalModeTheme = 'drawer' } = useThemeContext();

  const modalMode = defaultModalMode || modalModeTheme;

  const form = useFormController({
    schema,
    ...FormControllerProps,
  });

  useImperativeHandle(ref, () => ({
    validate: (e) => {
      const val = form.validate();
      const empty = objectValueAllEmpty(val);
      if (empty)
        return true;
      else
        return false;
    },
    getData: () => form.fields,
    getForm: () => form,
    resetForm: () => form.reset(),
  }));

  const getSlot = (key) => {
    return Children.map(props.children, (child, i) => {
      return child && child.key === key ? child : null;
    });
  };

  const onClose = () => {
    schema && formMode === 'form' && form.reset();
    const fn = onCancel || _onClose;
    fn && fn();
  };

  return (
    <TUIAddModal
      ref={ref}
      modalMode={modalMode}
      Modal={modalMode === 'drawer' ? DrawerDefault : ModalDefault}
      open={visible}
      onOk={onOk}
      onCancel={onClose}
      onClose={onClose}
      width={defaultWidth}
      title={title}
      errorMsg={errorMsg}
      modalProps={{
        okText,
        cancelText,
        className,
        FotterButtons,
        ...modalProps,
      }}
    >
      <div className={ClassNames('TUI-AddForm-Container', 'TUI-Drawer-Container')}>
        {getSlot('CRUD-FORM-TOP')}
        {children}
        {schema && formMode === 'form'
          ? (
            <div className={`TUI_FormWrap ${hideLabel ? 'TUI_FormWrap_hideLabel' : ''}`}>
              <Form
                schema={schema}
                defaultValue={form.defaultValue}
                fields={form.fields}
                errors={form.errors}
                onFieldChange={form.setField}
                onChange={form.setFields}
                {...formProps}
              />
            </div>
            )
          : (
              null
            )}
        {getSlot('CRUD-FORM-BOTTOM')}
      </div>
    </TUIAddModal>
  );
});

export default TUIDrawer;
