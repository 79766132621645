/** 默认主题配置 */
export const DefalutTokenProps = {
  colorBgContainer: '#fff', // 组件的容器背景色，例如：默认按钮、输入框等。务必不要将其与 `colorBgElevated` 混淆。 string #ffffff
  colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)', // 控制容器在禁用状态下的背景色。 string
  colorBgTextActive: 'rgba(0, 0, 0, 0.15)', // 控制文本在激活状态下的背景色。 string rgba(0, 0, 0, 0.15)
  colorBgTextHover: 'rgba(0, 0, 0, 0.06)', //  控制文本在悬停状态下的背景色。 string rgba(0, 0, 0, 0.06)
  colorBorder: '#d9d9d9', //  默认使用的边框颜色, 用于分割不同的元素，例如：表单的分割线、卡片的分割线等。 string #d9d9d9
  colorError: '#ff4d4f', //  用于表示操作失败的 Token 序列，如失败按钮、错误状态提示（Result）组件等。 string #ff4d4f
  colorErrorActive: '#d9363e', //  错误色的深色激活态 string #d9363e
  colorErrorBg: '#fff2f0', //  错误色的浅色背景颜色 string #fff2f0
  colorErrorBorderHover: '#ffa39e', //  错误色的描边色悬浮态 string #ffa39e
  colorErrorHover: '#ff7875', //  错误色的深色悬浮态 string #ff7875
  colorErrorOutline: 'rgba(255, 38, 5, 0.06)', //  控制输入组件错误状态下的外轮廓线颜色。 string rgba(255, 38, 5, 0.06)
  colorLink: '#1677ff', //  控制超链接的颜色。 string #1677ff
  colorLinkActive: '#0958d9', //  控制超链接被点击时的颜色。 string #0958d9
  colorLinkHover: '#69b1ff', //  控制超链接悬浮时的颜色。 string #69b1ff
  colorPrimary: '#1677ff', //  品牌色是体现产品特性和传播理念最直观的视觉元素之一。在你完成品牌主色的选取之后，我们会自动帮你生成一套完整的色板，并赋予它们有效的设计语义 string #1677ff
  colorPrimaryActive: '#0958d9', //  主色梯度下的深色激活态。 string #0958d9
  colorPrimaryBorder: '#91caff', //  主色梯度下的描边用色，用在 Slider 等组件的描边上。 string #91caff
  colorPrimaryHover: '#4096ff', //  主色梯度下的悬浮态。 string #4096ff
  colorText: 'rgba(0, 0, 0, 0.88)', //  最深的文本色。为了符合 W3C 标准，默认的文本颜色使用了该色，同时这个颜色也是最深的中性色。 string rgba(0, 0, 0, 0.88)
  colorTextDisabled: 'rgba(0, 0, 0, 0.25)', //  控制禁用状态下的字体颜色。 string rgba(0, 0, 0, 0.25)
  colorTextDescription: 'rgba(0, 0, 0, 0.45)', //	控制文本描述字体颜色。	string
  colorTextLightSolid: '#fff', //  控制带背景色的文本，例如 Primary Button 组件中的文本高亮颜色。 string #fff
  borderRadius: 6, //  基础组件的圆角大小，例如按钮、输入框、卡片等 number 6
  borderRadiusLG: 8, //  LG 号圆角，用于组件中的一些大圆角，如 Card、Modal 等一些组件样式。 number 8
  borderRadiusSM: 4, //  SM 号圆角，用于组件小尺寸下的圆角，如 Button、Input、Select 等输入类控件在 small size 下的圆角 number 4
  controlHeight: 32, //  Ant Design 中按钮和输入框等基础控件的高度 number 32
  controlHeightLG: 40, //  较高的组件高度 number 40
  controlHeightSM: 24, //  较小的组件高度 number 24
  controlOutline: 'rgba(5, 145, 255, 0.1)', //  控制输入组件的外轮廓线颜色。 string rgba(5, 145, 255, 0.1)
  controlOutlineWidth: 2, //  控制输入组件的外轮廓线宽度。 number 2
  fontSize: 14, //  设计系统中使用最广泛的字体大小，文本梯度也将基于该字号进行派生。 number 14
  fontSizeLG: 16, //  大号字体大小 number 16
  lineHeight: 1.5714285714285714, //  文本行高 number 1.5714285714285714
  lineType: 'solid', //  用于控制组件边框、分割线等的样式，默认是实线 string solid
  lineWidth: 1, //  用于控制组件边框、分割线等的宽度 number 1
  lineWidthFocus: 4, //  控制线条的宽度，当组件处于聚焦态时。 number 4
  marginXS: 8, //  控制元素外边距，小尺寸。 number 8
  motionDurationMid: '0.2s', //  动效播放速度，中速。用于中型元素动画交互 string 0.2s
  motionDurationSlow: '0.3s', //  动效播放速度，慢速。用于大型元素如面板动画交互 string 0.3s
  motionEaseInOut: 'cubic-bezier(0.645, 0.045, 0.355, 1)', //  预设动效曲率 string cubic-bezier(0.645, 0.045, 0.355, 1)
  opacityLoading: 0.65, //  控制加载状态的透明度。 number 0.65
  paddingContentHorizontal: 16, //  控制内容元素水平内间距。 number 16
  paddingXS: 8, //  控制元素的特小内间距。

};
