import { forwardRef, useImperativeHandle, useState } from 'react';
import { Alert, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Form, InputPassword, TUIDrawer, encrypt, useAppConfigContext, useFormController } from '../../../../index';
import { submitUserPassword } from './service';

const formschema = {
  origin_password: {
    type: InputPassword,
    name: '原密码',
    rules: [
      {
        required: true,
      },
    ],
    opts: {},
  },
  new_password: {
    type: InputPassword,
    name: '新密码',
    placeholder: '大小写英文字母、符号和数字，长度不低于6位',
    rules: [
      {
        required: true,
      },
      {
        validator: (value) => {
          if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{6,}$/.test(value))
            return '密码需包含大小写英文字母、符号和数字，长度不低于6位';

          return true;
        },
      },
    ],
    opts: {},
  },
  new_password_confirm: {
    type: InputPassword,
    name: '确认密码',
    placeholder: '密码需包含大小写英文字母、符号和数字，长度不低于6位',
    rules: [
      {
        required: true,
      },
      {
        validator: (value, fields) => {
          if (value !== fields.new_password)
            return '两次输入密码不一致';
          return true;
        },
      },
    ],
    opts: {},
  },
};

function ModifyPasswordT(props, ref) {
  const navigate = useNavigate();
  const { modifyPasswordProps = {} } = useAppConfigContext();
  const { api = '/saas/user/changePassword', passwordService, callBack } = modifyPasswordProps;

  const [visible, setVisible] = useState(false);

  const form = useFormController({
    schema: formschema,
    doSubmit: fields => passwordService
      ? passwordService(fields)
      : submitUserPassword(api, {
        password: encrypt(fields.origin_password),
        newPassword: encrypt(fields.new_password),
        confirmPassword: encrypt(fields.new_password),
      }),
  });

  const onCancel = () => {
    form.reset();
    setVisible(false);
  };

  const onOk = async () => {
    await form.submit().then((r) => {
      if (r && r.status === 0) {
        onCancel();
        message.success('修改成功，请重新登录');
        if (callBack) {
          callBack();
          return;
        }
        const userFormUrl = localStorage.getItem('formUrl');
        window.location.href = userFormUrl ? `${userFormUrl}/login` : `/login?prePath=${encodeURIComponent(window.location.href)}`;
      }
    });
  };

  useImperativeHandle(ref, () => ({
    handle: () => {
      setVisible(true);
      form.reset();
    },
  }));

  return (
    <TUIDrawer
      visible={visible}
      title="修改密码"
      onCancel={onCancel}
      onOk={onOk}
    >
      <>
        <div style={{ marginBottom: '16px' }}>
          <Alert message="提交后，所有子系统的登录密码均同步修改~" type="info" />
        </div>
        <Form
          schema={formschema}
          fields={form.fields}
          errors={form.errors}
          onFieldChange={form.setField}
          onChange={form.setFields}
        />
      </>
    </TUIDrawer>
  );
};

const ModifyPassword = forwardRef(ModifyPasswordT);

export default ModifyPassword;
