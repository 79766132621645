import { useThemeHook } from '../index';

export function globalCommonStyle() {
  const { token } = useThemeHook();
  return {
    '--colorPrimary': token.colorPrimary,
    '--colorPrimaryBg': token.colorPrimaryBg,
    '--hover-colorPrimary': token.colorPrimaryBgHover,
    '--fontSize': `${token.fontSize}px`,
    '--diff-fontSize': `${token.fontSize - 14}px`,
    '--diff-border-radius': `${token.borderRadius - 6}px`,
    '--diff-padding': `${token.padding - 16}px`,
    '--diff-margin': `${token.margin - 16}px`,
  };
}
