/* eslint-disable react-refresh/only-export-components */
import type { Dispatch } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { merge } from 'lodash-es';
import { type DispatchType, getReducer } from '../types';

/**
 * 应用配置上下文类型
 */
export interface AppConfigContextType {
  /**
   * 系统名称
   */
  systemName?: string;
  /**
   * layout的logo
   */
  headLogo?: string;
  /**
   * layout收缩的logo
   */
  headShinkLogo?: string;
  appBoxProps?: Record<PropertyKey, any>;
  jumpSystemProps?: Record<PropertyKey, any>;
  modifyPasswordProps?: Record<PropertyKey, any>;
  revisePhoneNumberProps?: Record<PropertyKey, any>;
  logoutProps?: Record<PropertyKey, any>;
  /**
   * 工具栏自定义插槽
   */
  toolSlot?: React.ReactNode;
  /**
   * 拿配置项 看是否需要app
   */
  isShowAppIQR?: boolean;
  /**
   * 拿配置项 看是否需要展示跳转（默认需要）
   */
  isNotJumpTongyi?: boolean;
};

/**
 * 应用配置分发上下文类型
 */
type AppConfigDispatchType = DispatchType<AppConfigContextType>;

/**
 * 应用配置初始化数据
 */
const initialState: AppConfigContextType = {
  systemName: '腾路智行',
  headLogo: 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/basic_table/basic/ten_logo_white.png', // layout的logo
  headShinkLogo: 'https://static-1255466169.cos.ap-guangzhou.myqcloud.com/assets/logo/logo_shrink.png',
  appBoxProps: {
    api: undefined, // 不写就是默认的
    appBoxTips: undefined, // 文字说明
    customizeAppBox: undefined, // 自定义下载
  },
  jumpSystemProps: {
    api: undefined,
    jumpSystemUrl: undefined, // 跳转地址
    jumpCallBack: undefined, // 自定义跳转
    jumpText: '', // 自定义名称
    jumpIcon: '', // 自定义icon
    jumpProps: {},
  },
  modifyPasswordProps: {
    api: undefined,
    passwordService: undefined, // 自定义请求
    callBack: undefined,
  },
  revisePhoneNumberProps: {
    api: undefined,
    revisePhoneService: undefined, // 自定义请求
    callBack: undefined,
  },
  logoutProps: {
    api: undefined,
    logoutService: undefined,
    callBack: undefined,
  },
  toolSlot: null, // 工具插槽
  isShowAppIQR: false, // 是否显示app
  isNotJumpTongyi: false, // 是否不显示跳转
};

/**
 * 应用配置上下文
 */
export const AppConfigContext = createContext(initialState);

/**
 *  获取应用配置上下文
 * @returns {AppConfigContextType} 应用配置上下文
 */
export function useAppConfigContext() {
  return useContext(AppConfigContext);
}

/**
 * 应用配置分发上下文
 */
export const AppConfigDispatch = createContext<Dispatch<AppConfigDispatchType>>(() => {});

/**
 * 获取应用配置分发上下文
 * @returns {Dispatch<AppConfigDispatchType>} 应用配置分发上下文
 */
export function useAppConfigDispatch() {
  return useContext(AppConfigDispatch);
}

interface AppConfigContextProviderProps {
  defaultStatus?: AppConfigContextType;
  children: React.ReactNode;
};

/**
 * 应用配置上下文提供者
 * @param param0 子组件
 * @returns {React.ReactNode} 应用配置上下文提供者
 */
export function AppConfigContextProvider({ defaultStatus, children }: AppConfigContextProviderProps) {
  const [data, dispatch] = useReducer(
    getReducer<AppConfigContextType, AppConfigDispatchType>(initialState),
    merge({}, initialState, defaultStatus),
  );

  return (
    <AppConfigContext.Provider value={data}>
      <AppConfigDispatch.Provider value={dispatch}>
        {children}
      </AppConfigDispatch.Provider>
    </AppConfigContext.Provider>
  );
}
