import type { Key } from 'react';

export function genColumnKey(key?: string | number | Key, index?: number | string): string {
  if (key)
    return Array.isArray(key) ? key.join('-') : key.toString();

  return `${index}`;
}

export function sortByFixedAndSortKeyColumns(columns, sortKeyColumns) {
  // 根据 fixed 值分类
  const leftColumns: any = [];
  const centerColumns: any = [];
  const rightColumns: any = [];

  columns.forEach((column) => {
    if (column.fixed === 'left')
      leftColumns.push(column);
    else if (column.fixed === 'right')
      rightColumns.push(column);
    else
      centerColumns.push(column);
  });

  // 对 children 进行递归排序
  function recursiveSort(cols) {
    cols.forEach((col) => {
      if (col.children && col.children.length > 0)
        col.children = recursiveSort(col.children);
    });
    return cols.sort((a, b) => {
      return sortKeyColumns.indexOf(a.key) - sortKeyColumns.indexOf(b.key);
    });
  }

  // 分别对三类列进行排序
  leftColumns.sort((a, b) => sortKeyColumns.indexOf(a.key) - sortKeyColumns.indexOf(b.key));
  centerColumns.sort((a, b) => sortKeyColumns.indexOf(a.key) - sortKeyColumns.indexOf(b.key));
  rightColumns.sort((a, b) => sortKeyColumns.indexOf(a.key) - sortKeyColumns.indexOf(b.key));

  // 合并排序后的列
  return [...recursiveSort(leftColumns), ...recursiveSort(centerColumns), ...recursiveSort(rightColumns)];
}

export function filterHiddenLeaves(nodes) {
  return nodes.filter((node) => {
    if (!node.children || node.children.length === 0) { // 当前节点为叶子节点
      return node.show; // 只保留show为true的叶子节点
    }
    return node.children.some(it => !!it.show);
  }).map((node) => {
    if (node.children)
      node.children = filterHiddenLeaves(node.children);

    return node;
  });
}
