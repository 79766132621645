import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Typography, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { encrypt, getUrlParams } from '../../../utils';
import { TUIAddModal, TUIDrawer, TUIIconPark, useUserDispatch } from '../../../index';
import SliderValidate from '../SliderValidate';
import { getAgentId, getCodeConfig, getCodeWay, getDingtalkUrl, getRoleList, getUserInfoService, getWechatopenUrl, login } from '../service';
import Chrome from './image/chrome.png';
import WorkWX from './image/work_wx.svg';
import WeChat from './image/we_chat.svg';
import DingTalk from './image/ding_talk.svg';
import './index.less';
import request from 'src/utils/request';
import axios from 'axios';
import { dataA } from './mockA.js'
import AccountBindModal from '../AccountBindModal';

const Password = Input.Password;
const { Text, Link } = Typography;
const codeLoginIconEnum = {
  workwx: WorkWX,
  wechat: WeChat,
  dingtalk: DingTalk,
}
interface NormalLoginFormProps {
  TCAPTCHE_APPID?: number | string;
  loginCallback?: Function;
  NotShowSliderValidate?: boolean;
  colorPrimary: string;
  LoginService?: Function;
  SliderValidateService?: Function;
  otherLoginPara?: object;
  codeLogin?: {
    workwx?: boolean;
    wechat?: boolean;
    dingtalk?: boolean;
  } | boolean | null;
}
function NormalLoginForm({
  TCAPTCHE_APPID,
  NotShowSliderValidate = false, // 不显示滑块
  loginCallback,
  colorPrimary,
  LoginService,
  SliderValidateService,
  otherLoginPara = {},
  codeLogin,
}: NormalLoginFormProps) {
  const [type, setType] = useState<'form' | 'code'>('form');
  const [codeWay, setCodeWay] = useState({});
  const navigate = useNavigate();

  const dispatch = useUserDispatch();

  const [loading, setLoading] = useState(false);

  const doLogin = useCallback(async (values, SSO = false) => {
    setLoading(true);
    try {
      if (LoginService) {
        return await LoginService(values).then((r) => {
          setLoading(false);
          return r;
        }).catch(e => setLoading(false));
      }
      return await login({
        ...values,
        isSpt: 0,
        password: encrypt(values.password),
        loginType: 1,
        account: values.account,
        token: values.token,
        ...otherLoginPara,
      }).then(async (res) => {
        if (res.status === 0) {
          const { data } = res || {};
          const { cookie } = data || {};
          localStorage.setItem('token', res.data.cookie);
          setLoading(false);

          // 拉用户信息
          return await getUserInfoService({ Authorization: cookie }).then(async (re) => {
            if (re && re.status === 0) {
              let userInfo = {
                ...re.data,
                simpleName: (re.data.username || '').substring(0, 4),
                permissions: [],
              };

              // 拉权限
              return await getRoleList({
                Authorization: cookie,
              }).then((r) => {
                if (r.status === 0) {
                  userInfo = {
                    ...userInfo,
                    ...r.data,
                    account: userInfo.phoneNum,
                    name: userInfo.username,
                    phone: userInfo.phoneNum,
                    permissions: [],
                  };
                  localStorage.setItem('userInfo', JSON.stringify(userInfo));

                  // 存到全局useUserContext里
                  dispatch({ type: 'replaceData', payload: userInfo });

                  if (loginCallback) {
                    loginCallback(userInfo);
                  }
                  else {
                    setTimeout(() => {
                      // navigate('/');
                      window.location.href = '/';
                    }, 100);
                  }
                }
              });
            }
            !SSO && message.success('登录成功');
          }).catch((e) => {
            message.error(e.message || '接口处理失败，请稍后重试');
          });
        }
      });
    }
    catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);

  const onSubmit = useCallback((values) => {
    doLogin({
      ...values,
      ...(window.REACT_APP_NOTUSETONGYI ? { isSpt: 1 } : {}),
      token: values.sliderValidate,
    });
  }, []);

  useEffect(() => {
    // sessionStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');

    const query = getUrlParams() as { tid?: string; sid?: string; t?: string; uid: string };
    if (query.tid && query.sid) {
      const urlParams = new URL(window.location.href);
      urlParams.searchParams.delete('tid');
      urlParams.searchParams.delete('sid');
      urlParams.searchParams.delete('t');
      urlParams.searchParams.delete('uid');
      window.history.replaceState(null, 'redirect', urlParams.href);

      doLogin({
        fromSystem: +query.tid,
        toSystem: 101,
        sessionId: query.sid,
        timeStamp: query.t,
        userId: +query.uid,
      }, true);
    }
  }, [])

  const onCodeIconClick = (key) => {
    const tenantPara = JSON.parse(localStorage.getItem('TenantSession')) || {};
    const params = {
      clientId: tenantPara.applicationId,
    }
    // 获取WwLogin相关参数 并 切换到二维码页面
    if (key === 'workwx') {
      Promise.all([getCodeConfig(params), getAgentId(params)]).then(([res1, res2]) => {
        if (!res1 || !res2) return message.error('参数获取错误，请稍后再试');
        setType('code');
        var wwLogin = new WwLogin({
          id: "login_container_workchat",
          appid: res1.appid,
          agentid: res2.agentid,
          redirect_uri: decodeURIComponent(res1.redirect_uri),
          state: res1.state,
          lang: "zh",
        });
      })
    }
    // 获取并跳转扫码页面地址
    if (key === 'wechat') {
      getWechatopenUrl(params).then(res => {
        if (res && res.data.code === 0) {
          const jumpUrl = res.data.data;
          window.location.href = jumpUrl;
        }
      })
    }
    // 获取并跳转扫码页面地址
    if (key === 'dingtalk') {
      getDingtalkUrl(params).then(res => {
        if (res && res.data.code === 0) {
          const jumpUrl = res.data.data;
          window.location.href = jumpUrl;
        }
      })
    }
  }

  // 初始化扫码登录方式：配置优先
  useEffect(() => {
    // 手动配置 - { workwx : true, wechat : true, dingtalk : true }
    if (typeof codeLogin === 'object') {
      // TODO 动态设定icon
      setCodeWay({
        workwx: !!codeLogin.workwx,
        wechat: !!codeLogin.wechat,
        dingtalk: !!codeLogin.dingtalk,
      });
    }
    // 接口动态获取 - true
    else if (codeLogin === true) {
      getCodeWay().then(res => {
        if (res.code === 0) {
          const wayObj = {
            workwx: false,
            wechat: false,
            dingtalk: false,
          };
          res.data.socials.providers.forEach(item => {
            if (item.provider === 'wechatopen') wayObj.wechat = true;
            if (item.provider === 'workweixin') wayObj.workwx = true;
            if (item.provider === 'dingtalk') wayObj.dingtalk = true;
          })
          setCodeWay(wayObj);
        } else {
          setCodeWay({});
        }
      })
    }
    // 不使用扫码登录(默认) - !!codeLogin === false
    else {
      setCodeWay({});
    }
  }, [codeLogin])

  return (
    <>
      <Form onFinish={onSubmit} className="TUI-LoginForm" layout="vertical" style={{ display: type === 'form' ? 'block' : 'none' }}>
        <div className="TUI-LoginTitle">账号登录</div>
        <Form.Item
          // label='账号'
          name="account"
          rules={[{ required: true, message: '账号不能为空' }]}
        >
          <Input
            prefix={<i className="iconfont" style={{ color: colorPrimary }}>&#xe6e0;</i>}
            placeholder="请输入账号"
            style={{ width: '100%', height: '40px' }}
          />
        </Form.Item>

        <Form.Item
          // label='密码'
          name="password"
          rules={[{ required: true, message: '密码不能为空' }]}
        >
          <Password
            prefix={<i className="iconfont" style={{ color: colorPrimary }}>&#xe722;</i>}
            type="password"
            placeholder="请输入密码"
            style={{ width: '100%', height: '40px' }}
          />
        </Form.Item>

        {!NotShowSliderValidate && (
          <Form.Item
            name="sliderValidate"
            rules={[
              {
                required: true,
                message: '请先完成滑动验证',
              },
            ]}
          >
            <SliderValidate TCAPTCHE_APPID={TCAPTCHE_APPID} SliderValidateService={SliderValidateService} />
          </Form.Item>
        )}

        <div className="TUI-JunmpLink">
          <span
            onClick={() => {
              navigate && navigate('/resetPass');
            }}
          >
            忘记密码
          </span>
        </div>

        <Button
          block
          id="submitBtnId"
          type="primary"
          size="large"
          htmlType="submit"
          loading={loading}
          className="TUI-LoginBtn"
        >
          登录
        </Button>
        {/* 第三方登录方式 */}
        {codeWay && Object.values(codeWay).some(v => v) &&
          <div className='TUI-CodeLogin'>
            {
              Object.entries(codeWay).map(([key, value]) => (value &&
                <img
                  key={key}
                  src={codeLoginIconEnum[key]}
                  className="typeIcon"
                  onClick={() => onCodeIconClick(key)}
                />
              ))
            }
          </div>
        }
        <Text type="secondary" className="chormeC">
          推荐使用
          <img src={Chrome} alt="" style={{ marginLeft: '4px' }} />
          <Link
            style={{ fontSize: 18, margin: '0 4px', fontWeight: 500 }}
            href="https://www.google.cn/intl/zh-CN/chrome/"
          >
            Chrome
          </Link>
          浏览器
        </Text>
      </Form>
      {/* 企业微信二维码 */}
      <div className='TUI-CodeScan' style={{ display: type === 'code' ? 'block' : 'none' }}>
        <div id="login_container_workchat"></div>
        <div className='backLogin'>
          <Link onClick={() => { setType('form') }}>返回登录</Link>
        </div>
      </div>
    </>
  );
}

export default NormalLoginForm;
