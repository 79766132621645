import * as React from 'react';
import { List, Typography } from 'antd';
import ClassNames from 'classnames';
import styles from './index.module.less';

interface ItemProps {
  id: number | string;
  name: string | React.ReactNode;
}
interface LeftListProp {
  dataSource: Array<ItemProps>;
  activeId: number | string;
  ItemClick: Function;
  LeftSlot?: Function;
  RightSolt?: Function;
}

export function LeftList({
  dataSource = [],
  activeId,
  ItemClick = () => {},
  LeftSlot = () => null,
  RightSolt = () => null,
}: LeftListProp) {
  return (
    <List
      className={ClassNames('TUI-List', styles['TUI-List'])}
      dataSource={dataSource}
      renderItem={(record: ItemProps) => {
        return (
          <List.Item
            className={ClassNames([record.id === activeId ? styles.active : '', styles.item])}
            onClick={() => ItemClick(record)}
          >
            <div className={styles.row}>
              <span className={styles.head}>
                {LeftSlot(record)}
                <Typography.Paragraph style={{ margin: 0 }} ellipsis={{ rows: 1, tooltip: true }} className={styles.name}>{record.name}</Typography.Paragraph>
              </span>
              {RightSolt(record)}
            </div>
          </List.Item>
        );
      }}
    />
  );
}
