import * as React from 'react';
import type { authProps, storeDataProps } from '../DragLeft/types';
import { initStoreData } from '../DragLeft/common';
import { ValidateRules, message, useThemeHook } from '../../index';
import { Sortable } from './Sortable';
import type { onFetchServiceProps } from './types';

interface DragMenuProps {
  width?: number;
  nameWidth?: number | string;
  isDarag?: boolean;
  storeData?: storeDataProps;
  setStoreData?: Function;
  onClickLeaf?: Function;
  onFetchService?: onFetchServiceProps;
  auth?: authProps;
  setMenu?: Function;
  submitCallback?: Function;
  updateTreeData?: Function;
  icon?: string | React.ReactNode;
  DragStyle?: React.CSSProperties;
  DragMenuProps?: object;
}
export default function DragMenu({
  width = 258,
  nameWidth = 148,
  isDarag,
  storeData = initStoreData,
  setStoreData,
  onClickLeaf: onClickLeafSelf,
  onFetchService = {},
  auth = {},
  setMenu,
  submitCallback = () => {},
  updateTreeData,
  icon,
  DragStyle = {},
  DragMenuProps = {},
}: DragMenuProps) {
  const { token } = useThemeHook();
  const themeStyle = {
    '--width': `${width}px`,
    '--diff-border-radius': `${token.borderRadius - 6}px`,
  };

  const { treeData = [], initData = [], editGroupItem } = storeData;

  /** 更新数据 */
  const updateStore = (payload = {}) => {
    setStoreData && setStoreData({ ...storeData, ...payload });
  };

  const beforeAdd = (name) => {
    return ValidateRules.ValidateClassName(name);
  };

  /** 点击确认 */
  const onSaveLeaf = async (itr, val) => {
    if (!itr.id) {
      if (!onFetchService.onFetchAdd)
        return;
      await onFetchService.onFetchAdd({ ...itr, name: val }).then((r) => {
        message.success('操作成功');
        submitCallback();
      }).catch((e) => {
        message.error(e.message || '操作失败');
      });
    }
    else {
      if (!onFetchService.onFetchEdit)
        return;
      await onFetchService.onFetchEdit({ ...itr, name: val }).then((r) => {
        message.success('操作成功');
        submitCallback();
      }).catch((e) => {
        message.error(e.message || '操作失败');
      });
    }
  };

  /** 点击取消 */
  const onCancelLeaf = (type, item) => {
    updateStore({
      editGroupItem: null,
      ...(type === 'leaf' && item.id === 0 ? { treeData: initData } : {}),
    });
  };

  /** 行内编辑 */
  const onEdit = (itr) => {
    updateStore({
      editGroupItem: { type: 'leaf', ...itr },
      ...(editGroupItem && editGroupItem.type === 'leaf' && editGroupItem.id === 0 ? { treeData: initData } : {}),
    });
  };

  /** 删除 */
  const onDelete = async (itr) => {
    if (!onFetchService.onFetchDelete)
      return;
    await onFetchService.onFetchDelete(itr.id).then((r) => {
      message.success('操作成功');
      submitCallback();
    }).catch((e) => {
      message.error(e.message || '操作失败');
    });
  };

  /** 点击选中某条 */
  const onClickLeaf = (item) => {
    if (editGroupItem && (item.id !== editGroupItem.id)) {
      // 在新增文件夹时点击别的文件夹，数据要初始化
      updateStore({
        editGroupItem: null,
        ...(editGroupItem.type === 'leaf' && editGroupItem.id === 0 ? { treeData: initData } : {}),
        ...(item.id !== 0 ? { selectItem: item } : {}),
      });
    }
    else {
      updateStore({
        ...(item.id !== 0 ? { selectItem: item } : {}),
      });
    }
  };

  /** 拖拽完成 */
  const onDragAfter = ({ resultData }) => {
    const changeOrderArr = resultData.map((item, idx) => ({ id: item.id, order: `${idx + 1}`.padStart(8, '0') }));
    // console.log('拖拽后的字段--', resultData, changeOrderArr);
    updateTreeData && updateTreeData(resultData, changeOrderArr);
    onFetchService.onFetchChangeOrder && onFetchService.onFetchChangeOrder(changeOrderArr);
  };

  return (
    <div style={{ ...themeStyle, ...DragStyle }}>
      {treeData.length > 0 && (
        <Sortable
          handle={true}
          isDarag={isDarag}
          storeData={storeData}
          updateStoreData={setStoreData}
          icon={icon}
          onClickLeaf={onClickLeafSelf || onClickLeaf}
          onDragAfter={onDragAfter}
          extraContent={{
            ...onFetchService,
            type: 'leaf',
            editGroupItem,
            beforeAdd,
            onSaveLeaf: (itr, val) => onSaveLeaf(itr, val),
            onCancelLeaf: (type, itr) => onCancelLeaf(type, itr),
            setMenu: item => setMenu
              ? setMenu(item)
              : [
                  { id: 'showEdit', name: '编辑', isShow: () => true, onClick: () => { onEdit(item); } },
                  { id: 'showDel', name: '删除', isShow: () => item.canDelFlag === 1, onClick: () => { onDelete(item); } },
                ].filter(it => !!auth[it.id]),
          }}
          nameWidth={nameWidth}
          {...DragMenuProps}
        />
      )}
    </div>
  );
}
